import React, {ReactNode, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import {DownOutlined} from '@ant-design/icons';

import StyledList from './StyledList';
import {ThemeProps} from '../../../../../theme/theme';

const CollapseStyles = styled.div<ThemeProps & {open: boolean}>`
  border-top: 1px solid ${({theme}) => theme.colors.GREY2};
  padding: 12px ${({theme}) => theme.grid.gutter.sm};
  margin: 0 -${({theme}) => theme.grid.gutter.sm};

  > div {
    height: ${({theme}) => theme.grid.gutter.xlg};
    line-height: ${({theme}) => theme.grid.gutter.xlg};
    color: ${({theme}) => theme.colors.GREY};

    > span.anticon {
      float: right;
      line-height: inherit;
      font-size: 10px;

      transform: ${({open}) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 40%;
      transition: transform 0.2s ease;
    }
  }

  ${StyledList} {
    padding-left: ${({theme}) => theme.grid.gutter.md};
    li a {
      margin: 0;
    }
  }
`;

const CollapseMenuItem = ({
  children,
  title,
  defaultExpanded = false,
}: {
  children: ReactNode[];
  title: string;
  defaultExpanded?: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(defaultExpanded);

  const toggle = () => {
    setOpen(!open);
  };

  const element = useRef(null);

  return (
    <CollapseStyles open={open}>
      <div onClick={toggle} role='button' aria-expanded={open ? 'true' : 'false'}>
        {title} <DownOutlined />
      </div>
      {open && (
        <StyledList ref={element}>
          {children.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </StyledList>
      )}
    </CollapseStyles>
  );
};

export default CollapseMenuItem;

import {HttpClient} from '../index';
import {getQueryString} from '../../utils/urls';
import {SuccessResponse} from '../authorization/types';
import {Optional} from '../../types';

const declineTermsOfUse = (token: Optional<string>): Promise<SuccessResponse> => {
  return HttpClient.post<any>({
    url: '/account/decline_terms_of_use',
    data: {activation_token: getQueryString('token') || token},
  });
};

export default declineTermsOfUse;

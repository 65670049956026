import React from 'react';
import {RouteComponentProps} from 'react-router';
import {Redirect} from 'react-router-dom';

import {Routes} from '../pages/routes';
import isAdmin from '../utils/isAdmin';

export enum RoleTypes {
  EMPLOYEE = 'employee',
  CLIENT_ADMIN = 'client_admin',
}

const checkUserRole = (
  Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  redirectPath: Routes,
) => {
  return (props: any) => {
    if (!isAdmin()) {
      return <Redirect to={redirectPath} />;
    }

    return <Component {...props} />;
  };
};

export default checkUserRole;

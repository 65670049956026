import React, {FC, ReactNode} from 'react';
import isNil from 'lodash/isNil';

interface Props {
  Wrapper?: FC;
  condition?: boolean;
  noEmpty?: boolean;
}

const isEmptyChildren = (children: ReactNode): boolean => {
  // @ts-ignore
  return isNil(children) || children?.type?.() === null;
};

const OptionalWrapper: FC<Props> = ({children, condition, Wrapper, noEmpty}) => {
  if (noEmpty && isEmptyChildren(children)) return null;

  if (Wrapper && condition !== false) {
    return <Wrapper>{children}</Wrapper>;
  }

  return <>{children}</>;
};

export default OptionalWrapper;

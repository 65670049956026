import styled from 'styled-components/macro';

import {ThemeProps} from '../../../theme/theme';
import InputStyles from '../../Input/styles';
import InputSelectStyles from '../../Select/styles';

const FormPaper = styled.div<ThemeProps>`
  padding: ${({theme}) => theme.grid.gutter.xlg};
  background: ${({theme}) => theme.colors.WHITE};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 60px;
  max-width: 394px;
  flex: 0 1 394px;

  ${InputStyles},
  ${InputSelectStyles} {
    margin-bottom: ${({theme}) => theme.grid.gutter.sm};
  }

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `padding-left: ${theme.grid.gutter.sm};
      padding-right: ${theme.grid.gutter.sm}`,
    )}

  form > {
    a {
      text-decoration: underline;
    }

    p {
      ${({theme}) => ({...theme.textStyle.body})};
      color: ${({theme}) => theme.colors.GREY1};
      margin: ${({theme}) => theme.grid.gutter.sm} 0 0;

      > span {
        font-size: 14px;
        color: ${({theme}) => theme.colors.BLACK};
        line-height: 1;
      }

      a {
        text-decoration: underline;
      }
    }

    h1 {
      color: ${({theme}) => theme.colors.BLUE};
    }

    h1 + p {
      position: relative;
      top: -${({theme}) => theme.grid.gutter.xs};
      margin-bottom: ${({theme}) => theme.grid.gutter.xs};
    }
  }
`;

export default FormPaper;

import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components/macro';
import {RecoilRoot} from 'recoil';
import {RecoilLogger} from 'recoil-devtools-logger';
import {QueryClient, QueryClientProvider} from 'react-query';

import App from './App';
import theme from './theme/theme';
import * as serviceWorker from './serviceWorker';
import {NotificationProvider} from './context/notification-context';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      {process.env.NODE_ENV !== 'production' && <RecoilLogger />}
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

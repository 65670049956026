import HttpClient from '../httpClient';
import {ForgotPasswordSchema, SuccessResponse} from './types';

const forgotPassword = (data: ForgotPasswordSchema) => {
  const params = {
    recovery_origin: `${window.location.origin}/recovery_token`,
  };

  return HttpClient.post<SuccessResponse>({
    url: '/account/forgot_password',
    data,
    params,
  });
};

export default forgotPassword;

import {AvatarProps} from 'antd/lib/avatar';
import styled from 'styled-components/macro';

import {ThemeProps} from '../../../../../../theme/theme';

type SelectedProp = {selected: boolean};
type AvatarStylesProps = AvatarProps & ThemeProps & SelectedProp;

export const AvatarStyles = styled.div<AvatarStylesProps>`
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -24px;
  .ant-avatar.ant-avatar-icon {
    margin: 0 22px 5px 24px;
    border: 1px solid ${(props) => props.theme.colors.GREY2};
    background-color: ${(props) =>
      props.selected ? props.theme.colors.BLUE3 : props.theme.colors.WHITE};
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  span {
    font-size: 10px;
    line-height: 10px;
    color: ${(props) => props.theme.colors.GREY5};
  }
`;

import {InsuranceFormInfoHA, InsuranceInfoSchema} from '../types';
import HttpClient, {Methods} from '../../httpClient';

const sendInsuranceInfoHA = async (
  isUpdate: boolean = false,
  data: InsuranceFormInfoHA,
): Promise<InsuranceInfoSchema> => {
  return HttpClient.request<InsuranceInfoSchema>({
    method: isUpdate ? Methods.PUT : Methods.POST,
    url: '/sso/insurance',
    data: {
      is_insured: data.is_insured,
      insurance: data.insurance,
    },
  });
};

export default sendInsuranceInfoHA;

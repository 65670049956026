import {css, ThemeProps} from 'styled-components/macro';

import {PopoverProps} from './types';
import {Theme} from '../../theme/theme';

type Props = PopoverProps & ThemeProps<Theme>;

const styles = css<Props>`
  .ant-popover-inner {
    width: ${(props: Props) => props.innerWidth};
    border-radius: 4px;
  }
  .ant-popover-inner-content {
    padding: 10px 16px;
  }
  .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
    left: 36px;
  }
`;

export default styles;

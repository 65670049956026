import styled, {ThemeProps} from 'styled-components/macro';

import {Theme} from '../../theme/theme';
import {SelectProps} from './types';

type Props = SelectProps & ThemeProps<Theme>;

const InputSelectStyles = styled.div<Props>`
  label {
    display: block;
    padding: 0 0 4px ${(props: Props) => props.theme.grid.gutter.xs};
    color: ${(props: Props) => {
      if (props.error) return props.theme.colors.RED;
      return props.theme.colors.GREY1;
    }};
    opacity: ${(props: Props) => (props.disabled ? 0.5 : 1)};
    ${(props: Props) => ({...props.theme.textStyle.inputLabel})};
  }
  .ant-select {
    width: 100%;
    opacity: ${(props: Props) => (props.disabled ? 0.5 : 1)};

    .ant-select-selector {
      transition: none;
    }
  }
  .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 48px;
    color: ${(props: Props) => props.theme.colors.GREY};
    padding: ${(props: Props) => props.theme.grid.gutter.sm}
      ${(props: Props) => props.theme.grid.gutter.xs};
    border: 1px solid
      ${(props: Props) => {
        if (props.disabled) return props.theme.colors.GREY3;
        if (props.error) return props.theme.colors.RED;
        return props.theme.colors.BLUE4;
      }};
    border-radius: 10px;
    background-color: ${(props: Props) =>
      props.disabled ? props.theme.colors.GREY3 : props.theme.colors.GREY4};
  }
  .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 ${(props: Props) => props.theme.grid.gutter.xs};
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 8px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 48px;
  }
  .ant-select-selector:hover,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:active
    .ant-select-selector:active {
    border: 1px solid
      ${(props: Props) => {
        if (props.disabled) return props.theme.colors.GREY3;
        if (props.error) return props.theme.colors.RED;
        return props.theme.colors.BLUE3;
      }};
    box-shadow: unset;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:active
    .ant-select-selector:active {
    background-color: ${(props: Props) => props.theme.colors.PURPLE3};
    caret-color: ${(props: Props) => props.theme.colors.BLUE3};
    box-shadow: none;
    border: 1px solid
      ${(props: Props) => {
        if (props.disabled) return props.theme.colors.GREY3;
        if (props.error) return props.theme.colors.RED;
        return props.theme.colors.BLUE3;
      }};
  }
  .ant-select:focus {
    box-shadow: none;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: ${(props: Props) => props.theme.colors.GREY};
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 1;
  }

  .ant-select-single .ant-select-selector:hover .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-select-dropdown {
    padding: ${(props: Props) => props.theme.grid.gutter.xs} 0;
    border-radius: 10px;
  }
  .ant-select-item {
    padding: ${(props: Props) => props.theme.grid.gutter.xs};
    font-size: 16px;
    line-height: 18px;
    color: ${(props: Props) => props.theme.colors.GREY};
  }
  .ant-select-item:hover {
    background-color: ${(props: Props) => props.theme.colors.GREY3};
  }

  .ant-select-item-option-selected {
    background-color: ${(props: Props) => props.theme.colors.PURPLE3};
    font-weight: normal;
  }
  .ant-select-arrow {
    right: 18px;
  }
  .ant-select-arrow {
    svg {
      width: 8px;
      color: ${(props: Props) =>
        props.disabled ? props.theme.colors.GREY : props.theme.colors.BLUE3};
    }

    &.ant-select-arrow-loading {
      width: 20px;
      height: 20px;
      margin-top: -10px;
      right: 12px;
      svg {
        width: 20px;
        height: 20px;
        color: ${(props: Props) => props.theme.colors.AQUAMARINE1};
      }
    }
  }
  .ant-select-open .ant-select-arrow:not(.ant-select-arrow-loading) {
    transform: rotate(180deg);
  }
`;

export default InputSelectStyles;

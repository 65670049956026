import React, {FC} from 'react';
import {Typography as AntTypography} from 'antd';
import {TitleProps} from 'antd/lib/typography/Title';
import styled from 'styled-components/macro';

// TODO: implement in storybook;
const Typography: FC<TitleProps & {strong?: boolean}> = (props) => (
  <AntTypography.Title {...props} />
);

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export default Typography;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../../../theme/theme';

export const DefaultLink = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  cursor: pointer;
  color: ${({theme}) => `${theme.colors.BLUE3}`};
`;

export const DisabledLink = styled.div<ThemeProps>`
  color: ${({theme}) => `${theme.colors.GREY1}${theme.colors.THIRTY}`};
  font-size: 18px;
  cursor: not-allowed;
`;

export const Tooltip = styled.div<ThemeProps & {show: boolean}>`
  display: ${({show}) => (show ? '' : 'none')};
  top: 65px;
  right: 170px;
  height: 36px;
  background: ${({theme}) => theme.colors.GREY};
  color: ${({theme}) => theme.colors.GREY4};
  position: absolute;
  z-index: 100;
  font-size: 12px;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: 35px;
    height: 20px;
    width: 20px;
    background: ${({theme}) => theme.colors.GREY};
    transform: rotate(45deg);
  }

  > span {
    position: relative;
    display: block;
    margin: 8px 6px;
    line-height: 20px;
    z-index: 100;
  }
`;

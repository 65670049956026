import HttpClient, {Methods} from '../httpClient';
import {E1TransactionResponse, UserResponse} from './types';

const sendDemographicInfoHA = async (
  isUpdate: boolean = false,
  data?: Partial<UserResponse>,
): Promise<E1TransactionResponse> => {
  return HttpClient.request<E1TransactionResponse>({
    method: isUpdate ? Methods.PUT : Methods.POST,
    url: '/sso/demographic_info',
    data: isUpdate ? data : null,
  });
};

export default sendDemographicInfoHA;

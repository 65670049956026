import {LoadingOutlined} from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';

type Props = {
  overlay?: boolean;
  modal?: boolean;
  showIcon?: boolean;
  transparent?: boolean;
  spinnerPrimary?: boolean;
};

const StylesWrapper = styled.div<ThemeProps & Props>`
  width: 100%;
  height: 100% !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.transparent ? 0 : props.theme.grid.gutter.md)} 0;
  z-index: 100;

  ${(props) => (props.transparent ? 'background: rgba(255, 255, 255, 0.75)' : '')};

  span {
    font-size: 50px;
    cursor: wait;

    ${(props) => (props.spinnerPrimary ? `color: ${props.theme.colors.AQUAMARINE1}` : '')};
  }

  ${({overlay, modal, showIcon, theme}) =>
    overlay &&
    `
    color: #26768F;
    max-width: 377px;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    margin: 0 !important;

    ${theme.forWidth(
      'max',
      'sm',
      `
        ${showIcon ? 'right: 0' : ''};
        ${!modal ? 'max-width: 328px' : ''};
        border-radius: 10px;
      `,
    )}

    @media only screen and (min-width: ${theme.grid.breakpoints.sm}px) and (max-width: ${
      theme.grid.breakpoints.xl
    }px) {
      ${!modal && 'max-width: 275px;'}
    }
  `}

  ${({modal}) =>
    modal &&
    `
    left: 0;
    bottom: 0;
    border-radius: 10px;
  `}
`;

const Preloader = ({overlay, modal, showIcon, transparent, spinnerPrimary}: Props) => {
  return (
    <StylesWrapper
      overlay={overlay}
      modal={modal}
      transparent={transparent}
      spinnerPrimary={spinnerPrimary}
      showIcon={showIcon}
    >
      {/*
      // @ts-ignore */}
      <LoadingOutlined title='Loading...' />
    </StylesWrapper>
  );
};

export default Preloader;

import {RecoilValue, useRecoilValueLoadable} from 'recoil';

import {DataValue} from '../types';

const useDataValue = <DataType>(atom: RecoilValue<DataType>): DataValue<DataType> => {
  const loadable = useRecoilValueLoadable(atom);

  switch (loadable.state) {
    case 'hasValue':
      return {isLoading: false, data: loadable.contents};
    case 'loading':
      return {isLoading: true};
    default:
      return {isLoading: false, error: loadable.contents};
  }
};

export default useDataValue;

import React from 'react';
import 'antd/dist/antd.compact.less';
import {useObserveAppInstances} from 'hooks/useObserveAppInstances';

import Routing from './pages';
import GlobalStyles from './theme/GlobalStyles';

function App() {
  useObserveAppInstances();

  return (
    <>
      <GlobalStyles />
      <Routing />
    </>
  );
}

export default App;

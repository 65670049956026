import React, {FC} from 'react';
import {Menu as AntMenu} from 'antd';
import styled from 'styled-components/macro';

import {MenuProps} from './types';
import styles, {GlobalStyles} from './styles';

const MenuUnStyled = ({items}: MenuProps) => (
  <>
    <GlobalStyles items={items} />
    <AntMenu selectable={false}>
      {items.map((item) => (
        <AntMenu.Item>{item}</AntMenu.Item>
      ))}
    </AntMenu>
  </>
);

const Menu: FC<MenuProps> = styled(MenuUnStyled)`
  ${styles}
`;

export default Menu;

import {Dropdown as AntDropdown} from 'antd';
import React from 'react';

import Menu from './Menu';
import {Props as DropdownProps} from './types';

const Dropdown = ({children, items, ...props}: DropdownProps) => {
  return (
    <AntDropdown
      arrow
      placement='bottomCenter'
      overlay={<Menu items={items} />}
      trigger={['click', 'hover']}
      {...props}
    >
      {children}
    </AntDropdown>
  );
};
export default Dropdown;

import React from 'react';

import UserSelfActivationForm from './components/UserSelfActivationForm';
import SignInLayout from '../../components/SignInLayout';
import backgroundImage from '../../assets/sign-in-bg.jpg';

const UserSelfActivation = () => {
  return (
    <SignInLayout backgroundImage={backgroundImage}>
      <UserSelfActivationForm />
    </SignInLayout>
  );
};

export default UserSelfActivation;

import {atom, RecoilState, selector} from 'recoil';

const generateDataAtom = <DataType, ParamsType>(
  key: string,
  fetchFn: Function,
  paramsAtom?: RecoilState<ParamsType>,
) =>
  atom<DataType>({
    key,
    default: selector({
      key: `${key}__default`,
      get: paramsAtom ? ({get}) => fetchFn(get(paramsAtom)) : () => fetchFn(),
    }),
  });

export default generateDataAtom;

import React, {FormEvent, MouseEvent, useCallback, useState} from 'react';
import {useMutation} from 'react-query';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {AxiosError} from 'axios';
import {ERROR_CODES} from 'errors/Error/messages';

import Input from '../../../components/Input';
import getFieldError from '../../../utils/getFieldError';
import SubmitButton from '../../../components/Button/components/SubmitButton';
import Select from '../../../components/Select';
import {ValidationMessages} from '../../../utils/validation';
import validateEqualFields from '../../../utils/validateEqualFields';
import validateOktaPassword, {validateOktaHintMessages} from '../../../utils/validateOktaPassword';
import activateUser from '../../../services/user/activateUser';
import {useNotification} from '../../../context/notification-context';
import getErrorMessage, {OKTA_ERROR_CODES, ServerError} from '../../../services/getErrorMessage';
import {NOTIFICATION_TYPES} from '../../../components/Notification/types';
import {Routes} from '../../routes';
import TermsOfUseModal from './TermsOfUseModal';
import declineTermsOfUse from '../../../services/user/declineTermsOfUse';
import Typography from '../../../components/Typography';
import {getQueryString} from '../../../utils/urls';

const OKTA_TYPE_SERVER_ERROR = 'oktaServer';
const declineTermsNotification = (
  <div>
    By declining the Terms of Use, you will not be able to use this site. You can close this window
    or navigate to another place now.
    <br />
    <br />
    If you change your mind, you can accept the Terms of Use if you try to activate your account
    within 7 days.
  </div>
);

export enum ACTIVATE_USER_FORM_FIELDS {
  TEMP_PASSWORD = 'temp_password',
  NEW_PASSWORD = 'new_password',
  NEW_PASSWORD_CONFIRM = 'new_password_confirm',
  SECURITY_QUESTION = 'security_question',
  SECURITY_ANSWER = 'security_answer',
}

export type ActivateUserFormData = Record<ACTIVATE_USER_FORM_FIELDS, string | null>;

const defaultValues: ActivateUserFormData = {
  [ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD]: null,
  [ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD]: null,
  [ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM]: null,
  [ACTIVATE_USER_FORM_FIELDS.SECURITY_QUESTION]: null,
  [ACTIVATE_USER_FORM_FIELDS.SECURITY_ANSWER]: null,
};

const SecurityQuestionOptions = [
  {
    value: 'What is your favorite color?',
    label: 'What is your favorite color?',
  },
  {
    value: 'What was your first car?',
    label: 'What was your first car?',
  },
  {
    value: "What is your mother's middle name?",
    label: "What is your mother's middle name?",
  },
];

const UserSelfActivationForm = () => {
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    trigger,
    errors,
    setValue,
    clearErrors,
  } = useForm<ActivateUserFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const email = getQueryString('email');
  const [showModal, setShowModal] = useState<boolean>(false);
  const notificationsContext = useNotification();

  const {push} = useHistory();

  const {mutateAsync: mutateDecline} = useMutation(declineTermsOfUse, {
    onSuccess: () => {
      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.INFO,
        description: declineTermsNotification,
      });
    },
    onError: (_error: any) => {
      const error = _error as AxiosError<ServerError>;
      const errorMessage = getErrorMessage(error?.response?.data, true);
      const errorCode = error?.response?.data?.error_code;

      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.ERROR,
        description: errorMessage,
      });

      if (errorCode === ERROR_CODES.alreadyAcceptedTermsOfUse) {
        push(Routes.SIGN_IN);
      }
    },
    onSettled: () => {
      setShowModal(false);
    },
  });

  const {mutateAsync: mutateAcceptance, isLoading: isLoadingAcceptance} = useMutation<
    any,
    {},
    ActivateUserFormData
  >(activateUser, {
    onSuccess: () => {
      push(Routes.HOME);
    },
    onError: (_error: any) => {
      const error = _error as AxiosError<ServerError>;
      const errorMessage = getErrorMessage(error?.response?.data, true);
      const errorCode = error?.response?.data?.error_code;

      if (errorCode === OKTA_ERROR_CODES.UPDATE_OF_CREDENTIALS_FAILED) {
        [
          {
            type: OKTA_TYPE_SERVER_ERROR,
            name: ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD,
          },
        ].forEach(({name, type}) => {
          setError(name, {type});
          setValue(ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD, '', {shouldValidate: false});
        });
      }

      if (errorCode === ERROR_CODES.userAlreadyActivated) {
        push(Routes.SIGN_IN);
      }

      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.ERROR,
        description: errorMessage,
      });
    },
  });

  const handlePasswordFocusByServerError = (
    e: React.FocusEvent<HTMLInputElement>,
    serverOktaError: boolean,
  ) => {
    if (serverOktaError) {
      clearErrors([
        ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD,
        ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM,
      ]);
    }
  };

  const newPasswordHintItems = useCallback(
    (name, value, email = null) => {
      const error = getFieldError(formState, name);

      return validateOktaHintMessages.map(({item, validator}) => ({
        item,
        id: item,
        valid: value && validator(value, email),
        error: error && !validator(value, email),
      }));
    },
    [formState],
  );

  const validateEqual = useCallback(
    (value) => {
      const field = formState.touched[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM];
      if (field) {
        const message = validateEqualFields(
          getValues,
          ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM,
          ValidationMessages.PASSWORD_DIFFERS,
        )(value);

        if (message) {
          setError(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM, {
            type: 'equalField',
            message,
          });
        }
      }
    },
    [getValues, setError, formState.touched],
  );

  const saveBtnDisabled = !formState.isValid || !formState.isDirty;

  const submitForm = handleSubmit((data) => mutateAcceptance(data));

  const onSubmit = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    if (saveBtnDisabled) {
      return;
    }
    setShowModal(true);
  };

  const handleCancel = () => mutateDecline(null);
  const handleOk = () => {
    submitForm();
    setShowModal(false);
  };

  return (
    <>
      <TermsOfUseModal onOk={handleOk} onCancel={handleCancel} visible={showModal} />
      <form onSubmit={onSubmit}>
        <Typography level={1} data-e2e='activate-account__title'>
          Activate Your Account
        </Typography>
        <p data-e2e='activate-account__description'>
          Activate your oneRx account to easily search for and estimate the costs for your
          prescription drugs.
        </p>

        <input type='submit' style={{display: 'none'}} />

        <Controller
          name={ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            validate: {
              equalValidate: (value) => {
                const confirmPasswordField = getValues(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD);
                const isTempEqual = confirmPasswordField === value;

                if (isTempEqual) {
                  trigger(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD);
                  return undefined;
                }
              },
            },
            minLength: {
              value: 8,
              message: ValidationMessages.MIN_8,
            },
          }}
          render={({onChange, ...props}) => {
            const serverOktaError =
              errors[ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD]?.type === OKTA_TYPE_SERVER_ERROR;

            return (
              <Input
                label='Temporary password'
                placeholder='Enter your temporary password'
                data-e2e='activate-account__temporary-password-input'
                error={getFieldError(formState, props.name) || serverOktaError}
                type='password'
                onChange={(e) => {
                  const field = formState.touched[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD];
                  if (field) {
                    const newPasswordField = getValues(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD);

                    if (newPasswordField === e.target.value) {
                      setError(ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD, {
                        type: 'equalField',
                        message: ValidationMessages.MATCHES_TEMP_PASSWORD,
                      });
                    } else {
                      clearErrors(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD);
                    }
                  }

                  onChange(e);
                }}
                {...props}
              />
            );
          }}
        />
        <Controller
          name={ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            validate: {
              okta: (value) => {
                return validateOktaPassword(value, email);
              },
              equalValidates: (value) => {
                const tempPasswordField = getValues(ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD);
                const confirmPasswordField = getValues(
                  ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM,
                );
                const isTempEqual = tempPasswordField === value;
                const isConfirmEqual = confirmPasswordField === value;

                if (isTempEqual) {
                  return ValidationMessages.MATCHES_TEMP_PASSWORD;
                }

                if (isConfirmEqual) {
                  trigger(ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM);
                  return undefined;
                }
              },
            },
          }}
          render={({onChange, ...props}, {invalid}) => {
            const newPasswordError = errors[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD]?.message;

            const equalTempError = newPasswordError === ValidationMessages.MATCHES_TEMP_PASSWORD;

            const requiredError = newPasswordError === ValidationMessages.REQUIRED;

            const errorMessage = (requiredError || equalTempError) && newPasswordError;

            const equalFieldsError =
              errors[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM]?.message ===
              ValidationMessages.PASSWORD_DIFFERS;

            const serverOktaError =
              errors[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD]?.type === OKTA_TYPE_SERVER_ERROR;

            return (
              <Input
                labelTooltip
                label='New password'
                placeholder='Enter new password'
                type='password'
                data-e2e='activate-account__new-password-input'
                activeTooltipHint={props.value && invalid && !equalTempError && !serverOktaError}
                tooltipHintItems={newPasswordHintItems(props.name, props.value, email)}
                error={errorMessage || equalFieldsError || serverOktaError}
                onChange={(e) => {
                  validateEqual(e.target.value);

                  onChange(e);
                }}
                onFocus={(e) => handlePasswordFocusByServerError(e, serverOktaError)}
                {...props}
              />
            );
          }}
        />
        <Controller
          name={ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            validate: validateEqualFields(
              getValues,
              ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD,
              ValidationMessages.PASSWORD_DIFFERS,
            ),
          }}
          render={(props) => {
            const errorMessage = errors[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD_CONFIRM]?.message;
            const serverOktaError =
              errors[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD]?.type === OKTA_TYPE_SERVER_ERROR;

            return (
              <Input
                label='Confirm new password'
                placeholder='Retype your password'
                error={errorMessage || serverOktaError}
                type='password'
                data-e2e='activate-account__retype-password-input'
                onFocus={(e) => handlePasswordFocusByServerError(e, serverOktaError)}
                {...props}
              />
            );
          }}
        />
        <Controller
          name={ACTIVATE_USER_FORM_FIELDS.SECURITY_QUESTION}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
          }}
          render={(props) => (
            <Select
              label='Account security question'
              placeholder='Select...'
              data-e2e='activate-account__question-select'
              error={getFieldError(formState, props.name)}
              options={SecurityQuestionOptions}
              {...props}
            />
          )}
        />
        <Controller
          name={ACTIVATE_USER_FORM_FIELDS.SECURITY_ANSWER}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            minLength: {
              value: 4,
              message: ValidationMessages.MIN_4,
            },
          }}
          render={(props) => (
            <Input
              labelTooltip
              label='Answer'
              placeholder='Enter your answer'
              data-e2e='activate-account__answer-input'
              error={getFieldError(formState, props.name)}
              {...props}
            />
          )}
        />
        <SubmitButton
          label='Activate account'
          loadingLabel='Activating account...'
          data-e2e='activate-account__button'
          block
          isLoading={isLoadingAcceptance}
          disabled={saveBtnDisabled}
          onClick={onSubmit}
        />
      </form>
    </>
  );
};

export default UserSelfActivationForm;

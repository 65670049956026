import React, {FC} from 'react';
import {Alert} from 'antd';

import {
  AlertProps,
  NOTIFICATION_TYPES,
  NotificationDurations,
  NotificationMessageTypes,
} from './types';
import {DEFAULT_ERROR_DURATION, DEFAULT_SUCCESS_DURATION} from '../../constants/common';

export const notificationMessageByType: NotificationMessageTypes = {
  [NOTIFICATION_TYPES.SUCCESS]: 'Success',
  [NOTIFICATION_TYPES.INFO]: 'Information',
  [NOTIFICATION_TYPES.WARNING]: 'Warning',
  [NOTIFICATION_TYPES.ERROR]: 'Error',
};

export const notificationDuration: NotificationDurations = {
  [NOTIFICATION_TYPES.SUCCESS]: DEFAULT_SUCCESS_DURATION,
  [NOTIFICATION_TYPES.INFO]: 8,
  [NOTIFICATION_TYPES.WARNING]: 8,
  [NOTIFICATION_TYPES.ERROR]: DEFAULT_ERROR_DURATION,
};

const Notification: FC<AlertProps> = ({type, ...props}) => {
  return (
    <Alert showIcon closable type={type} message={notificationMessageByType[type!]} {...props} />
  );
};

export default Notification;

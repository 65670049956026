import React from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {Avatar} from 'antd';
import styled from 'styled-components/macro';

import {ReactComponent as BlueAvatarIcon} from '../../../../../assets/avatar-grey.svg';
import {ThemeProps} from '../../../../../theme/theme';

const AvatarStyles = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  .ant-avatar.ant-avatar-icon {
    margin: 0 ${({theme}) => theme.grid.gutter.sm} 0 0;
    background-color: ${(props) => props.theme.colors.WHITE};
  }
  > div {
    align-self: center;
    font-size: 10px;
    line-height: 10px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.GREY1};
    margin-top: -4px;
  }
`;

const UserAvatar = () => {
  const {authState} = useOktaAuth();

  const email = authState?.accessToken?.claims?.email;
  const splitEmail = email && email.split('@');

  return (
    <AvatarStyles data-e2e='navigation__avatar'>
      <Avatar icon={<BlueAvatarIcon />} />

      {splitEmail && (
        <div>
          <div data-e2e='navigation__avatar-email-first-part'>{splitEmail[0]}</div>
          <div data-e2e='navigation__avatar-email-second-part'>{'@' + splitEmail[1]}</div>
        </div>
      )}
    </AvatarStyles>
  );
};

export default UserAvatar;

import React from 'react';

import {Content} from '../../styles';
import {Title} from '../../../Privacy/styles';

const HealthcareAndInsuranceTerms = () => {
  return (
    <Content>
      <Title>What is the difference between a brand name and generic drug?</Title> A
      <strong> brand name drug</strong> is a prescription drug manufactured by the pharmaceutical
      company that holds the patent for the original drug. A <strong>generic drug</strong> has the
      same active ingredient, strength, dosage, form, route of administration, and efficacy as the
      brand name equivalent.
      <br />
      <br />
      <Title>What is a co-pay?</Title>
      A co-pay, or co-payment, is an amount of money that an insured patient pays out-of-pocket for
      prescription drugs or healthcare services.
      <br />
      Co-pays may be the same for all pharmacies because they are negotiated by your health
      insurance carrier with the pharmacy networks, and remain standard across in-network
      pharmacies. However, if you have coinsurance, a deductible, or go to a pharmacy that is out of
      your insurance plan's network, your co-pay could be more.
      <br />
      <br />
      <Title>What do form, quantity, and dosage mean in the oneRx app?</Title>
      The drug <strong>form</strong> is how the drug is taken (ex. tablet, capsule, gel, syringe,
      etc.). The drug <strong>quantity</strong> is how many of a certain form of drug is being
      prescribed (ex. 30 tablets, 1 vial, 100 mL, etc.). The drug <strong>dosage</strong> is the
      strength of your prescription (ex. 20 mg, 80 mg, etc.).
      <br />
      <br />
      <Title>What is prior authorization?</Title>
      If your drug requires prior authorization, it means your health plan needs to review the
      medication before it will be covered. Your plan wants to know that it is the right medication
      for the right situation. Please let your prescriber know that your prescription requires prior
      authorization. He/she might switch your therapy to another drug that doesn't require approval
      from the health plan, or will contact your plan to initiate the approval process.
      <br />
      <br />
      <Title>What is step therapy?</Title>
      Step therapy is a kind of prior authorization where user needs to try one medication before
      being eligible for another medication. Step therapies are defined by the health insurance
      company to promote the most cost-effective and safest drug therapies. If you see that a
      medication requires step therapy, please let your prescriber know. He/she might switch your
      therapy to another drug that doesn't require approval from the health plan, or will contact
      your plan to initiate the approval process.
      <br />
      <br />
    </Content>
  );
};

export default HealthcareAndInsuranceTerms;

import StorageKeys from '../constants/storageKeys';
import {Routes} from '../pages/routes';

export const getRedirectionPath = (): Routes | void => {
  try {
    return sessionStorage.getItem(StorageKeys.REDIRECT_TO) as Routes;
  } catch (e) {
    console.error(e);
  }
};

export const setRedirectionPath = (pathname: Routes) => {
  try {
    sessionStorage.setItem(StorageKeys.REDIRECT_TO, pathname);
  } catch (e) {
    console.error(e);
  }
};

export const removeRedirectionPath = () => {
  try {
    sessionStorage.removeItem(StorageKeys.REDIRECT_TO);
  } catch (e) {
    console.error(e);
  }
};

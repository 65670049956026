import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';

import {GenericRecoilState, GenericRecoilStateWithParams} from './types';
import generateParamsAtom from './atoms/paramsAtom';
import generateDataAtom from './atoms/dataAtom';
import useDataValue from './hooks/useDataValue';
import generateParamsSelector from './selectors/paramsSelector';

function generateRecoilState<DataType>(
  key: string,
  fetchFn: () => Promise<DataType> | DataType,
): GenericRecoilState<DataType>;

function generateRecoilState<DataType, ParamsType>(
  key: string,
  fetchFn: (params: ParamsType) => Promise<DataType> | DataType,
  defaultParams: ParamsType,
): GenericRecoilStateWithParams<DataType, ParamsType>;

function generateRecoilState<DataType, ParamsType>(
  key: string,
  fetchFn: (params: ParamsType) => Promise<DataType> | DataType,
  defaultParams?: ParamsType,
): any {
  const paramsAtom =
    typeof defaultParams !== 'undefined'
      ? generateParamsAtom(`${key}__params`, defaultParams)
      : undefined;

  const dataAtom = generateDataAtom(`${key}__data`, fetchFn, paramsAtom);
  const useValue = useDataValue.bind(null, dataAtom);
  const useSetValue = useSetRecoilState.bind(null, dataAtom);
  const useResetValue = useResetRecoilState.bind(null, dataAtom);

  if (typeof defaultParams === 'undefined') {
    return {
      dataAtom,
      useValue,
      useSetValue,
      useResetValue,
    };
  }

  const paramsSelector = generateParamsSelector(`${key}__params_selector`, paramsAtom!, dataAtom);
  const useParamsValue = useRecoilValue.bind(null, paramsAtom!);
  const useSetParams = () => useSetRecoilState(paramsAtom!);

  return {
    dataAtom,
    useValue,
    useSetValue,
    useResetValue,
    paramsAtom: paramsSelector,
    useParamsValue,
    useSetParams,
  };
}

export default generateRecoilState;

import React, {FC} from 'react';
import {Col, Row} from 'antd';
import {NavLink} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

import {Routes} from '../../pages/routes';
import ShowResponsive from '../ShowResponsive';
import {FooterProps} from './types';
import FooterStyles from './styles';

const Footer: FC<FooterProps> = ({disableNavigation = false}) => {
  const {authState} = useOktaAuth();

  return (
    <FooterStyles data-e2e='footer-section'>
      <div>
        <p data-e2e='footer-section__rights-place'>
          &copy;{window._env_.CURRENT_YEAR} Truveris. All Rights Reserved.
        </p>

        {disableNavigation ? null : (
          <ShowResponsive min='md'>
            <Row gutter={80}>
              <Col>
                <NavLink
                  data-e2e='footer-section__about-onerx-link'
                  to={{pathname: Routes.ABOUT, state: {canGoBack: true}}}
                >
                  About oneRx
                </NavLink>
              </Col>
              <Col>
                <NavLink
                  data-e2e='footer-section__faq-link'
                  to={{pathname: Routes.FAQ, state: {canGoBack: true}}}
                >
                  FAQ
                </NavLink>
              </Col>
              {authState.isAuthenticated && (
                <Col>
                  <NavLink
                    data-e2e='footer-section__terms-of-use-link'
                    to={{pathname: Routes.TERMS, state: {canGoBack: true}}}
                  >
                    Terms of Use
                  </NavLink>
                </Col>
              )}
              <Col>
                <NavLink
                  data-e2e='footer-section__support-link'
                  to={{pathname: Routes.SUPPORT, state: {canGoBack: true}}}
                >
                  Support
                </NavLink>
              </Col>
            </Row>
          </ShowResponsive>
        )}
      </div>
    </FooterStyles>
  );
};

export default Footer;

import React, {FC} from 'react';
import {Tabs} from 'antd';
import styled, {ThemeProps} from 'styled-components/macro';

import {FAQComponentList} from '../../types';
import {componentsMap} from '../pages';
import {Theme} from '../../../../theme/theme';
import {ContentWrapper} from '../../../Privacy/styles';

const {TabPane} = Tabs;
type Props = ThemeProps<Theme>;

const FAQDesktopContainer = styled.div`
  height: 100%;
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-content {
    height: 97%;
  }
  .ant-tabs-tab-btn {
    font-size: 16px;
    line-height: 18.75px;
    color: ${(props: Props) => props.theme.colors.GREY};
  }
`;

const DesktopFAQNavigation: FC<FAQComponentList> = ({components}) => {
  return (
    <FAQDesktopContainer>
      <Tabs defaultActiveKey='1' tabPosition='top'>
        {components.map((component) => {
          const FAQSection = componentsMap[component.componentName];
          return (
            <TabPane tab={component.displayValue} key={component.componentName}>
              <ContentWrapper>
                <FAQSection />
              </ContentWrapper>
            </TabPane>
          );
        })}
      </Tabs>
    </FAQDesktopContainer>
  );
};

export default DesktopFAQNavigation;

import React from 'react';
import SupportEmailLink from 'pages/FAQ/components/SupportEmailLink';

import {OKTA_USER_STATUS} from '../../services/authorization/types';
import {Optional} from '../../types';

export const getErrorTemplate = (status: Optional<OKTA_USER_STATUS>) => {
  switch (status) {
    case OKTA_USER_STATUS.LOCKED_OUT:
      return (
        <p>
          Your account has been locked for excessive incorrect login attempts. Please email Product
          Support at <SupportEmailLink /> to get your account unlocked
        </p>
      );
    case OKTA_USER_STATUS.DEPROVISIONED:
      return (
        <p>
          Your account has been deactivated. Please email Product Support at <SupportEmailLink />{' '}
          for assistance
        </p>
      );
    case OKTA_USER_STATUS.SUSPENDED:
      return (
        <p>
          Your account has been suspended. Please email Product Support at <SupportEmailLink /> for
          assistance
        </p>
      );
    case OKTA_USER_STATUS.RECOVERY:
    case OKTA_USER_STATUS.PASSWORD_EXPIRED:
      return <p>You have entered an incorrect email or password</p>;
    default:
      throw new Error();
  }
};

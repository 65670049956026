import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';
import Container from '../../components/SignInLayout/components/Container';

export const ButtonWrapper = styled.div<ThemeProps>`
  margin-bottom: ${({theme}) => theme.grid.gutter.md};
  > * {
    padding-left: 0;
  }
`;

export const ContentWrapper = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.H6})};
  color: ${(props: ThemeProps) => props.theme.colors.GREY1};
  font-weight: normal;
`;

export const HeaderWrapper = styled.div<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.colors.GREY};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: ${({theme}) => theme.grid.gutter.xs};
`;

export const IconStyle = styled.div<ThemeProps>`
  > * {
    color: ${(props: ThemeProps) => props.theme.colors.GREY};
    font-size: 24px;
  }
`;

export const InstructionWrapper = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.body})};
  color: ${(props: ThemeProps) => props.theme.colors.GREY1};
`;

export const SupportContainer = styled(Container)`
  ${({theme}) => theme.forWidth('max', 'lg', 'background-position: 80% 0%;')}
`;

export const SupportNodeWrapper = styled.div<ThemeProps>`
  margin: ${({theme}) => theme.grid.gutter.sm} 0px;
`;

export const TitleWrapper = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.H1})};
  color: ${(props: ThemeProps) => props.theme.colors.BLUE};
  margin-bottom: ${({theme}) => theme.grid.gutter.xs};
`;

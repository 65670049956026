import React from 'react';

// Use styles from Privacy Policy
import {ButtonWrapper, ContentWrapper, Header, Layout, Logo, Wrapper} from '../Privacy/styles';
import FullPrivacyPolicyContent from './FullPrivacyPolicyContent';
import HeaderNavigation from '../../components/Navigation/components/Header';
import Footer from '../../components/Footer';
import OptionalWrapper from '../../hocs/OptionalWrapper';

const FullPrivacyPolicy = () => {
  return (
    <>
      <HeaderNavigation isClickableLogo={false} />
      <Wrapper>
        <OptionalWrapper Wrapper={ButtonWrapper}>
          {/* PLACEHOLDER to keep consistency between similar pages */}
        </OptionalWrapper>
        <Layout>
          <Header>
            <Logo>oneRx </Logo>
            <span>Privacy Policy</span>
          </Header>
          <ContentWrapper>
            <FullPrivacyPolicyContent />
          </ContentWrapper>
        </Layout>
      </Wrapper>
      <Footer disableNavigation={true} />
    </>
  );
};

export default FullPrivacyPolicy;

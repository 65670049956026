import React from 'react';
import {Link} from 'react-router-dom';

import GoBack from '../../components/GoBack';
import OptionalWrapper from '../../hocs/OptionalWrapper';
import {OptionalButtonWrapper, Wrapper} from './styles';
import heroBg from '../../assets/about-bg.png';
import hiwImage from '../../assets/about-hiw.png';
import laptopImage from '../../assets/about-laptop.png';
import {Routes} from '../routes';
import {StyledButton} from '../../components/Button/components/SubmitButton';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

const About = () => (
  <>
    <Navigation />
    <Wrapper>
      <OptionalWrapper noEmpty Wrapper={OptionalButtonWrapper}>
        <GoBack />
      </OptionalWrapper>
      <div className='content'>
        <div className='hero'>
          <div className='hero--background'>
            <img src={heroBg} alt='About oneRx' />
          </div>
          <div className='hero--text'>
            Don't spend any more money on your prescriptions than you have to.
          </div>
        </div>
        <div className='section section--video'>
          <div className='section--video_wrapper'>
            <div className='section--video_text'>
              With oneRx, you could save thousands of dollars a year on your prescription
              medications.
            </div>
            <div className='section--video_container'>
              <iframe
                className='section--video_container--video'
                title='oneRx'
                src='https://player.vimeo.com/video/657936762?h=64e6c42bbf&title=0&byline=0&portrait=0'
                frameBorder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className='section section--columns section--how-it-works'>
          <div className='image-wrapper'>
            <img src={hiwImage} alt='How It works' />
          </div>
          <div className='section--content'>
            <h2>How oneRx works</h2>
            <ol>
              <li>Search for your prescription medication.</li>
              <li>
                Compare your medication price with your insurance to the oneRx discount price.
              </li>
              <li>
                If you choose to use the oneRx discount, show the oneRx card to your pharmacist.
              </li>
            </ol>
          </div>
        </div>
        <div className='section section--columns section--learn-more'>
          <div className='image-wrapper'>
            <img src={laptopImage} alt='' />
          </div>
          <div className='section--content'>
            <h2> Learn more about...</h2>
            <div className='section--content_main'>
              <ul>
                <li>Why you need oneRx</li>
                <li>How to set up a oneRx account</li>
                <li>How to use a oneRx discount card</li>
              </ul>
              <Link to={{pathname: Routes.FAQ, state: {canGoBack: true}}}>Visit our FAQ page</Link>
            </div>
          </div>
        </div>
        <div className='section section--access'>
          <h2>Don’t have access to oneRx? Learn how to bring it to your company.</h2>
          <StyledButton
            type='primary'
            size='large'
            htmlType='submit'
            primaryYellow
            onClick={() => window.open('https://truveris.com/contact-sales/ ')}
          >
            Click here
          </StyledButton>
        </div>
      </div>
    </Wrapper>
    <Footer />
  </>
);

export default About;

import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';

import {StyledButton} from '../../../../pages/DrugLookup/components/ResultDetails/components/ResultCard';
import {Routes} from '../../../../pages/routes';
import {ThemeProps} from '../../../../theme/theme';
import shouldHideSignIn from '../../../../utils/shouldHideSignIn';

const SignInButtonWrapper = styled.div<ThemeProps>`
  align-self: center;
  ${StyledButton} {
    width: 150px;
    display: unset;
  }
`;

const SignInButton = () => {
  if (shouldHideSignIn()) {
    return null;
  }

  return (
    <SignInButtonWrapper>
      <Link to={Routes.SIGN_IN}>
        <StyledButton type='primary' primaryGreen size='large' htmlType='button'>
          Sign in
        </StyledButton>
      </Link>
    </SignInButtonWrapper>
  );
};

export default SignInButton;

import React from 'react';
import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';
import DesktopFAQNavigation from './components/DesktopFAQ';
import MobileFAQNavigation from './components/MobileFAQ';
import ShowResponsive from '../../components/ShowResponsive';
import Navigation from '../../components/Navigation';
import {ButtonWrapper, Header, Layout, Logo, Wrapper} from '../Privacy/styles'; //treating privacy as the parent styling
import Footer from '../../components/Footer';
import {FAQComponents} from './components/pages';
import GoBack from '../../components/GoBack';
import OptionalWrapper from '../../hocs/OptionalWrapper';

const UnboldFAQHeader = styled.span<ThemeProps>`
  font-weight: 400;
`;

const FAQ = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <OptionalWrapper noEmpty Wrapper={ButtonWrapper}>
          <GoBack />
        </OptionalWrapper>
        <Layout>
          <Header>
            <Logo>oneRx </Logo>
            <UnboldFAQHeader>Frequently Asked Questions</UnboldFAQHeader>
          </Header>
          <ShowResponsive max='lg'>
            <MobileFAQNavigation components={FAQComponents.components} />
          </ShowResponsive>
          <ShowResponsive min='lg' excludeMin>
            <DesktopFAQNavigation components={FAQComponents.components} />
          </ShowResponsive>
        </Layout>
      </Wrapper>
      <Footer />
    </>
  );
};

export default FAQ;

import HttpClient from '../httpClient';

const updateLoginTimestamp = (id: string) => {
  return HttpClient.put({
    url: `/employees/me/last_login`,
    data: {
      user_id: id,
    },
  });
};

export default updateLoginTimestamp;

import React, {FC} from 'react';
import {LoginCallback as BaseLoginCallback} from '@okta/okta-react';
import {OnAuthResumeFunction} from '@okta/okta-react/bundles/types/OktaContext';
import {useHistory} from 'react-router';

import StorageKeys from '../../constants/storageKeys';
import useEffectOnce from '../../hooks/useEffectOnce';
import {Routes} from '../routes';

const LoginCallback: FC<{
  errorComponent?: React.ComponentType<{
    error: Error;
  }>;
  onAuthResume?: OnAuthResumeFunction;
}> = (props: any) => {
  const history = useHistory();
  const params = new URLSearchParams(props.location.search);

  if (params.get('error')) {
    sessionStorage.setItem(StorageKeys.SIGN_IN_FAILED, 'true');

    history.replace(Routes.SIGN_IN);
  }

  useEffectOnce(() => {
    sessionStorage.setItem(StorageKeys.SHOULD_UPDATE_LOGIN_TIMESTAMP, String(true));
  });

  return <BaseLoginCallback {...props} />;
};

export default LoginCallback;

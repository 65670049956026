import React from 'react';
import {Link} from 'react-router-dom';

import {Content} from '../../styles';
import {Title} from '../../../Privacy/styles';
import {Routes} from '../../../routes';

const AboutOneRX = () => {
  return (
    <Content>
      <Title>What is oneRx?</Title>
      oneRx® is a prescription pricing service that lets you compare prices for medications and
      instantly redeem savings. oneRx lets you know if there are discounts available for your drugs,
      which can help you lower costs regardless of whether you're insured or uninsured. If you have
      insurance, oneRx lets you view your co-pay and identify any restrictions before you go to the
      pharmacy so you don't leave empty handed. At oneRx, we're on a mission to reduce health care
      costs for Americans by putting real-time prescription savings at your fingertips.
      <br />
      <br />
      <Title>What is the oneRx Drug Savings Card?</Title>
      The oneRx Card is a prescription benefit option for those without insurance coverage, offering
      discounts on almost every drug. If you have insurance, there may be drugs for which it is
      cheaper for you to use the oneRx Card instead of your insurance coverage. oneRx lets you know
      when this is the case.
      <br />
      <br />
      <Title>Is oneRx a type of insurance?</Title>
      oneRx is NOT insurance, it is a free website and app that may offer lower costs for your
      medications based on negotiated pricing with pharmacies. For more information, please refer to
      our <Link to={Routes.TERMS}>Terms of Use</Link>
      <br />
      <br />
      <Title>Can I use the oneRx Drug Savings Card if I am insured or uninsured?</Title>
      Yes, both insured and uninsured users can get the oneRx discount. If you are insured, you must
      decline using your insurance benefits in order to use the oneRx discount. oneRx lets you know
      when the oneRx price is lower than your insurance co-pay.
      <br />
      <br />
      <Title>Can I combine oneRx with my insurance?</Title>
      No, when you use a oneRx drug savings card, you are NOT using your insurance. If you want to
      get the oneRx discount, be sure to tell your pharmacist that you are NOT using insurance and
      would like to use oneRx as a cash-paying patient. Please note that if you use oneRx, drug
      costs probably will not count toward your deductible.
      <br />
      <br />
      <Title>How do oneRx discounts and savings work?</Title>
      oneRx offers discounts on generic and prescription drugs through a pre-negotiated national
      pharmacy network. Prescription drug costs may vary by pharmacy due to differences in both
      retail and wholesale drug prices. Prices may change over time as pharmacy network agreements
      are updated.
      <br />
      <br />
      <Title>Are there age restrictions for oneRx?</Title>
      You must be at least 18 years of age to sign up for a oneRx account. However, there are no
      restrictions by age regarding the use of the oneRx. For example, parents may sign up for a
      oneRx account and get oneRx price discounts on behalf of their minor dependent children.
      <br />
      <br />
      <Title>
        Can I get oneRx discounts if I am on Medicare (including when I enter the "doughnut hole" in
        my Medicare coverage)?
      </Title>
      Generally, pricing for prescriptions covered through a Medicare plan should be lower than
      oneRx discounts. If the prescription drug is not covered by your Medicare plan, oneRx
      discounts may help you save money. However, you can not combine oneRx discounts with Medicare.
      <br />
      <br />
    </Content>
  );
};

export default AboutOneRX;

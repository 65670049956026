import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';

export const OptionalButtonWrapper = styled.div`
  width: 100%;
  max-width: ${(props: ThemeProps) => props.theme.grid.breakpoints.xl}px;
  margin: ${({
    theme: {
      grid: {
        gutter: {xlg},
      },
    },
  }) => `0 0 ${xlg}`};

  ${({theme}) => theme.forWidth('min', 'xlg', '')}
`;

export const Wrapper = styled.div`
  padding: ${(props: ThemeProps) => props.theme.grid.gutter.xlg};
  width: 100%;
  height: 100%;

  ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `padding: 40px 10px 40px;`)};

  .content {
    border: 1px solid ${(props: ThemeProps) => props.theme.colors.GREY2};
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .hero {
    position: relative;
    border-radius: 10px;

    &--background {
      > img {
        border-radius: 10px 10px 0 0;
        width: 100%;
      }
    }

    &--text {
      font-family: 'Times New Roman';
      left: 250px;
      top: 250px;
      position: absolute;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      color: ${(props: ThemeProps) => props.theme.colors.WHITE};
      line-height: 46px;
      max-width: 650px;
      z-index: 1000;

      ${({theme}: ThemeProps) =>
        theme.forWidth(
          'max',
          'lg',
          `
              top: 115px;
              left: 81px;
              font-weight: 400;
              max-width: 360px;
              font-size: 30px;
              line-height: 34px;
           `,
        )};

      ${({theme}: ThemeProps) =>
        theme.forWidth(
          'max',
          'xl',
          `
              top: 150px;
              left: 140px;
              font-weight: 400;
              max-width: 560px;
              font-size: 30px;
              line-height: 34px;
           `,
        )};

      ${({theme}: ThemeProps) =>
        theme.forWidth(
          'max',
          'md',
          `
              left: 38px;
              top: 44px;
              font-weight: 400;
              max-width: 190px;
              font-size: 16px;
              line-height: 18px;
           `,
        )};
    }
  }

  .section {
    h2 {
      display: block;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: ${(props: ThemeProps) => props.theme.colors.BLUE};
    }

    &--last {
      border-radius: 10px;
      min-height: 40px;
    }

    .image-wrapper {
      ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `margin: auto;`)};
    }

    &--columns {
      ${({theme}: ThemeProps) => theme.forWidth('min', 'xl', `padding-left: 265px !important;`)};

      ${({theme}: ThemeProps) => theme.forWidth('min', 'md', `padding-left: 15px;`)};

      & > div {
        &:nth-of-type(even) {
          ${({theme}: ThemeProps) => theme.forWidth('min', 'lg', `width: 50%;`)};
        }
      }

      display: flex;
      gap: 50px;
      margin: auto;

      ${({theme}: ThemeProps) => theme.forWidth('min', 'lg', `justify-content: center;`)};

      ${({theme}: ThemeProps) =>
        theme.forWidth(
          'max',
          'sm',
          `
            flex-direction: column;
            gap: 0;
          `,
        )};
    }

    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 18px;
    color: ${(props: ThemeProps) => props.theme.colors.GREY};

    &:nth-of-type(even) {
      background: ${(props: ThemeProps) => props.theme.colors.WHITE};
    }

    &:nth-of-type(odd) {
      background: ${(props: ThemeProps) => props.theme.colors.GREY19};
    }

    &--video {
      &_wrapper {
        margin: auto;

        & > .section--video_text {
          ${({theme}: ThemeProps) => theme.forWidth('min', 'lg', `max-width: 510px;`)};
        }

        ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `width: 100%;`)};
      }

      color: ${(props: ThemeProps) => props.theme.colors.GREY};
      display: flex;
      flex-direction: column;
      padding: 40px 15px 10px;
      font-weight: 400;
      font-size: 20px;
      gap: 40px;
      line-height: 20px;
      text-align: center;

      &_container {
        iframe {
          ${({theme}: ThemeProps) => theme.forWidth('min', 'lg', `width: 460px;`)};

          ${({theme}: ThemeProps) =>
            theme.forWidth(
              'min',
              'lg',
              `
                width: 460px;
                height: 260px;
              `,
            )};
        }

        &--video {
          margin-top: 40px;
        }
      }
    }

    &--how-it-works {
      padding: 20px 0;
      ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `gap: 20px;`)};

      ${({theme}: ThemeProps) => theme.forWidth('max', 'lg', `padding: 15px;`)};

      & > .section--content {
        ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `padding: 0 15px;`)};
      }

      h2 {
        text-align: left;
      }

      ol {
        align-self: stretch;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          ${({theme}: ThemeProps) => theme.forWidth('max', 'lg', `line-height: 16px;`)};
          color: ${(props: ThemeProps) => props.theme.colors.BLACK};
          text-align: left;
        }
      }
    }

    &--learn-more {
      padding: 30px 0;
      text-align: start;

      ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `gap: 20px;`)};

      ${({theme}: ThemeProps) =>
        theme.forWidth(
          'max',
          'lg',
          `
              justify-content: flex-start;
              padding-left: 15px;
          `,
        )};

      h2 {
        ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `margin-left: 58px;`)};
      }

      & > .section--content {
        .section--content_main {
          ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `margin: 0 0 0 58px;`)};
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0;

        li {
          text-align: start;
          line-height: 18px;
          font-size: 14px;

          ${({theme}: ThemeProps) => theme.forWidth('max', 'sm', `font-size: 14px;`)};
        }
      }

      a {
        text-decoration: underline;
        text-align: start;
      }
    }

    &--access {
      padding: 20px 0;

      button {
        span {
          color: ${(props: ThemeProps) => props.theme.colors.BLUE};
        }
      }

      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;

        ${({theme}: ThemeProps) =>
          theme.forWidth(
            'max',
            'sm',
            `
                text-align: start;
                font-size: 18px;
                padding: 10px 15px;
                line-height: 21px;
            `,
          )};
      }
    }
  }
`;

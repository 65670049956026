import React from 'react';

import {Content} from '../../styles';
import {Title} from '../../../Privacy/styles';
import SupportEmailLink from '../SupportEmailLink';

const Pharmacies = () => {
  return (
    <Content>
      <Title>Can I use oneRx Discount at any pharmacy?</Title>
      You can get oneRx discount prices at over 60,000 pharmacies in the United States and Puerto
      Rico, including many major chains. If your preferred pharmacy does not show up, then it is not
      in the oneRx Retail network.
      <br />
      <br />
      <Title>Why can't I find my pharmacy?</Title>
      Your pharmacy may not show up in search results because it is not in the oneRx retail network.
      In this case, you cannot use the oneRx Card at this pharmacy, but you can still use your
      insurance.
      <br />
      <br />
      <Title>My insurance co-pay was different from the amount shown by oneRx. Why?</Title>
      oneRx shows an approximate co-pay amount that may vary slightly by pharmacy. This can occur if
      your plan has a coinsurance policy, which requires you to pay a fixed percentage of the cost
      of the drug. It can also occur if the drug's retail price at the pharmacy (without a discount
      card) is lower than your insurance plan's co-pay; in this case, you will pay the lower price.
      <br />
      <br />
      <Title>
        Why did the pharmacy charge a different price or refuse the price from what oneRx quoted?
      </Title>
      If the pharmacy charges you a different price than what was quoted by oneRx, or if they
      refuses the oneRx discount, please contact us at <SupportEmailLink />. Please include your
      pharmacy information and prescription details so that we may escalate this to our Pharmacy
      Support. We will reach out to the pharmacy to determine the issue and notify you once the case
      is resolved.
      <br />
      <br />
    </Content>
  );
};

export default Pharmacies;

import {Optional} from '../types';
import {ValidationMessages} from './validation';
import {
  oneLowerCase,
  oneNumericCharacter,
  oneUpperCase,
  checkEmailSequence,
  minLen,
} from './validation/passwordValidations';

export interface passwordMessageControl {
  item: string;
  validator: (value: Optional<string>, email: Optional<string>) => boolean;
}

export const validateOktaHintMessages: passwordMessageControl[] = [
  {item: ValidationMessages.TOOLTIP_MIN_8, validator: (value, email) => minLen(value)},
  {
    item: ValidationMessages.ONE_UPPERCASE,
    validator: (value, email) => oneUpperCase(value),
  },
  {
    item: ValidationMessages.ONE_LOWERCASE,
    validator: (value, email) => oneLowerCase(value),
  },

  {
    item: ValidationMessages.ONE_NUMERIC_CHARACTERS,
    validator: (value, email) => oneNumericCharacter(value),
  },
  {
    item: ValidationMessages.EMAIL_SEQUENCE_VALUE,
    validator: (value, email) => checkEmailSequence(value, email),
  },
];

const validateOktaPassword = (value: Optional<string>, email: Optional<string>) => {
  if (!value) {
    return;
  }
  if (!email) {
    email = ''; // TODO should be return; should be changed when during implementation for forgot password
  }

  const message = validateOktaHintMessages.find(
    (message) => !message.validator(value, email),
  )?.item;

  return message;
};

export default validateOktaPassword;

import {AxiosError} from 'axios';

import {ServerError} from '../getErrorMessage';

export interface SessionResponseSchema {
  session_id: string;
  text?: string;
}

export enum SessionHTTPMetaData {
  SESSION_ID_STORAGE = 'X-LS-Session-Id',
  SESSION_API_URL = '/session/',
  SESSION_ERROR = '/session_errors/',
}

export const SessionExemptURIs = Object.freeze([
  SessionHTTPMetaData.SESSION_API_URL,
  '/account/forgot_password',
  '/account/activate_account',
  '/account/verify_recovery_token',
  '/account/reset_password',
  '/account/decline_terms_of_use',
  '/account/register',
  '/account/user_status',
]);

export interface MakeRequestHeader {
  Authorization: string;
  [SessionHTTPMetaData.SESSION_ID_STORAGE]?: string;
}

export interface PostSessionErrorData {
  error: AxiosError<ServerError>;
  headers: MakeRequestHeader;
}

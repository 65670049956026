import styled from 'styled-components/macro';

import {ThemeProps} from '../../../../../theme/theme';
import {DisabledLink, Tooltip} from '../../NavLinks/styles';

const StyledList = styled.ul<ThemeProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    a {
      color: ${({theme}) => theme.colors.GREY};
      font-size: 16px;
      line-height: 40px;
      display: block;
      font-weight: normal;
      margin: 4px 0;

      &.active {
        color: ${({theme}) => theme.colors.BLUE3};
        font-weight: 500;
        pointer-event: none;
      }
    }

    ${DisabledLink} {
      line-height: 40px;
      font-size: 16px;
      margin: 0 0 4px;

      ${Tooltip} {
        height: auto;
      }
    }
  }
`;

export default StyledList;

export enum OKTA_USER_STATUS {
  LOCKED_OUT = 'LOCKED_OUT',
  STAGED = 'STAGED',
  PROVISIONED = 'PROVISIONED',
  ACTIVE = 'ACTIVE',
  RECOVERY = 'RECOVERY',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  SUSPENDED = 'SUSPENDED',
  DEPROVISIONED = 'DEPROVISIONED',
}

export interface SuccessResponse {
  success?: boolean;
}

export interface UserStatusResponse {
  status?: OKTA_USER_STATUS;
}

export interface ForgotPasswordSchema {
  email: string;
}

export interface UserStatusSchema {
  email: string;
}

export interface ResetPasswordRequestData {
  new_password: string;
  recovery_answer: string;
  state_token: string;
}

export interface VerifyRecoveryTokenResponse {
  state_token: string;
  recovery_question: string;
}

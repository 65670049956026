import React from 'react';
import {Link} from 'react-router-dom';

import {Content} from '../../styles';
import {Routes} from '../../../routes';
import {Title} from '../../../Privacy/styles';
import SupportEmailLink from '../SupportEmailLink';

const YourAccount = () => {
  return (
    <Content>
      <Title>How does oneRx use my data?</Title>
      oneRx strives to maintain the highest data security and privacy standards. To better
      understand data usage, please read the following:
      <br />
      <Link to={Routes.TERMS}>Terms of Use</Link>: We maintain high standards of transparency, data
      security, and confidentiality. The Terms of Use explains our policies governing usage of
      oneRx.
      <br />
      <br />
      <Title>Will my medical information be kept private?</Title>
      We adhere to federal HIPAA regulations to keep your personally identifiable and prescription
      information saved in oneRx private and confidential. Before you looked up your first drug, you
      reviewed and signed our HIPAA policy, which you can view from the{' '}
      <Link to={Routes.MY_ACCOUNT}>My Account</Link> page in your oneRx profile.
      <br />
      <br />
      <Title>How can I contact or get help for oneRx?</Title>
      You can contact us at any time in the following ways:
      <ul>
        <li>
          Email us at <SupportEmailLink />
        </li>
        <li>Send us a message from your account in the Support section.</li>
      </ul>
      <br />
      <Title>How do I change or update my account information?</Title>
      You can manage the following things about your oneRx account:
      <ul>
        <li>
          On the <Link to={Routes.MY_INFO}>My Info</Link> page, you can edit your first and last
          name, date of birth, gender, and ZIP code.
        </li>
        <li>
          On the <Link to={Routes.MY_INSURANCE}>My Insurance</Link> page, you can edit the RxBIN,
          RxPCN, RxGRP and Person Code for your pharmacy benefits.
        </li>
        <li>
          On the <Link to={Routes.MY_ACCOUNT}>My Account</Link> page, you can view the HIPAA policy
          you signed when you first used the website, and you can change your oneRx password.
        </li>
      </ul>
      <br />
      <br />
    </Content>
  );
};

export default YourAccount;

import React, {MouseEvent, useState} from 'react';
import {Drawer} from 'antd';
import {CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {useOktaAuth} from '@okta/okta-react';
import {NavLink, useRouteMatch} from 'react-router-dom';

import NavLinks from '../NavLinks';
import CircleButton from './components/CircleButton';
import DrawerHeader from './components/DrawerHeader';
import {Routes} from '../../../../pages/routes';
import StyledList from './components/StyledList';
import UserAvatar from './components/UserAvatar';
import CollapseMenuItem from './components/CollapseMenuItem';
import signOut from '../../../../utils/signOut';
import isHAEmployer from '../../../../utils/isHaEmployer';
import {setRedirectionPath} from '../../../../utils/redirectionPath';
import shouldHideSignIn from '../../../../utils/shouldHideSignIn';

const MobileNavigation = () => {
  const {oktaAuth, authState} = useOktaAuth();

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const logOut = (e: MouseEvent) => {
    e.preventDefault();
    if (isHAEmployer()) {
      setRedirectionPath(Routes.HA_SIGNED_OUT);
    }
    signOut(oktaAuth);
    onClose();
  };

  const hasMatchMyProfile = Boolean(useRouteMatch(Routes.ME));

  return (
    <>
      <CircleButton onClick={showDrawer}>
        <MenuOutlined />
      </CircleButton>

      <Drawer
        placement='right'
        closable={false}
        onClose={onClose}
        visible={open}
        width='70%'
        className='mobileMenu'
        title={
          <DrawerHeader>
            <div>
              <UserAvatar />
              <CircleButton onClick={onClose}>
                <CloseOutlined />
              </CircleButton>
            </div>

            {authState.isAuthenticated && (
              <CollapseMenuItem title='My Profile' defaultExpanded={hasMatchMyProfile}>
                <NavLink to={Routes.MY_INFO} onClick={onClose}>
                  My Information
                </NavLink>
                <NavLink to={Routes.MY_INSURANCE} onClick={onClose}>
                  My Insurance
                </NavLink>
                <NavLink to={Routes.MY_ACCOUNT} onClick={onClose}>
                  My Account
                </NavLink>
                <a href=' ' onClick={logOut}>
                  Log Out
                </a>
              </CollapseMenuItem>
            )}
          </DrawerHeader>
        }
      >
        <StyledList>
          {authState.isAuthenticated ? (
            <NavLinks onClick={onClose} />
          ) : (
            !shouldHideSignIn() && (
              <li>
                <NavLink to={Routes.SIGN_IN} onClick={onClose}>
                  Sign In
                </NavLink>
              </li>
            )
          )}

          <li>
            <NavLink to={{pathname: Routes.ABOUT, state: {canGoBack: true}}} onClick={onClose}>
              About oneRx
            </NavLink>
          </li>
          <li>
            <NavLink to={{pathname: Routes.FAQ, state: {canGoBack: true}}} onClick={onClose}>
              FAQ
            </NavLink>
          </li>
          {authState.isAuthenticated && (
            <li>
              <NavLink to={{pathname: Routes.TERMS, state: {canGoBack: true}}} onClick={onClose}>
                Terms of Use
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to={{pathname: Routes.SUPPORT, state: {canGoBack: true}}} onClick={onClose}>
              Support
            </NavLink>
          </li>
        </StyledList>
      </Drawer>
    </>
  );
};

export default MobileNavigation;

import styled, {ThemeProps} from 'styled-components/macro';

import {Theme} from '../../theme/theme';
import {InputProps} from './types';

//TODO: as soon we use InputStyles, InputErrorStyles in Input, Select, Number Input component , better to extract as shared component

type Props = InputProps & ThemeProps<Theme>;

const InputStyles = styled.div<Props>`
  label {
    display: block;
    padding: 0 0 4px ${(props: Props) => props.theme.grid.gutter.xs};
    color: ${(props: Props) => {
      if (props.error) return props.theme.colors.RED;
      return props.theme.colors.GREY1;
    }};
    opacity: ${(props: Props) => (props.disabled ? 0.5 : 1)};
    ${(props: Props) => ({...props.theme.textStyle.inputLabel})};
  }

  &.admin__search-input {
    display: flex;
    flex-direction: column;
    .input-hint {
      font-size: 14px;
    }
  }

  .ant-input {
    height: 48px;
    color: ${(props: Props) => props.theme.colors.GREY};
    padding: ${(props: Props) => props.theme.grid.gutter.sm}
      ${(props: Props) => props.theme.grid.gutter.xs};
    border: 1px solid
      ${(props: Props) => {
        if (props.disabled) return props.theme.colors.GREY3;
        if (props.error) return props.theme.colors.RED;
        return props.theme.colors.BLUE4;
      }};

    border-radius: 10px;
    background-color: ${(props: Props) =>
      props.disabled ? props.theme.colors.GREY3 : props.theme.colors.GREY4};

    opacity: ${(props: Props) => (props.disabled ? 0.5 : 1)};
    transition: none;
  }

  .ant-input:hover,
  .ant-input:active {
    border: 1px solid
      ${(props: Props) => {
        if (props.disabled) return props.theme.colors.GREY3;
        if (props.error) return props.theme.colors.RED;
        return props.theme.colors.BLUE3;
      }};

    opacity: ${(props: Props) => (props.disabled ? 0.5 : 1)};
  }

  .ant-input:focus,
  .ant-input:active {
    background-color: ${(props: Props) => props.theme.colors.PURPLE3};
    caret-color: ${(props: Props) => props.theme.colors.BLUE3};
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-input::placeholder {
    color: ${(props: Props) => props.theme.colors.GREY};
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
  }

  .ant-input:hover::placeholder,
  .ant-input:active::placeholder {
    opacity: 1;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }

  input:invalid {
    box-shadow: none !important;
  }
`;

export const InputErrorStyles = styled.div<Props>`
  color: ${(props: Props) => props.theme.colors.RED};
  padding: 4px 0 0;
  ${(props: Props) => ({...props.theme.textStyle.inputLabel})};
  position: absolute;
`;

export const InputHint = styled.span`
  font-style: italic;
  font-size: 12px;
  padding: 4px 0 0;
  line-height: 18px;
  color: ${(props) => props.theme.colors.GREY1};
`;

export const PopoverContentStyles = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${(props: Props) => props.theme.colors.GREY1};
  }

  p.error {
    color: ${(props: Props) => props.theme.colors.RED};
  }

  p.valid {
    color: ${(props: Props) => props.theme.colors.GREY};
  }

  svg {
    width: 15px;
  }
  span {
    width: 200px;
  }
`;

export default InputStyles;

import React, {FC} from 'react';
import {Popover as AntPopover} from 'antd';
import styled from 'styled-components/macro';

import {PopoverProps} from './types';
import styles from './styles';

const PopoverWrapper = ({className, innerWidth, ...props}: PopoverProps) => (
  <AntPopover {...props} overlayClassName={className} />
);

const Popover: FC<PopoverProps> = styled(PopoverWrapper)`
  ${styles}
`;

export default Popover;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';
import {AuthWrapperProps} from './types';

type Props = ThemeProps & AuthWrapperProps;

export const AuthWrapperStyles = styled.div<Props>`
  padding: 0 ${(props: Props) => props.theme.grid.gutter.sm};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
 
  height: 100%;
  z-index: 1;
  background-color: ${(props: Props) => `${props.theme.colors._BLUE2}1A`}};

  ${({theme}) => theme.forWidth('min', 'md', `padding: ${theme.grid.padding};`)}

  ${({theme}) => theme.forWidth('min', 'xxl', 'overflow: hidden;')}

  :after {
    content: '';
    position: absolute;
    width: ${(props: Props) => `${props.theme.grid.breakpoints['md']}px`};
    height: ${(props: Props) => `${props.theme.grid.breakpoints['md']}px`};
    border-radius: 50%;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 100px);
    background-color: ${(props: Props) => props.theme.colors.WHITE};
    
    
   ${(props: Props) => ({...props?.circlePosition})}
  }

  :after {
    ${({theme}) =>
      theme.forWidth(
        'min',
        'md',
        `width:  ${theme.grid.breakpoints['lg']}px; height: ${theme.grid.breakpoints['lg']}px; 
         transform: scale(1.1); left: -50%;`,
      )}

      ${({theme}) =>
        theme.forWidth(
          'min',
          'lg',
          'width: 50%; height: 100vh; transform: scale(1.5); top: -20%; left: -12%;',
        )}
  }
`;

export const LogoStyles = styled.div`
  padding-top: 28px;
  img {
    height: 45px;
    max-width: 157px;
  }
`;

export const FormStyles = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 122px;
  z-index: 1;
  position: relative;
  form {
    display: flex;
    flex-direction: column;
  }
  form > div {
    margin-bottom: ${(props: Props) => props.theme.grid.gutter.sm};
    max-width: inherit;
  }
  form {
    ${({theme}) => theme.forWidth('min', 'md', 'max-width: 350px;')}
  }
  ${({theme}) => theme.forWidth('min', 'lg', 'padding-left: 110px;')}
`;

export const TitleStyles = styled.div<Props>`
  h1.ant-typography {
    margin-bottom: ${(props: Props) => props.theme.grid.gutter.xs};
  }
  h1 span {
    color: ${(props: Props) => props.theme.colors.BLUE3};
    font-weight: 500;
  }
  p {
    font-size: 12px;
    line-height: 14px;
    color: ${(props: Props) => props.theme.colors.GREY1};
    margin-bottom: ${(props: Props) => props.theme.grid.gutter.md};
  }
`;

export const HintStyles = styled.h3`
  margin-top: ${(props: Props) => props.theme.grid.gutter.xs};

  color: ${(props: Props) => props.theme.colors.GREY1};
  ${(props: Props) => ({...props.theme.textStyle.body1})};
`;

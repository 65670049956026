import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {Security} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';

import {Routes} from '../pages/routes';
import oktaConfig from '../config/auth';
import {removeRedirectionPath} from '../utils/redirectionPath';

export const getOktaAuth = (): OktaAuth | undefined => {
  return window.__oktaAuth__;
};

const oktaAuth = new OktaAuth({
  issuer: oktaConfig.authConfig.issuer,
  clientId: oktaConfig.authConfig.clientId,
  redirectUri: `${window.location.origin}${Routes.LOGIN_CALLBACK}`,
  responseType: 'token',
  pkce: true,
});
window.__oktaAuth__ = oktaAuth;

const AppWithRouterAccess: FC = ({children}) => {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push(Routes.SIGN_IN);
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    removeRedirectionPath();
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      {children}
    </Security>
  );
};
export default AppWithRouterAccess;

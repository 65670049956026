import HttpClient from '../httpClient';
import {UserStatusSchema, UserStatusResponse} from './types';

const oktaUserStatus = (data: UserStatusSchema) => {
  return HttpClient.post<UserStatusResponse>({
    url: '/account/user_status',
    data,
  });
};

export default oktaUserStatus;

import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

import Input from '../../../components/Input';
import {ThemeProps} from '../../../theme/theme';
import userState from '../../../recoil/userState';

const {useValue} = userState;

const SignatureWidget = styled.div<ThemeProps>`
  font-size: 12px;
  line-height: 12px;
  color: ${(props: ThemeProps) => props.theme.colors.GREY1};
  margin-right: auto;
  font-weight: 400;
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  ${({theme}) => theme.forWidth('min', 'md', 'max-width: 288px')}
  ${({theme}) => theme.forWidth('min', 'lg', 'max-width: 304px')}
  ${({theme}) =>
    theme.forWidth('max', 'sm', `margin-bottom: ${theme.grid.gutter.sm}; margin-right:0;`)}
`;

const SignatureHeader = styled.p<ThemeProps>`
  padding-left: 10px;
  margin-bottom: 10px;
`;

const SignatureInput = styled(Input)`
  padding-left: 10px;
  width: 100%;
`;

const Signature = () => {
  const {data} = useValue();
  const {first_name, last_name} = data?.demographic_info ?? {first_name: '', last_name: ''};
  const current_date = moment().format('MM/DD/YYYY');
  const signature =
    last_name || first_name ? `${first_name} ${last_name}, ${current_date}` : current_date;

  return (
    <SignatureWidget>
      <SignatureHeader>Signature</SignatureHeader>
      <SignatureInput type='text' value={signature} disabled />
    </SignatureWidget>
  );
};

export default Signature;

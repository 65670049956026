import {ThemeProps as AntThemeProps} from 'styled-components/macro';

import colors from './colors';
import breakpoints from './breakpoints';

type TextStyleName =
  | 'H1'
  | 'H2'
  | 'H3'
  | 'H4'
  | 'H5'
  | 'H6'
  | 'body'
  | 'body1'
  | 'link'
  | 'inputLabel';

type Gutters = 'xs' | 'sm' | 'md' | 'lg' | 'xlg';

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface TextStyle {
  fontSize: string;
  lineHeight: string;
  fontWeight?: number;
  color?: string;
}

interface GridStyle {
  padding: string;
  gutter: Record<Gutters, string>;
  breakpoints: Record<Breakpoints, number>;
}

export interface Theme {
  colors: Record<string, string>;
  fontFamily: string;
  buttonFontSizes: Record<string, string>;
  textStyle: Record<TextStyleName, TextStyle>;
  grid: GridStyle;
  forWidth: (
    type: 'min' | 'max',
    breakpoint: Breakpoints,
    css: string,
    exclude?: boolean,
  ) => string;
}

export type ThemeProps = AntThemeProps<Theme>;

const theme: Theme = {
  colors,
  fontFamily: '"Roboto", sans-serif',
  buttonFontSizes: {
    BUTTON_LARGE: '16px',
    BUTTON_SMALL: '14px',
  },
  grid: {
    padding: '0 40px',
    gutter: {
      xs: '8px',
      sm: '16px',
      md: '24px',
      lg: '32px',
      xlg: '40px',
    },
    breakpoints,
  },
  forWidth: function (type, breakpoint, css, exclude) {
    let breakpointValue = this.grid.breakpoints[breakpoint];
    if (exclude) {
      if (type === 'min') {
        breakpointValue += 1;
      } else {
        breakpointValue -= 1;
      }
    }
    return `@media only screen and (${type}-width: ${breakpointValue}px){${css}}`;
  },
  textStyle: {
    // TODO: better to extract as separate style to cover gutters and styles for typography across app
    H1: {
      fontSize: '30px',
      lineHeight: '35px',
      fontWeight: 700,
    },
    H2: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 700,
    },
    H3: {
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    H4: {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 300,
    },
    H5: {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 300,
    },
    H6: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 300,
    },
    body: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    link: {
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.BLUE3,
    },
    inputLabel: {
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
};

export default theme;

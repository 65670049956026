import React, {useRef, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components/macro';

import Modal from '../../../components/Modal';
import {ModalProps} from '../../../components/Modal/types';
import {TitleStyles} from '../../../components/AuthWrapper/styles';
import {ThemeProps} from '../../../theme/theme';
import {useScrollListener} from '../../../hooks/useScrollListener';
import Typography from '../../../components/Typography';
import ModalHipaaFooter from './ModalHipaaFooter';
import {isFullyScrolled} from '../../../utils/isFullyScrolled';

export const GlobalModalMaskStyles = createGlobalStyle<ThemeProps>`
.ant-modal-mask {
  background: ${({theme}) => `${theme.colors.BLACK}${theme.colors.THIRTY}9c`};
  backdrop-filter: blur(5px);
}
`;

const ModalStyled = styled(Modal)`
  top: 65px;

  .ant-modal-footer button + button,
  .ant-modal-footer .ant-btn + .ant-btn {
    margin-left: 20px;
    ${({theme}) =>
      theme.forWidth(
        'max',
        'sm',
        `margin-left: 0px;
        `,
      )};
  }

  .ant-modal-footer button {
    margin-left: 0;
  }

  .ant-modal-body > div {
    border: 1px solid ${(props: ThemeProps) => props.theme.colors.GREY1};
  }

  .ant-modal-body,
  .ant-modal-body > * {
    scrollbar-color: ${(props) => props.theme.colors.GREY} ${(props) => props.theme.colors.GREY2};
  }

  .ant-modal-body > *::-webkit-scrollbar-track,
  .ant-modal-body::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.GREY2};
    border-radius: 10px;
  }
`;

const ModalBody = styled.div<ThemeProps>`
  overflow: auto;
  height: 477px;
  border: 1px solid ${(props: ThemeProps) => props.theme.colors.GREY4};
  border-radius: 4px;
  color: ${(props: ThemeProps) => props.theme.colors.GREY1};
  font-size: 12px;
  line-height: 20px;
  padding: ${(props: ThemeProps) => props.theme.grid.gutter.sm}
    ${(props: ThemeProps) => props.theme.grid.gutter.sm};

  & p,
  & li {
    ${({theme}) => theme.forWidth('max', 'md', `font-size: 12px`)};
  }
`;

const ModalHipaa = ({visible, onOk, onCancel, okButtonProps}: ModalProps) => {
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState<boolean>(true);
  const hipaaBodyRef = useRef<HTMLDivElement>(null);

  const handleAcceptButtonScroll = () => {
    const hipaaBodyWrapper = hipaaBodyRef.current;

    if (null !== hipaaBodyWrapper) {
      if (isFullyScrolled<HTMLDivElement>(hipaaBodyRef)) {
        setAcceptButtonDisabled(false);
      }
    }
  };

  useScrollListener({
    element: hipaaBodyRef.current,
    callBack: handleAcceptButtonScroll,
  });

  return (
    <>
      <GlobalModalMaskStyles />
      <ModalStyled
        visible={visible}
        onCancel={onCancel}
        forceRender={true}
        footer={
          <ModalHipaaFooter
            onOk={onOk}
            onCancel={onCancel}
            okButtonProps={{
              disabled: acceptButtonDisabled || okButtonProps?.disabled,
              // @ts-ignore
              primaryGreen: true,
            }}
          />
        }
        title={
          <TitleStyles>
            <Typography level={1}>oneRx HIPAA Policy</Typography>
          </TitleStyles>
        }
      >
        <ModalBody ref={hipaaBodyRef}>
          <Typography level={3} strong>
            Authorization for Uses and Disclosures of Protected Health Information Health-Related
            Materials
          </Typography>
          <p>
            I hereby authorize my group health plan to release to oneRx my health information (which
            may also have been collected by oneRx on behalf of my group health plan), to help
            determine the health-related materials I will receive as part of my use of the oneRx
            platform. The health-related materials may include information and advertisements
            related to treatments and therapies specific to my health status.
          </p>
          <p>
            The materials may be provided by my group health plan, a pharmaceutical manufacturer, or
            another healthcare entity. oneRx may receive a payment for making such information
            available to me through the oneRx platform, which could include text and email.
          </p>
          <p>
            If I am presented with an advertisement pursuant to this Authorization and I choose to
            request certain information and/or samples as described in the advertisement, then I
            further authorize oneRx to disclose my protected health information to the advertiser as
            designated in the advertisement, such as my name, email address, mailing address, or
            phone number in order to receive such information and/or samples. oneRx may receive a
            payment for releasing my personal information. The use and disclosure of my protected
            health information solely as set forth in this paragraph is valid only for purposes of
            when I choose to receive the information and/or samples, as described in the
            advertisement and until I receive such information and/or samples.
          </p>
          <p>
            The following is the Authorization to provide me personalized content related to my
            health, including without limitation communications about affordability solutions,
            adherence support, and pharmacy services including prescription fulfilment. This
            authorization also allows oneRx, on behalf of my group health plan, to conduct analytics
            using some of the information that I provide to gain insight into and support the
            effectiveness of this my personalized content.
          </p>
          <p>
            oneRx will safeguard my personal information and will not use it for any purpose, other
            than to send me personalized health content to me; send my information to a third party
            pursuant to the third paragraph above; perform and provide services to my group health
            plan, including analytics; anonymously analyze health outcomes in support of my
            personalized content; measure the effect of the health-related materials furnished to me
            (this analysis is computer-automated and involves no human review of my protected health
            information); and carry out any use or disclosure otherwise permitted by this
            Authorization. To provide services to my group health plan, I authorize oneRx to
            de-identify my personal information pursuant to HIPAA and to further disclose or license
            such de-identified information.
          </p>
          <p>
            Although there is the potential for information disclosed pursuant to this Authorization
            to be subject to redisclosure by the recipient and no longer be protected by federal
            privacy rules, oneRx maintains administrative, technical, and physical safeguards as
            required by the Federal Government’s Health Information Privacy Rule, or “HIPAA,” to
            protect each patient’s confidential information. oneRx does not disclose personally
            identifiable information to anyone other than each patient’s group health plan,
            pharmacy, or healthcare provider without this Authorization or as governed, permitted or
            required by law.
          </p>
          <p>
            I do not have to grant this Authorization but, if I do not, I will not receive
            personalized health-related material or, as applicable, receive the materials as
            described in the advertisement. I understand that my healthcare provider will treat me
            regardless of whether I grant this Authorization.
          </p>
          <p>
            I have a right to receive a copy of this Authorization. I may change my mind and revoke
            (take back) this Authorization at any time, except to the extent that my healthcare
            provider or oneRx has already acted based on this Authorization. To revoke this
            Authorization, I must contact my group health plan c/o oneRx in writing (including my
            name, date of birth, gender, home address and healthcare provider’s name) at: oneRx, 3
            Beaver Valley Road, Suite 103, Wilmington, DE, 19803; or support@onerx.com . This
            information will not be used for any purposes other than to verify my identity in order
            to revoke this Authorization.
          </p>

          <p>This Authorization is valid for the following time periods:</p>
          <ul>
            <li>
              {' '}
              Five years from the date on which I grant this Authorization, [date] – for use in
              delivering personalized health-related materials from my group health plan,
              pharmaceutical manufacturers and other healthcare entities on the oneRx platform;
            </li>
            <li>
              {' '}
              Five years from the date on which I grant this Authorization, [date] – for use in
              providing me personalized content on behalf of my group health plan; and
            </li>
            <li>
              {' '}
              Five years from the date on which I grant this Authorization, [date] – for use in
              oneRx’s analytics programs.
            </li>
          </ul>
          <p>
            oneRx is a business associate of my group health plan and is bound by federal law to
            protect and safeguard my protected health information.
          </p>
          <p>
            Authorization signed by: [Signer’s relationship to patient (e.g., “self” or
            “guardian”)],{' '}
          </p>
          <p>[Signer’s name]</p>
        </ModalBody>
      </ModalStyled>
    </>
  );
};

export default ModalHipaa;

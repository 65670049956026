export enum RecoilKeys {
  GENERIC_PARAMS = 'generic/params',
  GENERIC_PAYLOAD = 'generic/payload',
  USER = 'user',
  DRUGS = 'drugs',
  PHYSICIANS = 'physicians',
  PHYSICIAN_OPTIONS = 'physicianOptions',
  PHARMACIES = 'pharmacies',
  PHARMACY_OPTIONS = 'pharmacyOptions',
  SELECTED_DRUG_STATE = 'selectedDrugState',
  DRUG_LOOKUP_SELECTED_STATE = 'drugLookupSelectedState',
  DRUG_FILTERED_FORM = 'drugFilteredFormState',
  DRUG_COST = 'drugCost',
  DRUG_DISCOUNT_PROVIDER = 'discount_provider',
  DRUG_DEA_CLASS_CODE = 'deaClassCode',
  DRUG_ALTERNATIVES = 'drugAlternatives',
  ACTIVE_COST_STATE = 'activeCostState',
  SELECTED_DRUG_COST = 'selectedDrugCost',
  EMPLOYERS = 'employers',
  ALL_EMPLOYERS_OPTIONS = 'allEmployersOptions',
  EMPLOYERS_OPTIONS = 'employersOptions',
  EMPLOYERS_CURRENT = 'employersCurrent',
  NOT_HAVING_PHYSICIAN = 'notHavingPhysician',

  ADMIN_USER_LIST = 'adminAccountsList',
  ADMIN_ROLES_LIST = 'adminRolesList',
  ADMIN_ROLES_LIST_OPTIONS = 'adminRolesListOptions',
  ADMIN_ACCOUNTS_SEARCH = 'adminAccountsSearch',
  ADMIN_ACCOUNT_BY_ID = 'adminAccountById',
  ADMIN_ROLE_BY_ID = 'adminRoleById',
  ADMIN_ACCOUNT_ORDER = 'adminAccountOrder',
  ADMIN_ACCOUNT_FILTER = 'adminAccountFilter',
  ADMIN_ACCOUNT_SELECTION = 'adminAccountSelection',
  ADMIN_DASHBOARD_ORGANIZATIONS = 'adminDashboardOrganizations',
  ADMIN_ORGANIZATIONS = 'adminOrganizations',
  ADMIN_ORGANIZATIONS_LIST = 'adminOrganizationsList',
  ADMIN_ORGANIZATIONS_SEARCH = 'adminOrganizationSearch',
  ADMIN_ORGANIZATIONS_BY_ID = 'adminOrganizationById',
  ADMIN_ORGANIZATIONS_ORDER = 'adminOrganizationOrder',
  ADMIN_ORGANIZATIONS_FILTER = 'adminOrganizationFilter',
  ADMIN_ORGANIZATIONS_SELECTION = 'adminOrganizationSelection',
}

export enum RecoilSelectors {
  SELECT_ACCOUNTS_TABLE = 'selectAccountsToTable',
  SELECT_ORGANIZATIONS_TABLE = 'selectOrganizationsToTable',
}

import {MutableRefObject} from 'react';

import {Optional} from '../types';

const SCROLL_BOTTOM_PADDING = 200;

export const isFullyScrolled = <T extends HTMLElement>(
  container: MutableRefObject<Optional<T>>,
): boolean => {
  if (!container.current) {
    return false;
  }

  const {scrollTop, clientHeight, scrollHeight} = container.current;

  return Math.ceil(scrollTop) + clientHeight + SCROLL_BOTTOM_PADDING >= scrollHeight;
};

const adminOrganizationTexts = {
  organizationInformation: 'Organization information',
  organizationStatus: 'Organization status',
  goLiveDate: 'Go Live Date',
  clientAdministrators: 'Client administrator(s)',
  groupId: 'Group ID',
  activeUsers: 'Active users',
  orgStatus: 'Org status',
  transactionTypes: 'Transaction type(s)',
  backToOrgInventory: 'Back to Org Inventory',
  systemCouldNotSaveOrg: 'The system could not add the organization, please try again later.',
  systemCouldNotSaveOrgChanges:
    'The system could not save the organization changes, please try again later',
  aboutThisOrganization: 'About this Organization',
  organizationName: 'Organization Name',
  enterOrganizationName: 'Enter organization name',
  enterGroupId: 'Enter group ID',
  organizationSetup: 'Organization Setup',
  transactionType_s: 'Transaction type(s)',
  organizationCreated: 'Organization created',
};

const adminGeneralTexts = {
  makeSelectionBeforeUsingThisButton: 'Make a selection before using this button',
  selectedItemsStatusIncompatible:
    'One or more of the selected items ' +
    'are in a status that is incompatible with this action or additional ' +
    'permissions are required',
};

export const changesToEntityWereSuccessfullySaved = (name: string): string =>
  `Changes to ${name} were successfully saved`;

export const maxLengthIsXCharacters = (number: number): string =>
  `Max length is ${number} characters`;

const commonTexts = {
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  saving: 'Saving',
  action: 'Action',
  unknown: 'Unknown',
  users: 'Users',
  organization_s: 'Organization(s)',
  organization: 'Organization',
  oneRxDashboard: 'oneRx Dashboard',
  admin: 'Admin',
  add: 'Add',
  selectedItems: 'selected items',
  search: 'Search',
};

const notificationTexts = {
  pleaseFixAllFields: 'Please fix fields with errors and try again',
};

const adminUsersTexts = {
  bulkAdd: 'Bulk Add',
  bulkUpload: 'Bulk Upload',
  oneRxUsers: 'oneRx Users',
  bulkAddUsers: 'Bulk Add Users',
  bulkUploadSuccess:
    'The file has been successfully uploaded and is ready to be processed. You will receive a status email when the comparison is complete.',
};

const values = {
  ...adminOrganizationTexts,
  ...adminGeneralTexts,
  ...commonTexts,
  ...notificationTexts,
  ...adminUsersTexts,
};

export default values;

import React from 'react';
import {Link} from 'react-router-dom';

import {Content} from '../../styles';
import {Title} from '../../../Privacy/styles';
import {Routes} from '../../../routes';
import SupportEmailLink from '../SupportEmailLink';

const Insurance = () => {
  return (
    <Content>
      <Title>Why does oneRx ask for my insurance information?</Title>
      oneRx can personalize your drug search results if you enter your insurance info to show your
      co-pay amount for drugs. We can also let you know if your insurance has restrictions such as a
      Prior Authorization that may need to be resolved by your prescriber and insurance company
      before you can receive the drug.
      <br />
      <br />
      <Title>Does oneRx support all insurance plans?</Title>
      Currently, we support the vast majority of plans. If you have a "narrow network" plan which
      restricts you to specific pharmacies, you may not be able to search with insurance using
      oneRx. However, you are still eligible for discounts redeemable through the app.
      <br />
      <br />
      <Title>How do I enter or change my insurance information?</Title>
      We will lookup your pharmacy insurance info so that you don’t have to re-enter it for oneRx.
      If we can’t locate this info, you can manually enter and manage it from your oneRx profile.
      You will need your pharmacy benefit card (which may be different than your health insurance
      card) to enter your insurance information on your oneRx account.
      <br />
      To enter or change your insurance info, go to the{' '}
      <Link to={Routes.MY_INSURANCE}>My Insurance</Link> page in your profile and enter your RxBIN,
      RxGRP, RxPCN, and Person Code from your pharmacy benefit card and click Save.
      <br />
      oneRx will immediately let you know if your changes were successfully saved.
      <br />
      <br />
      <Title>
        I've tried to enter my insurance manually, but I'm getting an error. What do I do?
      </Title>
      Make sure you are entering data from your <strong>pharmacy</strong> benefits card, which may
      be different from your health insurance card and check that the fields are entered correctly.
      <br />
      If your insurance still isn't recognized, please contact <SupportEmailLink />. We will be more
      than happy to assist you with the process.
      <br />
      <br />
      <Title>
        Can I use my insurance for some prescriptions and my oneRx Card for different prescriptions?
      </Title>
      Yes, your pharmacist should be able to apply either your insurance benefit or your oneRx
      discount (but not both) to any of your prescriptions. It’s a good idea to check each of your
      drugs to see whether your insurance or oneRx will offer the lowest price for each
      prescription.
      <br />
      <br />
      <Title>
        I tried looking up my co-pay but oneRx couldn't retrieve my price because I have filled my
        script too recently. What does this mean, and what can I do?
      </Title>
      Unfortunately, your plan will not allow us to see prices for some recently filled scripts.
      However, unless you have a deductible or coinsurance, the price you recently paid with
      insurance will probably be the price you pay next time.
      <br />
      <br />
      <Title>How are you able to retrieve accurate prices with insurance?</Title>
      We use your insurance info to calculate pharmacy benefits on your behalf. To provide pricing
      for your drug searches, you may receive correspondence from your insurance carrier that is
      related to our coordination of your benefits. While technical details of our systems are
      proprietary, we assure you that your information is kept private and safe.
      <br />
      <br />
    </Content>
  );
};

export default Insurance;

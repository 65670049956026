import React, {FC, useState} from 'react';
import styled from 'styled-components/macro';

import Select from '../../../../components/Select';
import {ThemeProps} from '../../../../theme/theme';
import {componentsMap} from '../pages';
import {COMPONENT_NAME, FAQComponentList} from '../../types';
import {ContentWrapper} from '../../../Privacy/styles';

const MobileFAQContentWrapper = styled(ContentWrapper)<ThemeProps>`
  ${({theme}) => theme.forWidth('max', 'lg', 'height: 80%; margin-top: 10px;')}
  ${({theme}) => theme.forWidth('max', 'sm', 'height: calc(100% - 169px)')}
`;

const MobileFAQNavigation: FC<FAQComponentList> = ({components}) => {
  const [displayedContent, setDisplayedContent] = useState<COMPONENT_NAME>(
    COMPONENT_NAME.ABOUT_ONE_RX,
  );
  const FAQSection = componentsMap[displayedContent];
  const FAQOptions = components.map((component) => {
    return {value: component.componentName, label: component.displayValue};
  });

  return (
    <>
      <Select
        label='Category'
        placeholder='About oneRx'
        options={FAQOptions}
        onChange={setDisplayedContent}
      />
      <MobileFAQContentWrapper>
        <FAQSection />
      </MobileFAQContentWrapper>
    </>
  );
};

export default MobileFAQNavigation;

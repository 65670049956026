import {InfoCircleFilled} from '@ant-design/icons';
import styled, {ThemeProps} from 'styled-components/macro';

import {Theme} from '../../../../../theme/theme';
import {Optional} from '../../../../../types';

interface CircleProps {
  color?: Optional<string>;
  align?: Optional<string>;
}

type Props = ThemeProps<Theme> & CircleProps;

const InfoCircle = styled(InfoCircleFilled)`
   {
    color: ${(props: Props) => props.color ?? props.theme.colors.GREY1};
    svg {
      width: 14px;
    }
  }
`;

export default InfoCircle;

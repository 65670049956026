import {createGlobalStyle} from 'styled-components/macro';

import {ThemeProps} from './theme';
import {notificationStyles} from '../components/Notification/styles';

const GlobalStyles = createGlobalStyle<ThemeProps>`
  body {
    background: ${(props) => props.theme.colors.BODYBG};
    font-family: ${(props) => props.theme.fontFamily};
    min-width: ${({theme}) => theme.grid.breakpoints.xs}px;
  }

  .ant-modal-body > *::-webkit-scrollbar-thumb, .ant-modal-body::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.GREY};
    border-left: 2px solid ${(props) => props.theme.colors.GREY5};
    border-right: 2px solid ${(props) => props.theme.colors.GREY5};
    border-radius: 4px;
  }

  .ant-modal-body, .ant-modal-body > * {
    scrollbar-color: ${(props) => `${props.theme.colors.GREY} ${props.theme.colors.GREY5}`};
    scrollbar-width: thin;
  }

  .ant-modal-body::-webkit-scrollbar, .ant-modal-body > *::-webkit-scrollbar {
    width: 8px;
    opacity: 0;
  }

  .ant-modal-body:hover::-webkit-scrollbar, .ant-modal-body > *:hover::-webkit-scrollbar {
    width: 8px;
    opacity: 1;
  }

  .ant-modal-body > *::-webkit-scrollbar-track, .ant-modal-body::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.GREY5};
    border-radius: 10px;
  }

  .ant-select-clear {
    background: transparent;
  }

  ${notificationStyles}
  #root {
    min-height: 100%;

    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  a {
    ${({theme}) => ({...theme.textStyle.link})};
  }

  h1.ant-typography {
    color: ${({theme}) => theme.colors.GREY};
    ${({theme}) => ({...theme.textStyle.H1})};
  }

  h2.ant-typography {
    ${({theme}) => ({...theme.textStyle.H2})};
  }

  h3.ant-typography {
    color: ${({theme}) => theme.colors.GREY};
    ${({theme}) => ({...theme.textStyle.H3})};
  }

  h4.ant-typography {
    ${({theme}) => ({...theme.textStyle.H4})};
    color: ${({theme}) => theme.colors.GREY};
  }

  .ant-typography strong {
    font-weight: 700;
  }

  .custom-tooltip {
    margin-top: 24px;
    left: 0;

    &.no-margin {
      margin-top: 0;
    }

    .ant-tooltip-inner {
      font-size: 12px;
      color: ${({theme}) => theme.colors.GREY4};
      min-height: auto;
      line-height: 20px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .my-insurance-form--tooltip {
    max-width: 200px !important;
  }

  @keyframes slide-from-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .drug-info--skeleton {
    .ant-skeleton-paragraph li {
      background: ${({theme}) => theme.colors.GREY2} !important;
    }
  }

  .ant-skeleton.ant-skeleton-active {
    span {
      animation: ant-skeleton-loading 1.4s ease infinite;
      background: ${({theme}) => theme.colors.GREY2} !important;
    }
  }

  .ant-skeleton-title, .ant-skeleton-paragraph > * {
    background: ${({theme}) => theme.colors.GREY2} !important;
  }

  .admin-link {
    display: none;

    @media only screen and (min-width: ${(props) => props.theme.grid.breakpoints.md}px) {
      display: block;
    }

    &.active {
      > * {
        color: ${({theme}) => theme.colors.GREY};
      }
    }

    &--overlay {
      max-width: 280px;
      width: 100%;
      background: ${({theme}) => theme.colors.WHITE};

      .ant-dropdown-menu {
        padding: ${({theme}: ThemeProps) => theme.grid.gutter.sm} 0;
        background: ${({theme}) => theme.colors.ORANGE1};
      }

      &__item {
        margin: auto;
        color: ${({theme}) => theme.colors.GREY12};
        background: transparent;
        display: block;
        padding: 16px 6px;
        transition: all, 0.5s;
        font-size: 16px;
        cursor: pointer;

        .admin-link--text {
          padding: 0 8px;
          max-height: 16px;

          &__item {
            margin-left: 20px;
          }
        }

        &:not(a) {
          line-height: 16px;
        }

        &:hover {
          background: ${({theme}) => theme.colors.YELLOW3};
          color: ${({theme}) => theme.colors.GREY12};
        }
      }
    }
  }

  .ant-upload-list {
    &-item {
      * {
        fill: #404D66;
      }

      color: #404D66;
      background: ${({theme}) => theme.colors.GREY17};
    }
  }

  .ant-drawer.mobileMenu {
    .ant-drawer-content-wrapper {
      max-width: 400px;

      .ant-drawer-content {
        background: ${({theme}) => theme.colors.GREY4};
        overflow-x: hidden;

        .ant-drawer-header {
          background: ${({theme}) => theme.colors.WHITE};
          padding: ${({
            theme: {
              grid: {
                gutter: {sm},
              },
            },
          }) => `0 ${sm} 0`};
          border-bottom: 1px solid ${({theme}) => theme.colors._SECONDARY};
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        }

        .ant-drawer-body {
          padding: ${({theme}) =>
            `${theme.grid.gutter.sm} ${theme.grid.gutter.sm} ${theme.grid.gutter.md}`};
        }

        .ant-drawer-footer {
          padding: 0;
        }
      }
    }
  }
`;

export default GlobalStyles;

import {css, ThemeProps} from 'styled-components/macro';

import {Theme} from '../../theme/theme';
import {ButtonProps} from './types';

type Props = ButtonProps & ThemeProps<Theme>;

const styles = css<Props>`
  &.ant-btn {
    max-width: 326px;
    min-width: 130px;
    height: 36px;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    border: none;
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.GREY3};
  }

  &.ant-btn-block {
    max-width: none;
  }

  .ant-click-animating-node {
    display: none;
  }

  &.ant-btn > span {
    color: ${(props) => props.theme.colors.GREY};
  }

  &.ant-btn-primary {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    background-color: ${(props) => {
      if (props.primaryGreen) {
        return props.theme.colors.AQUAMARINE1;
      }
      if (props.primaryYellow) {
        return props.theme.colors.YELLOW3;
      }

      return props.theme.colors.PURPLE;
    }};
  }
  &.ant-btn-primary:hover {
    box-shadow: 0 5px 10px ${(props) => `${props.theme.colors.BLACK}33`};
  }
  &.ant-btn-primary:hover,
  &.ant-btn-primary:active {
    background-image: none;
  }
  &.ant-btn-primary:active {
    box-shadow: none;
  }
  &.ant-btn-primary > span {
    color: ${(props) => (props.primaryYellow ? props.theme.colors.GREY : props.theme.colors.WHITE)};
    ${(props) =>
      props.primaryYellow
        ? `
          text-shadow: none;
        `
        : ''};
  }
  &.ant-btn-primary[disabled],
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary[disabled]:active,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    cursor: not-allowed;
    box-shadow: none;
    background-image: none;
    background-color: ${(props) => props.theme.colors.GREY2};
    opacity: 0.5;
  }
  &.ant-btn[disabled] > span,
  &.ant-btn-primary[disabled] > span {
    color: ${(props) => props.theme.colors.GREY1};
  }
`;

export default styles;

import styled from 'styled-components/macro';
import React, {FC} from 'react';

import {ThemeProps} from '../../../theme/theme';
import {ReactComponent as Arrow} from '../../../assets/arrow-back.svg';

export const GoBackButtonStyled = styled.button<ThemeProps>`
  font-size: 16px;
  border: unset;
  background: unset;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.BLUE3};

  > span {
    font-size: 24px;
  }

  :hover {
    cursor: pointer;
  }
`;

interface GoBackButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

// TODO: maybe it will make sense to merge later
//  with generic Button component
//  when the Button be update to the new design
const GoBackButton: FC<GoBackButtonProps> = ({onClick, style}) => (
  <GoBackButtonStyled data-e2e='support-section__back-button' onClick={onClick} style={style}>
    <Arrow /> Go back
  </GoBackButtonStyled>
);

export default GoBackButton;

import React from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {AxiosError} from 'axios';

import Input from '../../components/Input';
import Button from '../../components/Button';
import {Routes} from '../routes';
import {regexpPatterns} from '../../utils/validation';
import forgotPassword from '../../services/authorization/forgotPassword';
import {ForgotPasswordSchema} from '../../services/authorization/types';
import {useNotification} from '../../context/notification-context';
import getErrorMessage, {ServerError} from '../../services/getErrorMessage';
import {NOTIFICATION_TYPES} from '../../components/Notification/types';
import backgroundImage from '../../assets/sign-in-bg.jpg';
import SignInLayout from '../../components/SignInLayout';
import Typography from '../../components/Typography';

const ForgotPassword = () => {
  const notificationsContext = useNotification();

  const {control, handleSubmit, errors, formState, reset} = useForm<ForgotPasswordSchema>({
    defaultValues: {email: ''},
  });

  const {isSubmitting, isDirty, isValid} = formState;

  const onSubmit: SubmitHandler<ForgotPasswordSchema> = async (data) => {
    if (isValid || !errors?.email) {
      try {
        const response = await forgotPassword(data);

        if (response?.success) {
          const description = `Password was sent to ${data.email} `;
          notificationsContext?.setNotificationState?.({
            type: NOTIFICATION_TYPES.SUCCESS,
            description,
          });
          reset();
        }
      } catch (_error) {
        const error = _error as AxiosError<ServerError>;
        const errorMessage = getErrorMessage(error?.response?.data);
        notificationsContext?.setNotificationState?.({
          type: NOTIFICATION_TYPES.ERROR,
          description: errorMessage,
        });
      }
    }
  };

  return (
    <SignInLayout backgroundImage={backgroundImage}>
      <form onSubmit={handleSubmit(onSubmit)} data-e2e='reset-password-form'>
        <Typography level={1} data-e2e='reset-password__title'>
          Reset Password
        </Typography>
        <p data-e2e='reset-password__description'>
          Enter your email below and we will send instructions for resetting your password.
        </p>
        <Controller
          name='email'
          control={control}
          rules={{
            pattern: {value: regexpPatterns.EMAIL, message: 'Invalid Email Address'},
            required: true,
          }}
          render={({onChange, value, ref}) => (
            <Input
              label='Email'
              error={errors?.email?.message}
              type='text'
              placeholder='Enter your email'
              ref={ref}
              value={value}
              onChange={onChange}
              data-e2e='reset-password__email-input'
            />
          )}
        />

        <Button
          htmlType='submit'
          type='primary'
          size='large'
          primaryGreen
          data-e2e='reset-password__submit-button'
          disabled={!isDirty || isSubmitting}
          block
        >
          Reset
        </Button>
        <p>
          <Link to={Routes.SIGN_IN} data-e2e='reset-password__back-link'>
            Back to Sign In
          </Link>
        </p>
      </form>
    </SignInLayout>
  );
};

export default ForgotPassword;

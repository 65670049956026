import React from 'react';
import {Redirect} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

import SignInForm from './SignInForm';
import {Routes} from '../routes';
import isAdmin from '../../utils/isAdmin';

const SignIn = () => {
  const {authState} = useOktaAuth();

  if (!authState.isAuthenticated) {
    return <SignInForm />;
  }

  return <Redirect to={isAdmin() ? Routes.ADMIN_USERS_CREATE : Routes.DRUG_LOOKUP} />;
};

export default SignIn;

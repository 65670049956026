import {Optional} from '../../types';

export enum EmployerName {
  HEALTH_ADVOCATE = 'HealthAdvocate',
}

export enum InsurancePolicyHolderValues {
  NOT_SPECIFIED = 'Not Specified',
  SELF = 'Self',
  SPOUSE = 'Spouse',
  PARENT = 'Parent',
  OTHER = 'Other',
}

export enum GenderValues {
  MALE = 'm',
  FEMALE = 'f',
}

export interface InsuranceFormInfo {
  insurance: InsuranceInfoSchema;
  is_insured?: Boolean;
}

export interface InsuranceFormInfoHA {
  insurance: Optional<InsuranceInfoSchema>;
  is_insured?: Boolean;
}

export interface InsuranceInfoSchema {
  id?: string;
  account_id?: Optional<string>;
  plan_id?: Optional<string>;
  relationship_code?: Optional<string>;
  instance_id?: Optional<string>;
  carrier_id?: Optional<string>;
  policy_holder: Optional<InsurancePolicyHolderValues>;
  rxbin: string;
  rxpcn: Optional<string>;
  group_id: Optional<string>;
  member_id: string;
  person_code: Optional<string>;
  is_insured?: Boolean;
  is_esi?: boolean;
}

export interface UserSchema {
  activation_token: Optional<string>;
  created_at: string;
  email: string;
  employee: Optional<EmployeeSchema>;
  id: string;
  idp_id: string;
  is_activated: Optional<Boolean>;
  type: string;
}

export interface EmployeeSchema {
  accepted_term_of_use_at: Optional<string>;
  declined_term_of_use_at: Optional<string>;
  demographic_info: Optional<DemographicInfoSchema>;
  employer: Optional<EmployerSchema>;
  hipaa_signed_at: Optional<string>;
  id: string;
  is_insured: Optional<Boolean>;
  insurance: Optional<InsuranceInfoSchema>;
  is_demographic_changed: Optional<Boolean>;
}

export interface EmployerSchema {
  id: Optional<string>;
  name: EmployerName | string;
  supported_transactions: SupportedTransaction[];
}

export interface SupportedTransaction {
  name: SupportedTransactionName;
}

export enum SupportedTransactionName {
  NCPDP = 'NCPDP',
  OPTUM = 'OPTUM',
  ESI = 'ESI',
  MEDIMPACT = 'MEDIMPACT',
}

export interface DemographicInfoSchema {
  id: Optional<string>;
  first_name: Optional<string>;
  last_name: Optional<string>;
  dob: Optional<string>;
  gender: Optional<GenderValues>;
  ssn: Optional<string>;
  zip: Optional<string>;
}

export interface UserResponse {
  id: string;
  demographic_info: Optional<DemographicInfoSchema>;
  is_insured: Optional<Boolean>;
  employer: Optional<EmployerSchema>;
  insurance?: Optional<InsuranceInfoSchema>;
  e1_insurance?: Optional<InsuranceInfoSchema>;
  user: Optional<UserSchema>;
  hipaa_signed_at: Optional<string>;
  accepted_term_of_use_at: Optional<string>;
  declined_term_of_use_at: Optional<string>;
  role_id: string;
  user_role: UserRoleSchema;
  is_demographic_changed?: Optional<boolean>;
}

export interface UserRoleSchema {
  id: string;
  name: string;
  verbose_name: string;
}

export interface UserSchema {
  demographic_failed?: Optional<boolean>;
  insurance_failed?: Optional<boolean>;
}

export type UserExtendedSchema = Partial<UserResponse> &
  Partial<E1TransactionResponse> &
  Partial<UserSchema>;

export type UserState = Partial<UserResponse> & Partial<UserSchema>;

export enum E1ResponseStatusCode {
  OK = 'OK',
  FAILED = 'FAILED',
}

export interface E1TransactionSchema {
  insurance: Optional<InsuranceInfoSchema>;
  status: {
    code: E1ResponseStatusCode;
    message: string;
  } | null;
}

export interface E1TransactionResponse {
  demographic_info: Optional<DemographicInfoSchema>;
  e1_transaction: E1TransactionSchema;
}

export interface HipaaLinkResponse {
  url: string;
}

export enum USER_FIELDS {
  EMAIL = 'email',
  EMPLOYER = 'employer',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  ROLE = 'role',
}

import React from 'react';
import styled, {ThemeProps} from 'styled-components/macro';
import {LoadingOutlined} from '@ant-design/icons';

import {Theme} from '../../../theme/theme';
import Button from '../index';
import {ButtonProps} from '../types';

interface LoadableButtonChildProps {
  isLoading?: boolean;
  label: string;
  loadingLabel?: string;
  data_e2e?: string;
  hideBoxShadow?: boolean;
}

type StyledProps = {isLoading?: boolean; hideBoxShadow?: boolean} & ThemeProps<Theme>;

export const StyledButton = styled(Button)<StyledProps>`
  background-color: ${({theme}) => theme.colors.BLUE3};
  ${(props) => (props.hideBoxShadow ? '' : 'box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);')}
  border: none;
`;

const DefaultContainer = styled.div<StyledProps>`
  ${(props) => (props.isLoading ? 'visibility: hidden;' : '')}
`;

const LoadingContainer = styled.div<StyledProps>`
  ${(props) => (!props.isLoading ? 'visibility: hidden;' : '')}
  position: absolute;
  width: 100%;
  left: 0;
`;

const Icon = styled(LoadingOutlined)`
  margin-right: ${(props) => props.theme.grid.gutter.xs};
`;

const LoadableButtonChild = ({isLoading, label, loadingLabel}: LoadableButtonChildProps) => (
  <>
    <LoadingContainer isLoading={isLoading}>
      {/*
      // @ts-ignore */}
      <Icon />
      {loadingLabel || label}
    </LoadingContainer>
    <DefaultContainer isLoading={isLoading}>{label}</DefaultContainer>
  </>
);

const SubmitButton = ({
  isLoading,
  label,
  loadingLabel,
  disabled,
  ...props
}: Omit<ButtonProps, 'theme'> & LoadableButtonChildProps) => {
  const isPrimaryGreen = props.primaryGreen || !(props.primaryPurple || props.primaryYellow);

  return (
    <StyledButton
      type='primary'
      size='large'
      htmlType='submit'
      primaryGreen={isPrimaryGreen}
      disabled={isLoading || disabled}
      {...props}
    >
      <LoadableButtonChild isLoading={isLoading} label={label} loadingLabel={loadingLabel} />
    </StyledButton>
  );
};

export default SubmitButton;

import {RESET_PASSWORD_FORM_FIELDS, ResetPasswordFormData} from '../../pages/ResetPassword/types';
import {getQueryString} from '../../utils/urls';
import HttpClient from '../httpClient';
import {ResetPasswordRequestData, SuccessResponse, VerifyRecoveryTokenResponse} from './types';

const transformRequestData = (
  data: ResetPasswordFormData & {stateToken: string},
): ResetPasswordRequestData => ({
  new_password: data[RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD],
  recovery_answer: data[RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER],
  state_token: data.stateToken,
});

export function resetPassword(data: ResetPasswordFormData & {stateToken: string}) {
  return HttpClient.post<SuccessResponse>({
    url: '/account/reset_password',
    data: transformRequestData(data),
  });
}

export function verifyRecoveryToken() {
  return HttpClient.post<VerifyRecoveryTokenResponse>({
    url: '/account/verify_recovery_token',
    data: {
      token: getQueryString('recovery_token'),
    },
  });
}

export default resetPassword;

import styled from 'styled-components/macro';
import React, {FC} from 'react';

import {ThemeProps} from '../../theme/theme';
import {GoBackButtonStyled} from '../../components/Button/components/GoBackButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;
`;

export const ButtonFlexContainer = styled.div<ThemeProps>`
  width: 100%;
  max-width: ${(props: ThemeProps) => props.theme.grid.breakpoints.xl}px;
  margin: ${({
    theme: {
      grid: {
        gutter: {md, xlg},
      },
    },
  }) => `${xlg} 0 ${md}`};

  ${({theme}) => theme.forWidth('min', 'xlg', '')}
`;

export const ButtonFlexItem = styled.div<ThemeProps>`
  flex-grow: 1;
  box-sizing: border-box;
  height: fit-content;

  ${({theme}: ThemeProps) => {
    const {
      grid: {
        gutter: {xlg},
      },
    } = theme;
    return theme.forWidth('max', 'xl', `margin: 0 ${xlg} 0;`);
  }}

  ${({theme}: ThemeProps) => {
    const {
      grid: {
        gutter: {sm},
      },
    } = theme;
    return theme.forWidth('max', 'sm', `margin: 0 ${sm} 0;`);
  }}

  ${GoBackButtonStyled} {
    padding: 0 1px;
  }

  :empty {
    display: none;
  }
`;

export const ButtonWrapper: FC = ({children}) => (
  <ButtonFlexContainer>
    <ButtonFlexItem>{children}</ButtonFlexItem>
  </ButtonFlexContainer>
);

export const Layout = styled.div<ThemeProps>`
  max-width: ${(props: ThemeProps) => props.theme.grid.breakpoints.xl}px;
  height: calc(100vh - 330px);

  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${(props: ThemeProps) => props.theme.colors._SECONDARY};
  background-color: ${(props: ThemeProps) => props.theme.colors.WHITE};
  box-shadow: 0 20px 10px -15px rgba(0, 0, 0, 0.2);
  padding: 18px;
  margin: 16px 40px;
  position: relative;

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `flex-direction: column;
        margin: 16px;
        padding: 25px 16px;
        max-height: unset;
        height: calc(100vh - 300px);
        min-height:290px;
        `,
    )}
`;

export const Header = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.H1})};
  margin-top: 7px;
  margin-bottom: 16px;
`;

export const Logo = styled.span<ThemeProps>`
  font-weight: bold;
  color: ${(props: ThemeProps) => props.theme.colors.BLUE};
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 65px);
  overflow-y: auto;
  ${({theme}) => theme.forWidth('max', 'xs', 'height: calc(100% - 95px);')}
`;

export const Content = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.body})};
  color: ${(props: ThemeProps) => props.theme.colors.GREY1};

  ${({theme}) => theme.forWidth('max', 'lg', 'height: unset;')}

  & .contact-us {
    margin-bottom: 5px;

    > div {
      line-height: 15px;
    }
  }
`;

export const TermsStyles = styled(Content)`
  a {
    font-size: inherit;
    text-decoration: underline;
  }
`;

export const Title = styled.div<ThemeProps>`
  ${(props: ThemeProps) => ({...props.theme.textStyle.body1})};
  font-weight: bold;
  color: ${(props: ThemeProps) => props.theme.colors.GREY};
  margin: 16px 0;
`;

import {Optional} from '../types';

const validateEqualFields = (
  getValues: (name: string) => any,
  fieldName: string,
  message: string,
) => (value: Optional<string>) => {
  return value === getValues(fieldName) ? undefined : message;
};

export default validateEqualFields;

import {FormState} from 'react-hook-form/dist/types/form';

const getFieldError = (
  formState: FormState<any>,
  fieldName: string,
  onTouch: boolean = true,
): string | undefined => {
  if (onTouch) {
    return formState?.touched?.[fieldName] ? formState?.errors?.[fieldName]?.message : undefined;
  }

  return formState?.errors?.[fieldName]?.message ?? undefined;
};

export default getFieldError;

import {AlertProps as AntAlertProps} from 'antd/lib/alert';

export type AlertProps = AntAlertProps;

export enum NOTIFICATION_TYPES {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum NOTIFICATION_DESC {
  SUCCESS = 'Your changes were saved successfully.',
  ERROR = "Sorry, we couldn't save your changes.",
}

export type NotificationMessageTypes = Record<NOTIFICATION_TYPES, string | null>;
export type NotificationDurations = Record<NOTIFICATION_TYPES, number | null>;

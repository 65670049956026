import React, {FC} from 'react';

import Link from '../../../../components/Link';

const SupportEmailLink: FC<{fontSize?: string}> = ({fontSize = '14px'}) => {
  return (
    <Link
      style={{fontSize}}
      data-e2e='support-section__email-address'
      href='mailto:support@onerx.com'
    >
      support@onerx.com
    </Link>
  );
};

export default SupportEmailLink;

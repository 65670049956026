import React, {MouseEvent} from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';
import {Avatar} from 'antd';

import {ReactComponent as BlueAvatarIcon} from '../../../../../../assets/avatar-grey.svg';
import {ReactComponent as WhiteAvatarIcon} from '../../../../../../assets/avatar-white.svg';
import Dropdown from '../../../../../Dropdown';
import {Routes} from '../../../../../../pages/routes';
import {AvatarStyles} from './AvatarStyles';
import signOut from '../../../../../../utils/signOut';
import isHAEmployer from '../../../../../../utils/isHaEmployer';
import {setRedirectionPath} from '../../../../../../utils/redirectionPath';

const UserAvatar = () => {
  const {oktaAuth, authState} = useOktaAuth();

  const isMeRoute = Boolean(useRouteMatch(Routes.ME));

  const logOut = (e: MouseEvent) => {
    e.preventDefault();
    if (isHAEmployer()) {
      setRedirectionPath(Routes.HA_SIGNED_OUT);
    }
    signOut(oktaAuth);
  };

  const AvatarIcon = isMeRoute ? WhiteAvatarIcon : BlueAvatarIcon;
  const splitEmail = authState?.accessToken?.claims?.email?.split('@');

  return (
    <Dropdown
      items={[
        <NavLink to={Routes.MY_INFO} data-e2e='navigation__dropdown-rx-info'>
          My Profile
        </NavLink>,
        // <NavLink to={Routes.MY_SETTINGS} data-e2e='navigation__dropdown-settings'>
        //   Settings
        // </NavLink>,
        <a href=' ' onClick={logOut} data-e2e='navigation__dropdown-logout'>
          Log out
        </a>,
      ]}
    >
      <AvatarStyles
        selected={isMeRoute}
        onClick={(e) => e.preventDefault()}
        data-e2e='navigation__avatar'
      >
        <Avatar size={50} icon={<AvatarIcon />} />
        {splitEmail && (
          <>
            <span data-e2e='navigation__avatar-email-first-part'>{splitEmail[0]}</span>
            <span data-e2e='navigation__avatar-email-second-part'>{'@' + splitEmail[1]}</span>
          </>
        )}
      </AvatarStyles>
    </Dropdown>
  );
};

export default UserAvatar;

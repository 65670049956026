import {css, ThemeProps} from 'styled-components/macro';

import {Theme} from '../../theme/theme';
import {ModalProps} from './types';

type Props = ModalProps & ThemeProps<Theme>;

const styles = css<Props>`
  .ant-modal-body,
  .ant-modal-body > * {
    scrollbar-color: ${(props) => props.theme.colors.GREY} ${(props) => props.theme.colors.GREY2};
  }

  .ant-modal-body > *::-webkit-scrollbar-track,
  .ant-modal-body::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.GREY2};
    border-radius: 10px;
  }

  max-width: calc(100vw - 80px);
  padding-bottom: 0;
  ${({theme}: Props) =>
    theme.forWidth(
      'max',
      'sm',
      `margin-bottom: 0; max-width: calc(100vw - ${theme.grid.gutter.sm});`,
    )}

  .ant-modal-header {
    padding: 20px 40px ${(props: Props) => props.theme.grid.gutter.xs};
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    ${({theme}: Props) =>
      theme.forWidth(
        'max',
        'sm',
        `padding-left: ${theme.grid.gutter.xs}; padding-right: ${theme.grid.gutter.xs};`,
      )}
  }
  .ant-modal-title {
    text-align: ${(props: Props) => props.titleAlignment || 'left'};
    color: ${(props: Props) => props.theme.colors.GREY};
    ${(props: Props) => ({...props.theme.textStyle.H3})};
  }
  .ant-modal-content {
    border-radius: 10px;
    border: 1px solid ${(props: Props) => props.theme.colors._SECONDARY};
  }
  .ant-modal-body {
    padding: ${(props: Props) => (props.title ? '0' : '40px')} 40px 20px;
    ${({theme}: Props) =>
      theme.forWidth(
        'max',
        'sm',
        `padding-left: ${theme.grid.gutter.xs}; padding-right: ${theme.grid.gutter.xs}`,
      )};
  }
  .ant-modal-body > div {
    ${({theme}: Props) =>
      theme.forWidth(
        'max',
        'sm',
        `padding-left: 4px; padding-right: ${theme.grid.gutter.xs}; height: calc(100vh - 500px);`,
      )};
  }
  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    line-height: 40px;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: ${(props: Props) => props.footerDirection || 'row'};
    border-top: none;
    padding: 0 40px 20px;
    justify-content: ${(props: Props) => props.footerButtonAlignment || 'flex-end'};
    ${(props: Props) =>
      props.theme.forWidth(
        'max',
        'sm',
        `padding: 0 ${props.theme.grid.gutter.xs} ${props.theme.grid.gutter.xs};
      flex-direction: ${props.footerDirection === 'row-reverse' ? 'column-reverse' : 'column'};
      `,
      )}
  }
  .ant-modal-footer button + button,
  .ant-modal-footer .ant-btn + .ant-btn,
  .ant-modal-footer button {
    margin-left: 20px;
    ${({theme}: Props) =>
      theme.forWidth('max', 'sm', `margin-left: 0; margin-bottom: ${theme.grid.gutter.sm}`)}
  }
`;

export default styles;

declare global {
  interface Window {
    _env_: any;
  }
}

const oktaConfig = {
  authConfig: window?._env_?.REACT_APP_AUTH_CONFIG || {
    issuer: 'https://truverisexternal.okta.com/oauth2/aus1kd103olIEab1S0h8',
    clientId: '0oa1jnw9imj3k45qS0h8',
  },
};

export default oktaConfig;

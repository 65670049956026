import {RecoilState, selector} from 'recoil';

const generateParamsSelector = <DataType, ParamsType>(
  key: string,
  paramsAtom: RecoilState<ParamsType>,
  dataAtom: RecoilState<DataType>,
): RecoilState<ParamsType> =>
  selector({
    key,
    get: ({get}) => get(paramsAtom),
    set: ({set, reset}, newValue) => {
      set(paramsAtom, newValue);
      reset(dataAtom);
    },
  });

export default generateParamsSelector;

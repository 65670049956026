import React, {FC} from 'react';
import {Tooltip} from 'antd';

import {TooltipProps} from './types';

const StyledTooltip: FC<TooltipProps> = ({title, preview, ...props}: TooltipProps) => (
  <Tooltip {...props} title={title}>
    {preview}
  </Tooltip>
);

export default StyledTooltip;

import React, {MouseEventHandler, useState} from 'react';
import {NavLink} from 'react-router-dom';

import AdminLinks from '../Admin/NavLinks';
import {Routes} from '../../../../pages/routes';
import {DisabledLink, Tooltip} from './styles';
import userState from '../../../../recoil/userState';
import isHAEmployer from '../../../../utils/isHaEmployer';
import isAdmin from '../../../../utils/isAdmin';

interface NavLinksProps {
  onClick?: MouseEventHandler;
}

const {useValue} = userState;

const NavLinks = ({onClick}: NavLinksProps) => {
  const {data: userStateData} = useValue();
  const isInsuranceComplete = userStateData?.is_insured
    ? Boolean(userStateData?.insurance?.member_id)
    : true;
  const isProfileComplete = Boolean(
    userStateData?.demographic_info &&
      userStateData?.hipaa_signed_at &&
      (isHAEmployer() || isInsuranceComplete),
  );

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const handleTooltip = () => setShowTooltip(!showTooltip);
  const isAdminUser = isAdmin();

  return (
    <>
      {isAdminUser ? <AdminLinks /> : null}
      {!isProfileComplete ? (
        <>
          <li>
            <DisabledLink onMouseEnter={handleTooltip} onMouseLeave={handleTooltip}>
              Drug Lookup
              <Tooltip show={showTooltip}>
                <span>Set up your account first</span>
              </Tooltip>
            </DisabledLink>
          </li>
        </>
      ) : (
        <>
          <li>
            <NavLink to={Routes.DRUG_LOOKUP} onClick={onClick} data-e2e='navigation__drug-lookup'>
              Drug Lookup
            </NavLink>
          </li>
        </>
      )}
    </>
  );
};

export default NavLinks;

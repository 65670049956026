import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';

import Input from '../../components/Input';
import {Routes} from '../routes';
import resetPassword, {verifyRecoveryToken} from '../../services/authorization/resetPassword';
import {RESET_PASSWORD_FORM_FIELDS, ResetPasswordFormData} from './types';
import {ValidationMessages} from '../../utils/validation';
import validateOktaPassword, {validateOktaHintMessages} from '../../utils/validateOktaPassword';
import getFieldError from '../../utils/getFieldError';
import validateEqualFields from '../../utils/validateEqualFields';
import {useNotification} from '../../context/notification-context';
import getErrorMessage, {OKTA_ERROR_CODES, ServerError} from '../../services/getErrorMessage';
import {NOTIFICATION_TYPES} from '../../components/Notification/types';
import SubmitButton from '../../components/Button/components/SubmitButton';
import {SuccessResponse} from '../../services/authorization/types';
import SignInLayout from '../../components/SignInLayout';
import Typography from '../../components/Typography';
import backgroundImage from '../../assets/sign-in-bg.jpg';
import notificationMessages from '../MyProfile/notificationMessages';
import {getQueryString} from '../../utils/urls';

const OKTA_TYPE_SERVER_ERROR = 'oktaServer';

const defaultValues: ResetPasswordFormData = {
  [RESET_PASSWORD_FORM_FIELDS.SECURITY_QUESTION]: '',
  [RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER]: '',
  [RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD]: '',
  [RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM]: '',
};

const ResetPassword = () => {
  const {push} = useHistory();
  const [stateToken, setStateToken] = useState('');
  const {handleSubmit, formState, control, setValue, getValues, setError, errors, trigger} =
    useForm<ResetPasswordFormData>({
      defaultValues,
      mode: 'onChange',
    });
  const notificationsContext = useNotification();
  const showNotification = notificationsContext?.setNotificationState;

  const {mutateAsync, isLoading} = useMutation<SuccessResponse, {}, ResetPasswordFormData>(
    (data: ResetPasswordFormData) => resetPassword({...data, stateToken}),
    {
      onSuccess: () => {
        showNotification?.({
          type: NOTIFICATION_TYPES.SUCCESS,
          description: notificationMessages.PASSWORD_SAVED_SUCCESSFULLY,
        });
        push(Routes.SIGN_IN);
      },
      onError: (_error: any) => {
        const error = _error as AxiosError<ServerError>;
        const errorCode = error?.response?.data?.error_code;
        const errorMessage = getErrorMessage(error?.response?.data);

        if (errorCode === OKTA_ERROR_CODES.RECOVERY_ANSWER_OKTA_CODE) {
          setError(RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER, {
            type: OKTA_TYPE_SERVER_ERROR,
          });
        }

        showNotification?.({
          type: NOTIFICATION_TYPES.ERROR,
          description: errorMessage,
        });
      },
    },
  );
  const email = getQueryString('email');
  const newPasswordHintItems = useCallback(
    (name: string, value: string, email = null) => {
      const error = getFieldError(formState, name);

      return validateOktaHintMessages.map(({item, validator}) => ({
        item,
        id: item,
        valid: value && validator(value, email),
        error: error && !validator(value, email),
      }));
    },
    [formState],
  );

  const saveBtnDisabled = !formState.isValid || !formState.isDirty;
  const submitForm = handleSubmit((data: ResetPasswordFormData) => mutateAsync(data));

  const onSubmit = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    if (saveBtnDisabled) {
      return;
    }
    return submitForm();
  };

  useEffect(() => {
    const getRecoveryQuestions = async () => {
      try {
        const response = await verifyRecoveryToken();
        if (response?.state_token && response?.recovery_question) {
          setStateToken(response.state_token);

          setValue(RESET_PASSWORD_FORM_FIELDS.SECURITY_QUESTION, response.recovery_question);
        }
      } catch (_error) {
        const error = _error as AxiosError<ServerError>;
        const errorMessage = getErrorMessage(error?.response?.data);

        showNotification?.({
          type: NOTIFICATION_TYPES.ERROR,
          description: errorMessage,
        });
      }
    };
    getRecoveryQuestions();
  }, [setValue, showNotification]);

  const validateEqual = useCallback(
    (value) => {
      const field = formState.touched[RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM];
      if (field) {
        const message = validateEqualFields(
          getValues,
          RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM,
          ValidationMessages.PASSWORD_DIFFERS,
        )(value);

        if (message) {
          setError(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM, {
            type: 'validate',
            message,
          });
        }
      }
    },
    [getValues, setError, formState.touched],
  );

  return (
    <SignInLayout backgroundImage={backgroundImage}>
      <form onSubmit={onSubmit} data-e2e='recovery-form'>
        <Typography level={1} data-e2e='recovery-form__title'>
          New password
        </Typography>
        <p data-e2e='recovery-form__description'>
          Enter your security answer and then enter and confirm your new password.
        </p>
        <input type='submit' style={{display: 'none'}} />
        <Controller
          name={RESET_PASSWORD_FORM_FIELDS.SECURITY_QUESTION}
          control={control}
          render={({value, onChange, ref}) => (
            <Input
              label='Account security question'
              type='text'
              placeholder='Where is the trigger?'
              data_e2e='recovery-form__security-question-input'
              ref={ref}
              onChange={onChange}
              value={value}
              disabled={true}
            />
          )}
        />

        <Controller
          name={RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER}
          control={control}
          rules={{required: ValidationMessages.REQUIRED}}
          render={(props) => {
            const errorMessage = errors[RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER]?.message;

            const serverOktaError =
              errors[RESET_PASSWORD_FORM_FIELDS.RECOVERY_ANSWER]?.type === OKTA_TYPE_SERVER_ERROR;

            return (
              <Input
                label='Answer'
                placeholder='Enter your answer'
                data_e2e='recovery-form__answer-input'
                error={errorMessage || serverOktaError}
                {...props}
              />
            );
          }}
        />

        <Controller
          name={RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            validate: {
              okta: (value) => {
                return validateOktaPassword(value, email);
              },
              equalValidates: (value) => {
                const newPasswordField = getValues(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM);
                const isEqual = newPasswordField === value;

                if (isEqual) {
                  trigger(RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM);
                  return undefined;
                }
              },
            },
            minLength: {
              value: 8,
              message: ValidationMessages.MIN_8,
            },
          }}
          render={({onChange, ...props}, {invalid}) => {
            const equalFieldsError =
              errors[RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM]?.message ===
              ValidationMessages.PASSWORD_DIFFERS;

            return (
              <Input
                label='New password'
                type='password'
                placeholder='Enter new password'
                activeTooltipHint={props.value && invalid}
                tooltipHintItems={newPasswordHintItems(props.name, props.value, email)}
                error={equalFieldsError}
                data_e2e='recovery-form__new-password-input'
                onChange={(e) => {
                  validateEqual(e.target.value);

                  onChange(e);
                }}
                {...props}
              />
            );
          }}
        />

        <Controller
          name={RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM}
          control={control}
          rules={{
            required: ValidationMessages.REQUIRED,
            validate: {
              equalField: validateEqualFields(
                getValues,
                RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD,
                ValidationMessages.PASSWORD_DIFFERS,
              ),
            },
          }}
          render={(props) => {
            const errorMessage = errors[RESET_PASSWORD_FORM_FIELDS.NEW_PASSWORD_CONFIRM]?.message;

            return (
              <Input
                label='Confirm new password'
                type='password'
                placeholder='Retype your password'
                error={errorMessage}
                data_e2e='recovery-form__confirm-new-password-input'
                {...props}
              />
            );
          }}
        />
        <SubmitButton
          label='Done'
          block
          isLoading={isLoading}
          disabled={saveBtnDisabled}
          onClick={onSubmit}
          data_e2e='recovery-form__confirm-button'
        />
      </form>
    </SignInLayout>
  );
};
export default ResetPassword;

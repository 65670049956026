import React, {FC} from 'react';
import AntButton from 'antd/lib/button';
import styled from 'styled-components/macro';

import styles from './styles';
import {ButtonProps} from './types';

const ButtonUnStyled = (props: ButtonProps) => (
  <AntButton type='primary' {...props}>
    {props.children}
  </AntButton>
);

const Button: FC<ButtonProps> = styled(ButtonUnStyled)`
  ${styles}
`;

export default Button;

export {default as GoBackButton} from './components/GoBackButton';

export enum SIGN_IN_FIELDS {
  USER_NAME = 'username',
  PASSWORD = 'password',
}

export type SignInFormData = Record<SIGN_IN_FIELDS, string>;

export type UserStatusParams = {
  error: string;
  userData: SignInFormData;
};

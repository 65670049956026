import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import {LogoLink, NavigationStyles} from '../../styles';
import {Routes} from '../../../../pages/routes';
import OneRxLogo from '../../../../assets/onerx-logo.png';
import {HeaderProps} from './type';

const Header: FC<HeaderProps> = ({children, isClickableLogo = true}) => {
  return (
    <NavigationStyles data-e2e='navigation'>
      <div>
        {isClickableLogo ? (
          <Link to={Routes.HOME} component={LogoLink}>
            <img src={OneRxLogo} alt='oneRx' data-e2e='navigation__logo' width={164} />
          </Link>
        ) : (
          <img src={OneRxLogo} alt='oneRx' data-e2e='navigation__logo' width={164} />
        )}

        {children}
      </div>
    </NavigationStyles>
  );
};

export default Header;

enum StorageKeys {
  REDIRECT_TO = 'redirectTo',
  LS_SESSION_ID = 'X-LS-Session-Id',
  SHOULD_HIDE_SIGN_IN = 'shouldHideSignIn',
  SIGN_IN_FAILED = 'signInFailed',
  SHOULD_UPDATE_LOGIN_TIMESTAMP = 'shouldUpdateLoginTimestamp',
  IS_STATELESS_ROUTE = 'isStatelessRoute',
  TOTAL_APP_INSTANCES = 'onerx-active-tabs-count',
  OKTA_TOKEN_STORAGE = 'okta-token-storage',
  OKTA_CACHE_STORAGE = 'okta-cache-storage',
}

export default StorageKeys;

import React from 'react';
import {Col, Row} from 'antd';

// Use styles from Privacy Policy
import {
  ButtonWrapper,
  Content,
  ContentWrapper,
  Header,
  Layout,
  Logo,
  Wrapper,
} from '../Privacy/styles';
import HeaderNavigation from '../../components/Navigation/components/Header';
import Footer from '../../components/Footer';
import OptionalWrapper from '../../hocs/OptionalWrapper';
import example1 from './assets/example-1.jpg';
import example2 from './assets/example-2.jpg';

const HelpInsurance = () => {
  return (
    <>
      <HeaderNavigation isClickableLogo={false} />
      <Wrapper>
        <OptionalWrapper Wrapper={ButtonWrapper}>
          {/* PLACEHOLDER to keep consistency between similar pages */}
        </OptionalWrapper>

        <Layout style={{height: 'auto', marginBottom: '40px'}}>
          <Header>
            <Logo>How to Locate Your Rx Benefit Information</Logo>
          </Header>
          <ContentWrapper style={{overflow: 'visible'}}>
            <Row gutter={[32, 16]}>
              <Col xs={24}>
                <Content>
                  Your pharmacy benefits information can be found on your prescription benefits card
                  or a combination medical and pharmacy card. In these example benefits cards, the
                  information that oneRx needs is highlighted in yellow. The actual placement of the
                  benefits information will vary from one provider to another.
                </Content>
              </Col>
              <Col>
                <img src={example1} alt='Benefits card example 1' width='100%' />
              </Col>
              <Col>
                <img src={example2} alt='Benefits card example 2' width='100%' />
              </Col>
            </Row>
          </ContentWrapper>
        </Layout>
      </Wrapper>
      <Footer disableNavigation={true} />
    </>
  );
};

export default HelpInsurance;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../../../../theme/theme';

const DrawerHeader = styled.div<ThemeProps>`
  > div:first-of-type {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button,
    .ant-avatar {
      flex-shrink: 0;
    }
  }
`;

export default DrawerHeader;

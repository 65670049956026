enum notificationMessages {
  SAVED_SUCCESSFULLY = `Your changes were successfully saved`,
  PASSWORD_SAVED_SUCCESSFULLY = `Your password has been successfully changed. Please log in using your new password.`,
  SAVING_FAILURE = `Sorry, we couldn't save your changes`,
  INSURANCE_FOUND = `We found this prescription insurance for you. Can you make sure it is correct before we continue?

TIP: If you don’t want to use insurance to calculate the cost of your medications, click the “Don’t use my insurance” checkbox`,
  INSURANCE_NOT_FOUND = 'We couldn’t find your insurance info. Please provide the missing info or select the “Do not use my insurance” option to continue.',
  INSURANCE_NOT_FOUND_SSO = 'Please provide the missing info or select the “Don’t use my insurance” checkbox.',
  INSURANCE_A = '',
}

export default notificationMessages;

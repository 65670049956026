import {ReactNode} from 'react';

import {DrugCostRequest, InsuranceType, ResponseStatusMessage} from '../../services/drugs/types';

export enum ERROR_COMPONENTS {
  PRIOR_AUTHORIZATION = 'PriorAuthorization',
  NOT_COVERED = 'NotCovered',
  GENERIC_ALTERNATIVE = 'GenericAlternative',
  GENERIC_DEFAULT = 'GenericDefault',
  PLAN_LIMITATION_EXCEED = 'PlanLimitationExceed',
  PLAN_REVIEW = 'PlanReview',
  STEP_THERAPY = 'StepTherapy',
  REFILL_TOO_SOON = 'RefillTooSoon',
  DOB = 'Dob',
  PATIENT_NOT_COVERED = 'PatientNotCovered',
  GENERIC_ERROR = 'GenericError',
  INSURANCE_ERROR = 'InsuranceError',
}

export enum CORE_MESSAGES_PLACEHOLDERS {
  DRUG_NAME = 'drug_name',
}

export interface CoreMessages {
  [key: string]: string | ReactNode;
}

export interface ErrorComponentsByCode {
  [key: string]:
    | (ERROR_COMPONENTS & {[P in InsuranceType]?: undefined})
    | Record<InsuranceType, ERROR_COMPONENTS>;
}

export type ErrorProps = {
  hasInsurance: boolean;
  error: ResponseStatusMessage;
  drug?: DrugCostRequest;
};

import React, {forwardRef, useContext} from 'react';
import {Select as AntSelect} from 'antd';
import {ThemeContext} from 'styled-components';

import InputSelectStyles from './styles';
import {SelectProps} from './types';
import {InputErrorStyles} from '../Input/styles';
import InfoCircle from '../../pages/DrugLookup/components/DrugLookupForm/components/InfoCircle';
import StyledTooltip from '../Tooltip/StyledTooltip';
import {Theme} from '../../theme/theme';

const Select = forwardRef<typeof AntSelect, SelectProps>(
  (
    {
      label,
      labelTooltip,
      labelProps,
      error,
      options,
      renderOptionProp,
      labelTooltipText = null,
      labelTooltipPlacement = 'bottom',
      overlayClassName = '',
      ...props
    },
    ref,
  ) => {
    const {colors} = useContext<Theme>(ThemeContext);
    const renderOptions = renderOptionProp ? renderOptionProp(options) : options;

    return (
      <InputSelectStyles error={error} disabled={props.disabled}>
        {label && (
          <label data-e2e='form__label' {...labelProps}>
            {label}{' '}
            {labelTooltip && (
              <StyledTooltip
                overlayClassName={`custom-tooltip ${overlayClassName}`}
                color={colors.GREY}
                title={labelTooltipText}
                placement={labelTooltipPlacement}
                arrowPointAtCenter
                preview={<InfoCircle color={colors.GREY2} />}
              />
            )}
          </label>
        )}
        <AntSelect
          getPopupContainer={(trigger) => {
            return trigger;
          }}
          optionLabelProp='label'
          //@ts-ignore
          ref={ref}
          {...props}
        >
          {(renderOptions as any[])?.map(({label, value, children, ...rest}, i) => {
            return (
              <AntSelect.Option
                {...rest}
                key={`key-${value}-${i}`}
                label={label}
                value={value}
                data-e2e={`form-organization-${label}`}
              >
                {children || label}
              </AntSelect.Option>
            );
          })}
        </AntSelect>
        {error && <InputErrorStyles data-e2e='form__error-hint'>{error}</InputErrorStyles>}
      </InputSelectStyles>
    );
  },
);

export default Select;

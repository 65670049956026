import React, {useEffect} from 'react';
import {useOktaAuth} from '@okta/okta-react';

import Preloader from '../../components/Preloader';
import {hideSignIn} from '../../utils/shouldHideSignIn';

const HASignOn = () => {
  const {oktaAuth} = useOktaAuth();

  useEffect(() => {
    const idp = window?._env_?.HA_IDP;
    if (!idp) {
      console.error(new Error('HA IDP should be setup'));
    } else {
      hideSignIn();
      oktaAuth.signInWithRedirect({idp, scopes: ['openid', 'email']});
    }
  }, [oktaAuth]);

  return <Preloader />;
};

export default HASignOn;

import getErrorMessage from '../getErrorMessage';
import HttpClient from '../httpClient';
import {PostSessionErrorData, SessionHTTPMetaData, SessionResponseSchema} from './types';

export const createSessionError = ({error, headers}: PostSessionErrorData) => {
  const errorMessage = getErrorMessage(error?.response?.data);

  return HttpClient.post<SessionResponseSchema>({
    url: SessionHTTPMetaData.SESSION_ERROR,
    data: {text: errorMessage},
    headers,
  });
};

export const postSessionError = async ({error, headers}: PostSessionErrorData) => {
  try {
    await createSessionError({error, headers});
  } catch (error) {
    return await Promise.reject(error);
  }
};

import {FC} from 'react';

import AboutOneRX from './AboutOneRX';
import FindingAndPricingDrugs from './FindingAndPricingDrugs';
import HealthcareAndInsuranceTerms from './HealthcareAndInsuranceTerms';
import Insurance from './Insurance';
import Pharmacies from './Pharmacies';
import YourAccount from './YourAccount';
import {COMPONENT_NAME, DISPLAY_NAME, FAQComponent, FAQComponentList} from '../../types';

const AboutOneRXComponent: FAQComponent = {
  componentName: COMPONENT_NAME.ABOUT_ONE_RX,
  displayValue: DISPLAY_NAME.ABOUT_ONE_RX,
};

const FindingAndPricingDrugsComponent: FAQComponent = {
  componentName: COMPONENT_NAME.FINDING_AND_PRICING_DRUGS,
  displayValue: DISPLAY_NAME.FINDING_AND_PRICING_DRUGS,
};

const InsuranceComponent: FAQComponent = {
  componentName: COMPONENT_NAME.INSURANCE,
  displayValue: DISPLAY_NAME.INSURANCE,
};

const PharmaciesComponent: FAQComponent = {
  componentName: COMPONENT_NAME.PHARMACIES,
  displayValue: DISPLAY_NAME.PHARMACIES,
};

const HealthcareAndInsuranceTermsComponent: FAQComponent = {
  componentName: COMPONENT_NAME.HEALTHCARE_AND_INSURANCE_TERMS,
  displayValue: DISPLAY_NAME.HEALTHCARE_AND_INSURANCE_TERMS,
};

const YourAccountComponent: FAQComponent = {
  componentName: COMPONENT_NAME.YOUR_ACCOUNT,
  displayValue: DISPLAY_NAME.YOUR_ACCOUNT,
};

export const FAQComponents: FAQComponentList = {
  components: [
    AboutOneRXComponent,
    FindingAndPricingDrugsComponent,
    InsuranceComponent,
    PharmaciesComponent,
    HealthcareAndInsuranceTermsComponent,
    YourAccountComponent,
  ],
};

export const componentsMap: Record<COMPONENT_NAME, FC> = {
  AboutOneRX,
  FindingAndPricingDrugs,
  Insurance,
  Pharmacies,
  HealthcareAndInsuranceTerms,
  YourAccount,
};

import styled from 'styled-components/macro';
import {Link} from 'react-router-dom';

import {ThemeProps} from '../../../../../theme/theme';
import Typography from '../../../../../components/Typography';
import {CardProps, RESULT_CARD_NAME} from '../types';
import Button from '../../../../../components/Button';

const ResultCard = styled.div<ThemeProps & {variant?: boolean}>`
  ${({variant, theme}) =>
    !variant &&
    `
      height: 334px;

      @media only screen and (min-width: ${theme.grid.breakpoints.sm}px) and (max-width: ${theme.grid.breakpoints.md}px) {
        height: auto;
      }
    `} // TODO: don't set width here. Use ant Row/Cell
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.colors.GREY2};
  border-radius: 9px;
  background-color: ${({theme}) => theme.colors.WHITE};
  box-shadow: 0 20px 10px -15px rgba(0, 0, 0, 0.2);

  padding: ${({
    theme: {
      grid: {
        gutter: {sm},
      },
    },
  }) => `${sm}`};

  h2 {
    color: ${({theme}) => theme.colors.GREY};
  }

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: auto;
  `,
    )}
  ul {
    margin-block-start: 0.57em;
    padding-inline-start: 30px;
  }
`;

export const CardTitle = styled(Typography)<ThemeProps & CardProps>`
  span {
    color: ${(props) => {
      switch (props.cardName) {
        case RESULT_CARD_NAME.DISCOUNT_INSURANCE_PRICE:
          return props.theme.colors.PURPLE;
        case RESULT_CARD_NAME.DISCOUNT_PRICE:
          return props.theme.colors.AQUAMARINE1;
        default:
          return props.theme.colors.PURPLE;
      }
    }};
  }
`;

export const FlexContainer = styled.div`
  margin-top: ${({theme}) => theme.grid.gutter.sm};
  display: flex;
  align-items: center;

  > div:nth-of-type(1) {
    flex-basis: 294px;
    max-width: 294px;
    margin-right: ${({
      theme: {
        grid: {
          gutter: {xs},
        },
      },
    }) => xs};
  }

  > div:nth-of-type(2) {
  }

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
    display: flex;
    flex-direction: column;
    > div:nth-of-type(1) {
      flex-basis: 260px;
      margin-right: 0;
    }
  `,
    )}
`;

export const CircleContainer = styled.div<ThemeProps & CardProps>`
  max-height: 190px;
  overflow: visible;
  position: relative;
  text-align: center;
  margin-bottom: ${({
    theme: {
      grid: {
        gutter: {sm},
      },
    },
  }) => sm};

  > div {
    position: absolute;
    top: 67px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-right: 7px;

    > div:nth-of-type(1) {
      color: ${(props) => {
        switch (props.cardName) {
          case RESULT_CARD_NAME.DISCOUNT_INSURANCE_PRICE:
            return props.theme.colors.PURPLE;
          case RESULT_CARD_NAME.DISCOUNT_PRICE:
            return props.theme.colors.AQUAMARINE1;
          default:
            return props.theme.colors.PURPLE;
        }
      }};
      ${({price = '', theme}) =>
        price.length <= 8 ? theme.textStyle.H1 : {...theme.textStyle.H2, 'line-height': '35px'}};
    }

    > div:nth-of-type(2) {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: ${({theme}) => theme.colors.GREY};
    }

    > div:nth-of-type(3) {
      ${({theme}) => theme.textStyle.body}
      color: ${({theme}) => theme.colors.GREY1};
    }
  }

  p {
    font-size: 16px;
    color: ${({theme}) => theme.colors.GREY};
    margin: 1.5em 0;
    font-weight: 500;
  }
`;

export const ClickableCircleContainer = styled.div<ThemeProps & CardProps>`
  max-height: 190px;
  overflow: visible;
  position: relative;
  text-align: center;
  margin-bottom: ${({
    theme: {
      grid: {
        gutter: {sm},
      },
    },
  }) => sm};
  ${({theme}) =>
    theme.forWidth(
      'max',
      'md',
      `margin-left: 40px;
       margin-right: 40px;
      `,
    )}

  ${({theme}) =>
    theme.forWidth(
      'min',
      'md',
      `
         margin-left: 25px;
         margin-right: 40px;
        `,
      true,
    )}

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
    height: 205px;
    width: 205px;

    ${({theme}) =>
      theme.forWidth(
        'min',
        'md',
        `
         margin-left: 15px
        `,
      )}

    > div:nth-of-type(1) {
      color: ${(props) => {
        switch (props.cardName) {
          case RESULT_CARD_NAME.DISCOUNT_INSURANCE_PRICE:
            return props.theme.colors.PURPLE;
          case RESULT_CARD_NAME.DISCOUNT_PRICE:
            return props.theme.colors.AQUAMARINE1;
          default:
            return props.theme.colors.PURPLE;
        }
      }};
      ${({price = '', theme}) =>
        price.length <= 8 ? theme.textStyle.H1 : {...theme.textStyle.H2, 'line-height': '35px'}};
    }

    > div:nth-of-type(2) {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      color: ${({theme}) => theme.colors.GREY};
    }

    > div:nth-of-type(3) {
      ${({theme}) => theme.textStyle.body}
      color: ${({theme}) => theme.colors.GREY1};
    }

    p {
      font-size: 16px;
      color: ${({theme}) => theme.colors.GREY};
      margin: 1.5em 0;
      font-weight: 500;
    }
  }
`;

export const DescriptionContainer = styled.div<ThemeProps>`
  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
    width: 290px;
    margin-top: -48px;
    margin-bottom: 16px;
    text-align: center;

    > div:last-child {
      text-align: left;
    }
  `,
    )}
`;

export const ImgContainer = styled.div<ThemeProps & CardProps>`
  max-height: 244px;
  overflow: visible;
  position: relative;
`;

interface PriceDescriptionProps extends ThemeProps {
  bold?: boolean;
}

export const PriceDescription = styled.div<PriceDescriptionProps>`
  color: ${({theme}) => theme.colors.GREY};
  font-size: ${({theme}) => theme.textStyle.body.fontSize};
  line-height: 16px;

  margin-bottom: ${({theme}) => theme.grid.gutter.sm};

  > span {
    font-weight: ${({bold}) => (bold ? '500' : 'normal')};
    font-size: 14px;
    margin-left: 2px;
  }
`;

export const Description = styled.div<ThemeProps & CardProps>`
  ${({theme}) => theme.textStyle.body}
  color: ${({theme}) => theme.colors.GREY1};
`;

export const DrugResultErrorDescription = styled.div<ThemeProps & CardProps>`
  ${({theme}) => theme.textStyle.body}
  color: ${({theme}) => theme.colors.GREY};
  margin-bottom: ${({theme}) => theme.grid.gutter.xs};
  font-weight: 700;
  ${({cardName, error}) =>
    cardName === RESULT_CARD_NAME.DISCOUNT_PRICE
      ? `
      align-self: flex-start;
      ${!error && 'margin-top: 48px'};
    `
      : ''};
`;

export const ErrorBoldText = styled.div<ThemeProps>`
  color: ${({theme}) => theme.colors.GREY};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-top: ${({theme}) => theme.grid.gutter.xs};
  margin-bottom: ${({theme}) => theme.grid.gutter.xs};
`;

export const ErrorLink = styled(Link)<ThemeProps>`
  color: ${({theme}) => theme.colors.BLUE3};
  text-decoration: underline;
  font-size: 16px;
`;

export const StyledButton = styled(Button)<ThemeProps & {nonTablet?: boolean}>`
  ${({nonTablet, theme}) =>
    nonTablet
      ? `
    @media only screen and (min-width: ${theme.grid.breakpoints.sm}px) and (max-width: 1260px) {
      display: none;
    }
  `
      : `
    display: none;
    @media only screen and (min-width: ${theme.grid.breakpoints.sm}px) and (max-width: 1260px) {
      display: unset;
    }
  `}

  width: 290px;
`;

export default ResultCard;

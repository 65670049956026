import {CostSchema, DrugCostRequest} from '../../../../services/drugs/types';

export interface DetailsProps {
  drug?: DrugCostRequest;
  drugPrice?: CostSchema;
}

export enum RESULT_CARD_NAME {
  DISCOUNT_PRICE = 'discountPrice',
  DISCOUNT_INSURANCE_PRICE = 'discountInsurancePrice',
}

export interface CardProps {
  cardName?: RESULT_CARD_NAME;
  error?: boolean;
  price?: string;
  isClickable?: boolean;
}

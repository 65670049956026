import {useEffect} from 'react';

import {Optional} from '../types';

export const useScrollListener = ({
  element,
  callBack,
}: {
  element: Optional<HTMLElement>;
  callBack: EventListenerOrEventListenerObject;
}) => {
  useEffect(() => {
    if (element) {
      element.addEventListener('scroll', callBack);
      return () => {
        element.removeEventListener('scroll', callBack);
      };
    }
  }, [element, callBack]);
};

import {ModalProps} from 'antd';
import React, {FC} from 'react';
import styled from 'styled-components/macro';

import Signature from './Signature';
import {ThemeProps} from '../../../theme/theme';
import Button from '../../../components/Button';

const FooterText = styled.p<ThemeProps>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props: ThemeProps) => props.theme.colors.GREY};
  font-weight: 500;
  margin-bottom: ${(props: ThemeProps) => props.theme.grid.gutter.sm};
  text-align: right;
  ${({theme}: ThemeProps) =>
    theme.forWidth('max', 'sm', `margin-bottom: ${theme.grid.gutter.sm}; text-align:center;`)};
`;

const FooterWrapper = styled.div<ThemeProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 120px;
  ${({theme}) => theme.forWidth('max', 'sm', `height: auto; flex-wrap: wrap;`)};
`;

const FooterItem = styled.div<ThemeProps>`
  flex: 0 0 305px;
  ${({theme}) => theme.forWidth('max', 'sm', `flex: 1 1 100%;`)};
`;

const Actions = styled.div<ThemeProps>`
  display: flex;
  margin-right: 4px;
  ${({theme}) => theme.forWidth('max', 'sm', `flex-wrap: wrap; width: 100%;`)};
  .ant-btn {
    width: 140px;
    ${({theme}) => theme.forWidth('max', 'sm', `flex: 1 1 100%; max-width: 100%;`)};
  }
`;

const ModalHipaaFooter: FC<ModalProps> = ({okButtonProps, onOk, cancelButtonProps, onCancel}) => {
  return (
    <FooterWrapper>
      <FooterItem>
        <Signature />
      </FooterItem>
      <FooterItem>
        <FooterText>Scroll to the bottom of the text to enable buttons</FooterText>
        <Actions>
          <Button type='default' key='cancel' onClick={onCancel} {...cancelButtonProps}>
            Decline
          </Button>
          <Button key='ok' type='primary' primaryGreen onClick={onOk} {...okButtonProps}>
            Accept
          </Button>
        </Actions>
      </FooterItem>
    </FooterWrapper>
  );
};
export default ModalHipaaFooter;

import {ERROR_COMPONENTS, ErrorComponentsByCode} from '../types';

export const ERRORS_COMPONENTS_BY_CODE: ErrorComponentsByCode = {
  insuranceError: ERROR_COMPONENTS.INSURANCE_ERROR,
};

export enum ERROR_CODES {
  noInsurance = 'insurance_missing',
  invalidDemographicInfo = 'invalid_demographic_info',
  demographicInfoMissing = 'demographic_info_missing',
  notCreated = 'not_created',
  duplicatedDemographicData = 'duplicated_demographic_data',
  alreadyAcceptedTermsOfUse = 'already_accepted_terms_of_use',
  userAlreadyActivated = 'user_already_activated',
}

import styled from 'styled-components/macro';

import {ThemeProps} from '../../../theme/theme';

const Title = styled.div<ThemeProps>`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: ${({theme}) => theme.grid.gutter.xlg};

  > div {
    max-width: 550px;
  }

  h1.ant-typography {
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.WHITE};
    margin-bottom: ${({theme}) => theme.grid.gutter.sm};

    strong {
      color: ${({theme}) => theme.colors.AQUAMARINE2};
    }
  }

  h2.ant-typography {
    font-weight: 400;
    color: ${({theme}) => theme.colors.WHITE};
    margin-bottom: 0;
  }

  ${({theme}) =>
    theme.forWidth(
      'max',
      'md',
      `
        margin-left: 0;
        flex-grow: 0;
        margin-top: ${theme.grid.gutter.xlg};
        margin-bottom: ${theme.grid.gutter.md};
      `,
    )}

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
        margin-top: 80px;

        h1.ant-typography {
          font-size: 30px;
          line-height: 35px;
        }

        h2.ant-typography {
          font-size: 16px;
          line-height: 18px;
        }
      `,
    )}
`;

export default Title;

import React, {useContext} from 'react';
import {Input as AntInput} from 'antd';
import {ThemeContext} from 'styled-components';

import {InputProps} from './types';
import Popover from '../Popover';
import InputStyles, {InputErrorStyles, InputHint} from './styles';
import PopoverContent from './components/PopoverContent';
import StyledTooltip from '../Tooltip/StyledTooltip';
import InfoCircle from '../../pages/DrugLookup/components/DrugLookupForm/components/InfoCircle';
import {Theme} from '../../theme/theme';

const Input = React.forwardRef<AntInput, InputProps>(
  (
    {
      label,
      labelTooltip,
      labelProps,
      error,
      activeTooltipHint = false,
      tooltipHintItems,
      labelTooltipText = null,
      labelTooltipPlacement = 'bottom',
      overlayClassName = '',
      wrapperClassName = '',
      hint,
      ...props
    },
    ref,
  ) => {
    const {colors} = useContext<Theme>(ThemeContext);

    return (
      <Popover
        innerWidth='280px'
        visible={activeTooltipHint}
        placement='bottomLeft'
        content={<PopoverContent tooltipHintItems={tooltipHintItems} />}
      >
        <InputStyles className={wrapperClassName} error={error} disabled={props.disabled}>
          {label && (
            <label data-e2e='form__label' {...labelProps}>
              {label}{' '}
              {labelTooltip && (
                <StyledTooltip
                  overlayClassName={`custom-tooltip ${overlayClassName}`}
                  color={colors.GREY}
                  title={labelTooltipText}
                  arrowPointAtCenter
                  placement={labelTooltipPlacement}
                  preview={<InfoCircle color={colors.GREY2} />}
                />
              )}
            </label>
          )}
          <AntInput ref={ref} {...props} />
          {hint && !error && (
            <InputHint className='input-hint' data-e2e='form__general-hint'>
              {hint}
            </InputHint>
          )}
          {error && !activeTooltipHint && (
            <InputErrorStyles data-e2e='form__error-hint'>{error}</InputErrorStyles>
          )}
        </InputStyles>
      </Popover>
    );
  },
);

export default Input;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../../theme/theme';
import FormPaper from './FormPaper';

interface ContainerProps {
  backgroundImage?: string;
  hasTitle?: boolean;
}

const Container = styled.div<ThemeProps & ContainerProps>`
  display: flex;
  align-items: center;
  ${({backgroundImage}) =>
    backgroundImage ? `background: url(${backgroundImage}) no-repeat left 60% top 0% fixed;` : ''}
  padding: ${({theme}) => theme.grid.gutter.xlg};
  margin-top: -25px; // to cover border radius of header
  padding-top: 65px; // 40px + 25px

  ${({theme, hasTitle}) =>
    hasTitle
      ? theme.forWidth(
          'max',
          'md',
          `
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-end;
            background-position: left 38% top 0%;

            ${FormPaper} {
              flex: 0 0 auto;
              width: 100%;
            }
          `,
        )
      : ''}

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
        padding-left: ${theme.grid.gutter.sm};
        padding-right: ${theme.grid.gutter.sm};
        min-height: calc(100vh - 128px);
        background-position: left 54% top 0%;
      `,
    )}

  background-size: cover;
`;

export default Container;

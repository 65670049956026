import React from 'react';

import MobileNavigation from './components/MobileNavigation';
import ShowResponsive from '../ShowResponsive';
import DesktopNavigation from './components/DesktopNavigation';
import Header from './components/Header';

const Navigation = ({onlyMobile}: {onlyMobile?: boolean}) => {
  return (
    <Header>
      <ShowResponsive max='md' excludeMax>
        <MobileNavigation />
      </ShowResponsive>
      {!onlyMobile && (
        <ShowResponsive min='md'>
          <DesktopNavigation />
        </ShowResponsive>
      )}
    </Header>
  );
};

export default Navigation;

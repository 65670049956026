import React from 'react';
import {useOktaAuth} from '@okta/okta-react';

import Navigation from '../../components/Navigation';
import TermsContent from './TermsContent';
// Use styles from Privacy Policy
import {ButtonWrapper, ContentWrapper, Header, Layout, Logo, Wrapper} from '../Privacy/styles';
import GoBack, {State} from '../../components/GoBack';
import OptionalWrapper from '../../hocs/OptionalWrapper';
import Footer from '../../components/Footer';
import {EmployerName} from '../../services/user/types';
import TermsContentHA from './TermsContentHA';

export interface StateWithEmployer extends State {
  employer?: string;
}

const Terms = () => {
  const {authState} = useOktaAuth();
  const employer = authState.accessToken?.claims?.employer;
  const isHAUser = employer === EmployerName.HEALTH_ADVOCATE;

  return (
    <>
      <Navigation />
      <Wrapper>
        <OptionalWrapper noEmpty Wrapper={ButtonWrapper}>
          <GoBack />
        </OptionalWrapper>
        <Layout>
          <Header>
            <Logo>oneRX </Logo>
            <span>Terms of Use</span>
          </Header>
          <ContentWrapper>{isHAUser ? <TermsContentHA /> : <TermsContent />}</ContentWrapper>
        </Layout>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Terms;

import {useEffect, useState} from 'react';

/**
 * Returns screen width.
 * Also subscribes to resizing event.
 */
const useWidth = () => {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return width;
};

export default useWidth;

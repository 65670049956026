import React, {FC} from 'react';
import {Col, Row} from 'antd';
import {MailOutlined} from '@ant-design/icons';

import Footer from '../../components/Footer';
import FormPaper from '../../components/SignInLayout/components/FormPaper';
import GoBack from '../../components/GoBack';
import Navigation from '../../components/Navigation';
import OptionalWrapper from '../../hocs/OptionalWrapper';
import signInBg from '../../assets/create-user-bg.jpg';
import {
  ButtonWrapper,
  ContentWrapper,
  HeaderWrapper,
  IconStyle,
  InstructionWrapper,
  SupportContainer,
  SupportNodeWrapper,
  TitleWrapper,
} from './styles';
import {SupportNodeProps} from './types';
import SupportEmailLink from '../FAQ/components/SupportEmailLink';

const Support = () => {
  const SupportNode: FC<SupportNodeProps> = ({icon, header, children}) => {
    return (
      <SupportNodeWrapper>
        <Row>
          <Col>
            <IconStyle>{icon}</IconStyle>
          </Col>
          <Col offset={1}>
            <HeaderWrapper>{header}</HeaderWrapper>
          </Col>
        </Row>
        <Row>
          <Col offset={3}>
            <ContentWrapper>{children}</ContentWrapper>
          </Col>
        </Row>
      </SupportNodeWrapper>
    );
  };

  return (
    <>
      <Navigation />
      <SupportContainer data-e2e='support-section' backgroundImage={signInBg}>
        <FormPaper>
          <OptionalWrapper noEmpty Wrapper={ButtonWrapper}>
            <GoBack />
          </OptionalWrapper>

          <TitleWrapper data-e2e='support-section__title'>oneRx Support</TitleWrapper>
          <InstructionWrapper data-e2e='support-section__description'>
            You can reach the oneRx Support team in one of the following ways:
          </InstructionWrapper>

          <SupportNode
            icon={<MailOutlined />}
            header='Email'
            children={<SupportEmailLink fontSize='16px' />}
          />
        </FormPaper>
      </SupportContainer>
      <Footer />
    </>
  );
};

export default Support;

import React, {FC, ReactNode} from 'react';

import Footer from '../Footer';
import FormPaper from './components/FormPaper';
import Title from './components/Title';
import Container from './components/Container';
import Navigation from '../Navigation';

interface SignInLayoutProps {
  title?: ReactNode;
  backgroundImage?: string;
  onlyMobile?: boolean;
}

const SignInLayout: FC<SignInLayoutProps> = ({
  children,
  title,
  backgroundImage,
  onlyMobile = true,
}) => (
  <>
    <Navigation onlyMobile={onlyMobile} />
    <Container backgroundImage={backgroundImage} hasTitle={Boolean(title)}>
      <FormPaper>{children}</FormPaper>
      {title && (
        <Title>
          <div>{title}</div>
        </Title>
      )}
    </Container>
    <Footer />
  </>
);

export default SignInLayout;

import styled from 'styled-components/macro';

import Modal from '../../../components/Modal';
import {ThemeProps} from '../../../theme/theme';

export const ModalStyled = styled(Modal)`
  top: 65px;

  .ant-modal-header {
    padding: ${({
      theme: {
        grid: {
          gutter: {xs, xlg},
        },
      },
    }) => `${xlg} ${xlg} ${xs}`};

    h1.ant-typography {
      color: ${(props: ThemeProps) => props.theme.colors.BLUE};
    }

    ${({theme}) =>
      theme.forWidth(
        'max',
        'sm',
        `
          padding-left: ${theme.grid.gutter.sm};
          padding-right: ${theme.grid.gutter.sm};
        `,
      )}
  }

  .ant-modal-body,
  .ant-modal-body > * {
    scrollbar-color: ${(props) => props.theme.colors.GREY} ${(props) => props.theme.colors.GREY2};
  }

  .ant-modal-body > *::-webkit-scrollbar-track,
  .ant-modal-body::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.GREY2};
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: ${({
      theme: {
        grid: {
          gutter: {md, xlg},
        },
      },
    }) => `0 ${xlg} ${md}`};

    ${({theme}) =>
      theme.forWidth(
        'max',
        'sm',
        `
          padding-left: ${theme.grid.gutter.sm};
          padding-right: ${theme.grid.gutter.sm};
        `,
      )}
  }

  .ant-modal-footer {
    background: ${(props: ThemeProps) => props.theme.colors.GREY4};
    border-radius: 0 0 10px 10px;
    padding: ${({
      theme: {
        grid: {
          gutter: {md, xlg},
        },
      },
    }) => `${md} ${xlg}`};
    align-items: center;

    ${({theme}) =>
      theme.forWidth(
        'max',
        'sm',
        `
          align-items: stretch;
          padding-left: ${theme.grid.gutter.sm};
          padding-right: ${theme.grid.gutter.sm};

          button.ant-btn {
            margin: auto;
            width: 100%;

            &:last-of-type {
              margin: 0 auto ${theme.grid.gutter.sm};
            }
          }
        `,
      )}

    button.ant-btn:first-of-type {
      margin-bottom: 0;
    }
  }
`;

export const TermsBody = styled.div<ThemeProps>`
  padding: 16px 8px;
  overflow: auto;
  height: 477px;
  border: 1px solid ${(props: ThemeProps) => props.theme.colors.GREY1};
  border-radius: 4px;

  ${({theme}) => theme.forWidth('max', 'sm', 'height: calc(100vh - 380px) !important;')}
`;

export const FooterText = styled.p<ThemeProps>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props: ThemeProps) => props.theme.colors.GREY};
  margin-right: auto;
  font-weight: 500;
  align-self: center;
  margin-bottom: 0;
  text-align: left;

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `
        margin-bottom: ${theme.grid.gutter.sm};
        margin-right: 0;
      `,
    )}
`;

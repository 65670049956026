import {Routes} from 'pages/routes';
import useEffectOnce from 'hooks/useEffectOnce';
import {useGetLastNavigationAction} from 'hooks/useGetLastNavigationAction';

import StorageKeys from 'constants/storageKeys';

export const useObserveAppInstances = () => {
  const {type: navigationType} = useGetLastNavigationAction();

  const removeOktaValues = () => {
    localStorage.removeItem(StorageKeys.OKTA_TOKEN_STORAGE);
    localStorage.removeItem(StorageKeys.OKTA_CACHE_STORAGE);
  };

  const getCurrentCount = (): number =>
    Number(localStorage.getItem(StorageKeys.TOTAL_APP_INSTANCES) || '0');

  useEffectOnce(() => {
    window.addEventListener('load', () => {
      if (
        getCurrentCount() === 0 &&
        navigationType !== 'reload' &&
        localStorage.getItem(StorageKeys.OKTA_TOKEN_STORAGE)
      ) {
        removeOktaValues();
        window.location.href = Routes.SIGN_IN;
      }

      const newCount = getCurrentCount() + 1;
      localStorage.setItem(StorageKeys.TOTAL_APP_INSTANCES, String(newCount < 1 ? 1 : newCount));
    });

    const handleTabClose = () => {
      const currentCount = getCurrentCount();

      localStorage.setItem(StorageKeys.TOTAL_APP_INSTANCES, String(currentCount - 1));
    };

    window.addEventListener('beforeunload', () => {
      handleTabClose();
    });

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  });
};

module.exports = {
  //main colors

  _SECONDARY: '#DFECFF',
  _BLUE1: '#C7DDFF',
  _BLUE2: '#72B1FD',
  _GREY3: '#9AA2AF',
  _GREY5: '#3A3A3A',
  _GREY6: '#666666',
  _GREEN1: '#84D7AE',
  _GREEN2: '#56CB8F',
  _GREEN3: '#E0F5EB',
  _RED2: '#F5DDE0',

  //custom colors
  DARKSLATEGRAY: '#2F4F4F',
  EARLYDAWN: '#FFFBE6',

  // NEW COLORS

  //main colors
  WHITE: '#FFFFFF',
  BLACK: '#000000',

  // NEW branding color
  GREY: '#404D66',
  GREY1: '#8D9EB2',
  GREY2: '#C9D2E0',
  GREY3: '#E8ECF0',
  GREY4: '#F8F8FC',
  GREY5: '#8E9EB6',
  GREY6: '#E6E6E6',
  GREY7: '#0000003b',
  GREY8: '#0000001f',
  GREY9: '#8D9EB1',
  GREY10: '#ABABAB',
  GREY11: '#CCCCCC',
  GREY12: '#555555',
  GREY13: '#8D9EB1',
  GREY14: '#333333',
  GREY15: '#f9f9f9',
  GREY17: '#FAFAFA',
  GREY18: '#9EA0A1',
  GREY19: '#F1F3F7',
  GREY20: '#BEBEBE',

  BLUE: '#050069',
  BLUE1: '#14289B',
  BLUE3: '#1B41CA',
  BLUE4: '#ABBFFF',
  BLUE5: '#1D4AD0',

  PURPLE: '#6600CC',
  PURPLE1: '#8623F3',
  PURPLE2: '#c0c1fc',
  PURPLE3: '#E6E6FE',
  PURPLE4: '#7F3E98',
  PURPLE5: '#827FB4',

  AQUAMARINE: '#26768F',
  AQUAMARINE1: '#00A2AD',
  AQUAMARINE2: '#69FFEC',
  AQUAMARINE3: '#B7F5EC',

  YELLOW: '#FAAD14',
  YELLOW1: '#C7AB00',
  YELLOW2: '#e1c100',
  YELLOW3: '#FAD700',
  YELLOW4: '#FFE58F',

  ORANGE1: '#FFFAE2',

  LIGHT_YELLOW: '#FEFDF2',

  RED: '#FF7A7A',
  RED1: '#FF9595',
  RED2: '#FFAFAF',
  RED3: '#FFCACA',

  GREEN: '#005157',

  BODYBG: '#f8fbff',

  // Opacity (Added to end of hex)
  TEN: '1A',
  TWENTY_FIVE: '40',
  THIRTY: '4D',
  EIGHTY_SEVEN: 'de',
};

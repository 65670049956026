import React from 'react';

import {Content, Title} from '../Privacy/styles';

const TermsContent = () => {
  return (
    <Content>
      Revised: June 6, 2021.
      <Title>1. INTRODUCTION</Title>
      These Terms of Use (“Agreement”) between oneRx, LLC, a Delaware limited liability company,
      including its Affiliates ("oneRx," "we," "us," or "our") and users who visit, sign up and/or
      contribute content to the Service (“You,” or Your”). As used in this Agreement, the term
      "Affiliates" includes our owners, subsidiaries, affiliated companies, directors, officers,
      suppliers, partners, advertisers, sponsors, and other parties involved in producing, creating,
      and/or delivering the Service and/or content.
      <br />
      <br />
      This Agreement governs various oneRx services, which includes various platforms to provide
      users with transparent information related to pharmacies, drugs and pharmacy benefits,
      including without limitation, providing access to drug pricing, pharmacy locations, digital or
      physical coupons, discounts and offers, and tools and information related to health insurance,
      pharmacy benefits and drug pricing.
      <br />
      <br />
      This Agreement also governs Your use of the Service. By entering into this Agreement, The
      Services provided by oneRx are covered by HIPAA because oneRx is using and disclosing your
      Protected Health Information (PHI) on behalf of your group health plan. Pursuant to HIPAA,
      oneRx will only use your PHI as permitted by agreements with your group health plan, and will
      comply with their HIPAA Notice of Privacy Practices, and the terms of any applicable HIPAA
      Patient Authorization. Please contact Your group health plan to obtain a copy of their HIPAA
      Notice of Privacy Practices.
      <br />
      <br />
      We expressly reserve the right to alter, amend or modify this Agreement from time to time in
      our sole discretion with or without notice to You. A current version of this Agreement will be
      posted on our Website at www.onerx.com. You acknowledge and agree that You are solely
      responsible for reviewing this Agreement from time to time, and familiarizing Yourself with
      the terms of this Agreement, including any modifications. Your continued use of oneRx after
      such modifications will constitute acknowledgement of the modified Agreement and Your
      agreement to be bound by and abide to the modified Agreement.
      <Title>This Agreement covers the following topics:</Title>
      <ol>
        <li>Introduction</li>
        <li>Acknowledgment and Acceptance of Terms</li>
        <li>Your Account; Use of the Service; Prohibited Uses</li>
        <li>End User License; Intellectual Property</li>
        <li>Termination</li>
        <li>Representations and Warranties</li>
        <li>Indemnification</li>
        <li>Limitation of Liability</li>
        <li>Storage of Information</li>
        <li>Support and Feedback</li>
        <li>Promotional Offers</li>
        <li>Publicity</li>
        <li>Assignment</li>
        <li>Modifications</li>
        <li>Governing Law and Venue</li>
        <li>Third Party Beneficiaries</li>
        <li>Miscellaneous</li>
        <li>Contact Us</li>
      </ol>
      <Title>
        2. ACKNOWLEDGMENT AND ACCEPTANCE OF TERMS
        <br />
        2.1 General
      </Title>
      oneRx provides the Service to You subject to Your compliance with all the terms, conditions,
      and notices referenced or contained in this Agreement, in addition to any other written
      agreement(s) between You and us. This Agreement is established solely between You and oneRx,
      and not between You and any third-party providers of services required to use to our Service,
      including without limitation, the App provider (i.e., Apple, Google) internet browser or
      service provider. You acknowledge and agree to carefully review and be solely responsible for
      compliance with the terms of Your service agreement(s) required by such third parties.
      Furthermore, when using particular materials, functions, promotions or offerings ("Additional
      Services") on or related to the Service, You will be subject to any posted rules applicable to
      such Additional Services that may have terms and conditions ("Additional Terms") additional to
      the terms set forth in this Agreement. All such Additional Terms are hereby incorporated by
      reference into this Agreement.
      <br />
      <br />
      BY DOWNLOADING, INSTALLING OR USING THE SERVICE, YOU UNDERSTAND THAT THIS AGREEMENT BECOMES
      EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF SUCH USE AND HEREBY AGREE TO BE BOUND AND COMPLY
      WITHTHE TERMS HEREIN. YOU AFFIRM THAT YOU ARE NOT UNDER THE AGE OF 18, LOCATED IN THE UNITED
      STATES OR DISTRICT OF COLUMBIA, AND THAT YOU ARE FULLY ABLE AND COMPETENT TO ENTER INTO THIS
      AGREEMENT. IF YOU DO NOT WISH TO BE BOUND BY THIS AGREEMENT, PLEASE IMMEDIATELY EXIT AND
      UNINSTALL THE SERVICE NOW. YOUR SOLE REMEDY FOR DISCONTENT WITH OUR SERVICE, OR ANY ADDITIONAL
      SERVICE(S), PRODUCT(S), COUPON(S), DISCOUNT(S), CONTENT, OR OTHER INFORMATION AVAILABLE ON OR
      THROUGH OUR SERVICE, IS TO STOP USING THE SERVICE AND/OR THOSE OTHER SERVICES OR PRODUCTS, AS
      APPLICABLE.
      <br />
      <br />
      THE SERVICE IS NOT INTENDED TO PROVIDE HEALTH OR MEDICAL ADVICE, AND BEFORE MAKING ANY
      DECISIONS THAT COULD AFFECT YOUR HEALTH, YOU SHOULD CONSULT A HEALTHCARE PROFESSIONAL. THE
      SERVICE IS INTENDED SOLELY AS A SERVICE TO ASSIST YOU IN UNDERSTANDING, ORGANIZING AND
      MANAGING HEALTHCARE-RELATED INFORMATION AND MAY BE USED FOR INFORMATIONAL PURPOSES ONLY. THE
      SERVICE AND ANY CONTENT PROVIDED BY ONERX THROUGH THE SERVICE IS PROVIDED "AS IS" AND ON AN
      "AS AVAILABLE" BASIS BY ONERX AND IS USED SOLELY AT YOUR OWN RISK. THE SERVICE MAY NOT BE USED
      TO SELL, PURCHASE, OR TRADE COUPONS, DISCOUNTS OR OFFERS, AND THIS AGREEMENT AND APPLICABLE
      LAW STRICTLY PROHIBIT ANY SUCH USE.
      <Title>2.2. Not Medical Advice or Insurance</Title>
      THE SERVICE IS NOT INTENDED TO PROVIDE, OR TO HELP PHYSICIANS OR OTHER HEALTHCARE
      PROFESSIONALS PROVIDE INSURANCE, MEDICAL OR HEALTH ADVICE TO PATIENTS. THE SERVICE IS NOT
      INTENDED TO CREATE ANY PATIENT RELATIONSHIP, NOR SHOULD IT BE CONSIDERED A REPLACEMENT OR
      SUBSTITUTE FOR CONSULTATION, ADVICE, DIAGNOSIS OR TREATMENT BY A HEALTH CARE PROFESSIONAL. YOU
      SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY SEEKING MEDICAL ADVICE FROM YOUR HEALTH CARE
      PROVIDER BECAUSE OF THE RESULTS, CONTENT OR INFORMATION YOU READ OR RECEIVE THROUGH THE
      SERVICE. FURTHERMORE, THE SERVICE IS FOR INDIVIDUAL PERSONAL USE ONLY.
      <br />
      <br />
      RELIANCE ON ANY INFORMATION PROVIDED BY ONERX, ITS AFFILIATES, PARTNERS, AGENTS AND THEIR
      RESPECTIVE EMPLOYEES AND CONTRACTORS THROUGH OR RELATED TO THE SERVICE IS SOLELY AT YOUR OWN
      RISK. YOU SHOULD NOT RELY ON THE SERVICE FOR ANY NON-INFORMATIONAL PURPOSE, INCLUDING WITHOUT
      LIMITATION, ANY LIFE-THREATENING, EMERGENCY, CRITICAL OR OTHER SITUATION WHERE TIMELY
      ADMINISTRATION OF MEDICATIONS OR OTHER MEDICAL TREATMENT IS IMPERATIVE.
      <Title>2.3 Not Intended for Use With Federally Funded Programs</Title>
      YOU ARE NOT ELIGIBLE TO USE CERTAIN FEATURES OFFERED THROUGH THE SERVICE, INCLUDING WITHOUT
      LIMITATION, ANY OF ITS COUPONS, DISCOUNTS OR OTHER OFFERS, IF YOUR PRESCRIPTIONS ARE PAID FOR
      IN PART OR FULL BY ANY STATE OR FEDERALLY FUNDED PROGRAMS, INCLUDING, BUT NOT LIMITED TO,
      MEDICARE OR MEDICAID (INCLUDING MEDICAID MANAGED CARE), MEDIGAP (ALSO KNOWN AS MEDICARE
      SUPPLEMENTAL INSURANCE), VETERANS AFFAIR (VA), FEDERAL EMPLOYEE HEALTH BENEFIT (FEHB),
      DEPARTMENT OF DEFENSE (DOD), OR TRICARE, OR WHERE PROHIBITED BY LAW.
      <Title>3. YOUR ACCOUNT; USE OF THE SERVICE; PROHIBITED USES</Title>
      <Title>3.1 General</Title>
      oneRx allows You to access and use our Service in accordance with the terms and conditions in
      this Agreement. The Service offers a variety of features and functionality, including but not
      limited to: checking prices and/or out-of-pocket costs for prescription drugs, finding drug
      coupons and/or discount cards, locating pharmacies, learning about prescription drug offerings
      such as Patient Assistance Programs (PAPs) and receiving updates about prescription drug
      offers.
      <Title>3.2 Account Registration</Title>
      oneRx requires users to sign up and register for an Account which may be done through various
      platforms, e.g., app or website. You agree and acknowledge that we may not be able to send You
      any messages or communicate with You to provide Services if messaging services are disabled
      through the applicable oneRx platform, or via text or email.
      <br />
      <br />
      oneRx may collect and store certaininformation about Your use of the Services in order to
      provide services to your health plan.
      <br />
      <br />
      When You sign up for an Account, we may have your information pre-populated due to our
      relationship with your group health plan, who may provide us with your PHI to assist in the
      account registration process and to better facilitate Your use of Services. You may otherwise
      need to provide Your information. Except as expressly provided in this Agreement oneRx is not
      responsible for the use of any information that You may elect to provide to any third-party
      (including Healthcare Providers) in connection with the use of our Service.
      <Title>3.3. Passwords and Maintenance</Title>
      When You sign up for an Account, You will need to establish an Account password and
      potentially other security information. You are solely responsible for maintaining the
      confidentiality of all passwords and security information associated with Your Account, and at
      all times, You will be solely responsible for all activity that occurs on Your Account whether
      made by You or anyone else. You agree to promptly notify oneRx of any unauthorized use of Your
      Account or if any other breach of security has occurred. In no event shall oneRx be liable for
      any unauthorized use or activity of Your Account.
      <br />
      <br />
      You are responsible for taking all reasonable steps to ensure that no unauthorized person has
      access to Your oneRx Account or password. It is Your sole responsibility to (i) control,
      authorize, and/or monitor the dissemination, access and use of Your Account, including Your
      user name, Your email address and password; (ii) promptly contact us if You believe Your
      account or password has been compromised or if there is any other reason You need to
      deactivate Your Account; (iii) maintain the confidentiality of Your use of the Service by
      reasonably prudent measures, including without limitation, maintaining confidentiality and
      security of Your mobile or desktop device, for example by clearing data, usage and history
      related to the Service after use on public connections.
      <br />
      <br />
      When You provide any information to oneRx, You represent, warrant and agree that all such
      information is and shall remain true and accurate, including without limitation, Your name,
      address, contact phone number, email address and other data, which may be necessary to
      administer Your Account or fulfill a request submitted by You. You further understand and
      agree that, generally, You may only create an Account and submit information for You, as an
      individual. You may also submit information on behalf of Your legal dependents under the age
      of 18 years or other individuals with the prior consent or authorization from such individual.
      <br />
      <br />
      If You wish to attain additional Licenses (as defined below) to use the Service besides Your
      primary Account, You will need to register with a different email address and enter
      information that is accurate for the person whose pharmacy benefits or medications You are
      referencing and/or managing.
      <Title>3.4 Use of the Service</Title>
      You may use the Service in accordance with the limited End User License (as defined below)
      granted to You below and the terms of this Agreement.
      <br />
      <br />
      In order to use the Service, You must have properly configured and working Internet, Wi-Fi or
      data connection through an Internet or mobile device data provider. You hereby agree to
      obtain, maintain and be responsible for any and all payment or fees related to such
      connectivity requirements at Your sole expense.
      <br />
      <br />
      When using the Service, information or data may be transmitted over a connection or medium
      that may be beyond the control and jurisdiction of oneRx and its suppliers. Accordingly, oneRx
      assumes no liability for or relating to the delay, failure, interruption, transmission or
      corruption of any information or data transmitted in connection with use of the Service that
      is not attributable to the sole fault of oneRx, including without limitation, data breaches or
      use or misuse of information transmitted or received using the Services.
      <br />
      <br />
      Software, content, and all other information available on the Website are intended to
      complement that on the App. oneRx is not responsible for any discrepancies in Offer (as
      defined below) pricing, organization, appearance, information or any other differences between
      the Website and the App.
      <br />
      <br />
      When You access the Service using a desktop or mobile device, You can enter Your location or
      give us Your consent to access Your location ("Location Information"). If You use our App, we
      may, in accordance with Your consent, passively collect Location Information when our
      application is installed on Your mobile device. You can change the privacy settings of Your
      mobile device at any time to stop sharing Your Location Information with us, but certain
      features and functionality may be affected or be unavailable if You do not share Your Location
      Information with us.
      <br />
      <br />
      Your calls to and from us regarding the Service or this Agreement may be recorded for various
      purposes, including without limitation, monitoring customer service quality or compliance,
      checking accuracy of information You provide us, preventing fraud, and/or providing training
      for our staff and customer service representatives.
      <Title>3.5 Your Data</Title>
      In connection with the registration, implementation, maintenance, or servicing of the
      Services, You may be required to provide personal information, data, information, or other
      materials which is PHI (collectively "Your Data"). As mentioned above, oneRx may be providing
      a service to and acting on behalf of Your health plan, and may receive, use, and disclose your
      PHI pursuant to agreements it has in place with Your group health plan, and as otherwise
      specified in a HIPAA Patient Authorization. To the extent information provided by you to oneRx
      is not PHI or covered by HIPAA, You acknowledge by this Agreement (and through Your HIPAA
      Patient Authorization) that oneRx may de-identify and further disclose or license such
      de-identified information.
      <Title>3.6 Coupons, Discounts and Offers</Title>
      ANY COUPON, DISCOUNT,OR INFORMATION USED TO REDEEM SAVINGS OR A REDUCED PRICE THROUGH THE
      SERVICE (INDIVIDUALLY OR COLLECTIVELY, "OFFER") IS NOT HEALTH INSURANCE. A USER MAY NOT BE
      ELIGIBLE FOR AN OFFER, AND SHOULD CAREFULLY READ THE TERMS OF EACH OFFER TO DETERMINE
      ELIGIBILITY AND THE TERMS GOVERNING THE USE OF THE OFFER. BY USING THE SERVICE, YOU
      ACKNOWLEDGE AND AGREE THAT OFFERS ARE DELIVERED DIGITALLY TO USERS THROUGH THE ONERX PLATFORM
      (e.g., APP, WEBSITE OR OTHER METHODS), BUT ARE MAINTAINED, MANAGED AND OFFERED BY THIRD
      PARTIES, AND ONERX IS NOT RESPONSIBLE FOR THE EFFICACY, ACCURACY, ACCEPTANCE, TERMS OR
      PERFORMANCE OF ANY OFFER. YOU UNDERSTAND AND AGREE THAT OFFERS HAVE NO CASH VALUE AND ARE NOT
      GUARANTEED TO YIELD ANY SAVINGS OR BENEFITS TO YOU. YOU AGREE TO USE OFFERS AT YOUR SOLE RISK.
      <br />
      <br />
      You understand and agree that some Offers require a valid and current prescription from a
      licensed health professional. oneRx is not responsible for and cannot assist You in obtaining
      requisite prescriptions and/or health services required to redeem an Offer.
      <br />
      <br />
      oneRx does not guarantee that any Offer will be valid or accepted by any pharmacy. While most
      retail pharmacies, independent pharmacies, and other entities dispensing prescription drugs
      (collectively, "Pharmacies") will accept Offers for which a user is eligible, some pharmacies
      will not accept certain or any Offers. oneRx is not responsible or liable for Pharmacies that
      refuse any Offer or if You are unable to use an Offer for any reason.
      <br />
      <br />
      You acknowledge and agree that Offers are administered by third parties and that oneRx may
      need to provide such third parties relevant aspects of Your Data.
      <br />
      <br />
      Certain features of the Service may allow You to request a transfer of a prescription(s) to a
      pharmacy of Your choice. Certain laws and regulations limit the number of times prescriptions
      for certain drugs may be transferred. You understand and agree that by using transfer
      prescription features on the Service, the number of Your prescription transfers may be reduced
      or limited.
      <Title>3.7 Permitted Uses; Prohibited Uses; Use Policy</Title>
      The Service may only be accessed and used by You for lawful purposes and in accordance with
      this Agreement and other agreements referenced herein. You agree not to use the Service for
      any illegal, fraudulent, improper or abusive purpose or in any manner that may interfere,
      prevent or damage our ability to provide the Service to other users. If You use the Service
      for anything other than the permitted uses in this Agreement, oneRx may, in its sole
      discretion, immediately terminate Your Service and charge and collect any applicable fees for
      damage caused by Your improper use.
      <br />
      <br />
      You agree not to use the Service for any prohibited use, including without limitation, the
      following:
      <ul>
        <li>
          Downloading content from, using or searching the Service through the use of any type of
          software, engine, agent, tool, device or mechanism (including robots, crawlers, spiders,
          data mining tools or the like) besides the search agents and/or software provided by oneRx
          or other established and widespread third-party web browsers;
        </li>
        <li>
          Accessing, using or tampering with non-public areas of the Service, oneRx's computer
          systems, or the delivery systems of our providers;
        </li>
        <li>
          Gathering, collecting or using information for uses not explicitly permitted herein,
          including without limitation, gathering other users' names and email addresses to transmit
          any unsolicited spam, junk mail, unsolicited advertisements, messages or other form of
          solicitation;
        </li>
        <li>
          Using the Service for any commercial purpose or is any manner not adherent to these Terms;
        </li>
        <li>
          Engaging in behavior that is illegal, obscene, threatening, harassing, defamatory,
          libelous, deceptive, fraudulent, malicious, infringing, tortious, or invasive of another's
          privacy or inconsistent with the terms of this Agreement;
        </li>
        <li>
          Using the Service which is unlawful, libelous, defamatory, obscene, threatening,
          harassing, hateful, pornographic, ethnically or racially offensive; encourages conduct
          that would be considered a criminal offense; gives rise to civil liability; violates any
          applicable local, state, national and international regulation, or law; or is otherwise
          inappropriate;
        </li>
        <li>
          Impersonating another person or representing Yourself as an affiliate or agent of oneRx,
          our Affiliates or respective staff;
        </li>
        <li>
          Allowing or providing availability of the features or functions of the Service to be used
          by multiple users through any means;
        </li>
        <li>
          Using the Service in any way that interferes with other users' and third parties'
          permissible use and enjoyment of the Service or use the Services in any manner which
          disrupts, prevents or restricts any other users from using the Service;
        </li>
        <li>
          Using the Service in excess of our Use Policy set forth below as determined by and which
          may be modified from time to time by oneRx in our sole discretion;
        </li>
        <li>
          Using or employing methods and/or devices that are designed or likely to take advantage
          of, bypass, exploit, or otherwise avoid our Use Policy; and
        </li>
        <li>Enabling or encouraging any other person to do any of the aforementioned.</li>
      </ul>
      oneRx reserves the right to investigate and prosecute violations of this Agreement and/or
      cooperate with and involve law enforcement authorities in prosecuting users who violate the
      terms of this Agreement.
      <br />
      <br />
      Certain features of the Service allow You to look up Your out-of-pocket expenses (i.e., copay
      or coinsurance) for Your prescription drugs (each, a "Look Up"). You understand and agree that
      by providing Your Data, including your health insurance information, You give oneRx Your
      consent to assist, process and identify Your pharmacy related benefits, including without
      limitation to determine if Your prescription drugs are covered, Your out-of-pocket obligations
      and other benefit related restrictions and requirements.
      <br />
      <br />
      As part of a safety precaution to prevent automated or harmful attacks on our server network,
      oneRx may limit the use of our Service to a reasonable number of copay lookups per hour and/or
      day ("Use Policy").
      <Title>
        4. END USER LICENSE; INTELLECTUAL PROPERTY
        <br />
        4.1 End User License
      </Title>
      oneRx grants You a limited, personal, revocable, non-exclusive, non-sublicensable,
      non-assignable, non-transferable, non-resellable license ("License" or "End User License") and
      right to use the Service for Individual Use only in strict compliance with this Agreement.
      "Individual Use" means legitimate use by a single user only for You, Your legal dependents and
      individuals that have consented to allow You to use or share their information with us on
      their behalf which does not include any business or commercial use or purpose. The License is
      to be used on a oneRx platform, e.g., app, or website, and shall only be owned and controlled
      by You. All rights not expressly granted under this Agreement are retained by oneRx.
      <br />
      <br />
      You acknowledge and agree that any and all right, title and interest in any foreign and
      domestic patents, copyrights, trademarks, service marks, trade secrets, and all other
      intellectual property rights (collectively, "IP Rights") in the Service are and shall remain
      the sole and exclusive property of oneRx and its licensors, as applicable. Nothing in this
      Agreement intends to or shall grant, transfer, assign or vest any IP Rights to or in You, and
      You are only entitled to the limited use of the rights expressly granted to You in this
      Agreement. You will not take any action to jeopardize, limit, or interfere with oneRx's IP
      Rights. You acknowledge and agree that any unauthorized use of the IP Rights is a violation of
      this Agreement, as well as a violation of applicable intellectual property laws. You
      acknowledge and understand that all right, title and interest in and to any Third Party
      Content (as defined below) that may be accessed through the Service is the property of the
      respective content owners and may be protected by applicable copyright or other intellectual
      property laws and treaties.
      <br />
      <br />
      You agree not to sell, assign, rent, lease, distribute, export, import, act as an intermediary
      or provider, or otherwise grant rights to third parties with regard to the Service or any part
      thereof without oneRx's prior written consent.
      <br />
      <br />
      You agree not to undertake, cause, permit, or authorize the copying, modification, creation of
      derivative works, translation, reverse engineering, decompiling, disassembling, or hacking of
      the Service, or any parts thereof. You agree not to intercept, capture, emulate, decrypt, or
      redirect the communications protocols used by oneRx for any purpose or manner not expressly
      authorized by oneRx.
      <Title>4.2 Trademarks and Copyrights</Title>
      The Service, this Agreement and all content provided through the Service by oneRx are
      Copyrighted 2015 by oneRx, LLC. All rights reserved.
      <br />
      <br />
      All trademarks indicated herein are the sole and exclusive property of oneRx. Unless otherwise
      labeled, all custom graphics, logos, icons and service names used on the Service are
      trademarks of oneRx, its Affiliates or licensors. All other service marks or trademarks are
      property of their respective owners. No part of this Agreement grants You the right to use any
      logo, trademark, service mark, and/or the name of oneRx, its Affiliates or its Licensors for
      any purpose.
      <br />
      <br />
      You may not alter, remove, or obscure any service mark, copyright, trademark or other
      proprietary rights notices incorporated in or accompanying the Service, including in any
      content provided therein.
      <Title>4.3 Third Party Content and Sites</Title>
      Materials or content may be made available via the Service that are owned, provided or
      references documents, information, software, products, services, offers and/or materials
      provided by third parties not within our control, including without limitation, information
      regarding prescription drugs, coupons, discounts, patient assistance programs ("PAPs") offered
      by pharmaceutical manufacturers or other entities, links to third party sites or other
      condition management initiatives (collectively, "Third Party Content").
      <br />
      <br />
      Third Party Content is not under the control of oneRx, and oneRx is not liable or responsible
      for Third Party Content available, linked or accessible through the Service, and any right,
      title, interest and liability for any intellectual property rights in such Third Party Content
      shall remain with the respective third party owner or licensor unless otherwise stated herein
      or agreed to by oneRx and such third party. You assume all risk arising from Your use of Third
      Party Content, including without limitation, any Third Party Content You may find or view on
      sites linked from the Service. oneRx's inclusion of such a link to Third Party Content is
      provided as a convenience and is not intended to indicate association with or endorsement of
      such third party, or any warranty of any kind, either implied or express of any of the Third
      Party Content.
      <br />
      <br />
      oneRx does not and is under no obligation to review Third Party Content transmitted, sent,
      received, used, linked or offered through the Service for any purposes, including without
      limitation, for determining intellectual property infringement, copyright compliance,
      accuracy, decency, legality, omissions, errors, references or any other aspect of the Third
      Party Content. However, oneRx reserves the right to and will, in appropriate circumstances,
      terminate access to the Service if a user infringes on any intellectual property right of
      oneRx or a third party.
      <br />
      <br />
      You are only permitted to use Third Party Content as expressly authorized by oneRx or Third
      Party Content provider. Any unauthorized use of the materials appearing on the Service may
      violate trademark, copyright and other applicable laws resulting in civil or criminal
      penalties.
      <Title>5. TERMINATION</Title>
      You may stop using the Service and cancel Your Account at any time by deactivating Your
      Account online or Contacting Us. oneRx reserves the right to terminate Your use of the Service
      at any time for any reason or no reason in our sole discretion. If You breach any terms of
      this Agreement, oneRx may immediately suspend, block and/or disable Your existing Account and
      prevent any future use or access to the Service. To the extent oneRx is in possession of PHI,
      the disposition of such PHI upon termination shall be controlled by the agreement(s) between
      oneRx and Your group health plan.
      <Title>
        6. REPRESENTATIONS AND WARRANTIES
        <br />
        6.1 Your Representations and Warranties
      </Title>
      You represent and warrant that (i) You possess the legal right, capacity, and ability to enter
      into this Agreement; (ii) You will provide and maintain true and accurate information on Your
      Account at all times; (iii) You own or have a license to use any of Your Data or Your Content
      that You submit to us; (iv) Your Data and Your Content does not violate the copyrights,
      contract rights, publicity rights, privacy rights, or any other rights of any person; (iv) You
      are not located in a country that is subject to a U.S. Government embargo, or or that has been
      designated by the U.S. Government as a "terrorist supporting" country; (v) You not listed on
      any U.S. Government list of prohibited or restricted parties; and (iv) You will not use the
      Service in violation of this Agreement.
      <Title>6.2 oneRx's Warranty Disclaimer</Title>
      ONERX'S SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE," AND THE ENTIRE RISK ASSOCIATED WITH
      THE USE OF THE SERVICE SHALL BE BORNE SOLELY BY YOU. TO THE FULLEST EXTENT PERMITTED BY LAW,
      ONERX, ITS LICENSORS, AFFILIATES, SUPPLIERS AND AGENTS MAKE NO WARRANTIES, EXPRESS, STATUTORY,
      IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE OR ANY SIMILAR WARRANTY, WHETHER SAID
      WARRANTY ARISES UNDER PROVISIONS OF ANY LAW OF THE UNITED STATES OR ANY STATE OR TERRITORY
      THEREOF, OR OF ANY COUNTRY. ONERX MAKES NO REPRESENTATIONS OR WARRANTIES THAT THE SERVICES ARE
      FREE OF RIGHTFUL CLAIMS OF ANY THIRD PARTY FOR INFRINGEMENT OR MISAPPROPRIATION OF
      INTELLECTUAL PROPERTY OR OTHER PROPRIETARY RIGHTS (INCLUDING PATENT AND TRADE SECRET RIGHTS)
      OR THAT YOUR USE OF SERVICE OR ANY CONTENT OBTAINED OR DISPLAYED ON THE SERVICE WILL NOT
      INFRINGE ON THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.
      <br />
      <br />
      WITHOUT LIMITING THE FOREGOING, ONERX MAKES NO WARRANTY AS TO THE ACCURACY, AVAILABILITY,
      RELIABILITY, COMPLETENESS, CURRENTNESS, SECURITY OR TIMELINESS OF THE CONTENT, MATERIALS,
      OFFERS, SOFTWARE, TEXT, GRAPHICS, LINKS, THIRD PARTY SITES, THIRD PARTY CONTENT, THIRD PARTY
      MATERIALS, RESULTS OR COMMUNICATIONS OBTAINED THROUGH THE USE OF THE SERVICE OR ANY DATA OR
      INFORMATION DOWNLOADED OR OTHERWISE OBTAINED OR ACQUIRED THROUGH THE USE OF THE SERVICE. YOU
      ACKNOWLEDGE AND AGREE THAT ANY CONTENT, DATA, COUPONS OR INFORMATION DOWNLOADED OR OTHERWISE
      OBTAINED, ACQUIRED OR VIEWED THROUGH THE USE OF THE SERVICE ARE AT YOUR SOLE RISK AND
      DISCRETION, AND ONERX WILL NOT BE LIABLE OR RESPONSIBLE FOR ANY DAMAGE TO YOU, PERSONS YOU
      HAVE LEGAL CUSTODY OF OR AUTHORIZATION FROM OR THE RESPECTIVE PROPERTY OF EACH OF SUCH
      PERSONS. ONERX IS NOT RESPONSIBLE FOR THE CONTENT OR FUNCTIONALITY OF ANY THIRD-PARTY NETWORK,
      PLUGINS OR SERVICES USED IN CONNECTION WITH OR USED TO CONNECT TO THE SERVICE.
      <br />
      <br />
      ONERX MAKES NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICE
      WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR FREE OR THAT ANY DEFECTS IN THE SERVICE WILL BE
      CORRECTED. ONERX FURTHER MAKES NO WARRANTY OR GUARANTEE OF UP-TIME, RESPONSE TIMES, LATENCY,
      SECURITY, TIME BETWEEN FAILURES OR QUALITY OF SERVICE, AND EXPRESSLY DISCLAIMS ANY WARRANTY
      THAT THE SERVICE IS APPROPRIATE FOR HIGH-RISK, FAIL-SAFE, EMERGENCY OR OTHER ACTIVITIES WHERE
      FAILURE OF THE SERVICE COULD RESULT IN SERIOUS HARM TO PERSONS OR PROPERTY. ONERX IS NOT
      RESPONSIBLE FOR INFORMATION LOST OR MISDIRECTED DUE TO INTERRUPTIONS OR FLUCTUATIONS IN THE
      SERVICE, NETWORK CONNECTIONS OR THE INTERNET IN GENERAL.
      <br />
      <br />
      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ONERX, ITS EMPLOYEES,
      ITS LICENSORS, PARTNERS, CONRACTORS, AFFILIATES, SUPPLIERS OR AGENTS DIRECTLY OR INDIRECTLY
      THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS AND
      CONDITIONS AND SHALL UNDER NO CIRCUMSTANCES BE CONSTRUED AS MEDICAL ADVICE.
      <br />
      <br />
      SOME JURISDICTIONS DO NOT PERMIT THE DISCLAIMER OF CERTAIN IMPLIED WARRANTIES, SO CERTAIN OF
      THE FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU. TO THE EXTENT THAT ONERX CANNOT DISCLAIM ANY
      SUCH WARRANTY AS A MATTER OF APPLICABLE LAW, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE
      THE MINIMUM PERMITTED UNDER SUCH LAW.
      <br />
      <br />
      <Title>7. INDEMNIFICATION</Title>
      To the maximum extent permitted by applicable law, You shall indemnify and hold harmless,
      individually and collectively, oneRx, its Affiliates, agents, partners, and other providers
      who furnish goods and services to You in connection with the Service, and their officers,
      directors, managers, employees, and shareholders (the "Indemnified Parties") from and against
      any and all liability, claims, losses (including loss of profits, revenue and goodwill),
      damages, fines, penalties, injuries to persons or property, costs, and expenses (including
      reasonable attorneys' fees and dispute resolution expenses) arising from or related to (i) the
      use of or reliance upon the Service by You or any third party acting upon Your permission,
      knowledge, authority or direction, (ii) a breach of this Agreement by You or third parties
      whom You represent or are acting for or upon, (iii) any negligent acts, omissions to act or
      willful misconduct by You or any third party acting with Your permission, knowledge, authority
      or direction, (iv) the use of the Service in connection with a violation of any applicable
      law, code, regulation, or ordinance, and/or (v) the misappropriation, breach, violation, or
      infringement of any right, title or interest of any third party, including but not limited to,
      contractual rights, intellectual property rights (including patent, trademark, copyright, and
      trade secret rights), rights of privacy, and rights of publicity and personality.
      <Title>8. LIMITATION OF LIABILITY</Title>
      IN NO EVENT SHALL ONERX, ITS AFFILIATES, LICENSORS, CONTRACTORS OR SUPPLIERS BE LIABLE TO YOU
      OR ANY THIRD PARTY FOR SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
      DAMAGES OF ANY KIND INCLUDING WITHOUT LIMITATION, PERSONAL INJURY/WRONGFUL DEATH, LOSS OF
      PROFITS, USE, DATA, GOODWILL, BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES, RESULTING FROM
      (i) YOUR USE OF, ACCESS TO OR INABILITY TO USE OR ACCESS THE SERVICE OR CONTENT PROVIDED IN
      THE SERVICE; (ii) ANY CONTENT OBTAINED FROM THE SERVICE; AND/OR (iii) UNAUTHORIZED USE,
      ACCESS, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT FROM USE OF THE SERVICE, WHETHER BASED
      ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY) OR ANY OTHER LEGAL OR
      EQUITABLE THEORY OF LIABILITY, REGARDLESS OF WHETHER ONERX HAS BEEN INFORMED OF THE
      POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS
      ESSENTIAL PURPOSE.
      <br />
      <br />
      ONERX'S TOTAL LIABILITY TO YOU FOR ANY AND ALL DAMAGES UNDER ALL THEORIES OF LIABILITY AND
      FROM ALL CAUSES OF ACTION WILL BE LIMITED TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU,
      WHICH SHALL NOT EXCEED (IF YOU HAVE PAID ANY FEES TO ONERX) THE FEES PAID BY YOU FOR USE THE
      SERVICE DURING THE ONE (1) MONTH IMMEDIATELY PRIOR TO THE DATE THAT THE EVENTS GIVING RISE TO
      THE, ACTION OR CLAIM FIRST OCCURRED TO ONERX OR (IF YOU HAVE NOT PAID ANY FEES TO ONERX) $50,
      AS APPLICABLE. THE LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK BETWEEN THE
      PARTIES. THE LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY IN ANY AND ALL
      CIRCUMSTANCES.
      <br />
      <br />
      SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY, SO CERTAIN OF THE FOREGOING
      LIMITATIONS MAY NOT APPLY TO YOU.
      <br />
      <br />
      ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE SERVICE, CONTENT, PUBLIC AREAS OR THIS
      AGREEMENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT GIVING RISE TO SUCH
      ACTION OCCURRED. THE REMEDIES FOR SUCH CLAIMS ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN
      THIS AGREEMENT.
      <br />
      <br />
      ONERX HEREBY DISCLAIMS ANY LIABILITY IN RESPECT TO ANY SUCH THIRD-PARTY CONTENT.
      NOTWITHSTANDING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT SUCH THIRD-PARTY CONTENT MAY BE
      "TRANSACTIONAL," OR NEEDED TO COMPLETE THE PROCESS OF ACQUIRING DISCOUNTS OR COUPONS ACCESSED
      THROUGH ONERX, AND MAY RELATE TO OR PROCEED FROM PERSONALLY IDENTIFIABLE INFORMATION (PII) OR
      PERSONAL HEALTH INFORMATION THAT YOU HAVE ENTERED.
      <Title>9. STORAGE OF INFORMATION</Title>
      oneRx is obligated to store Your PHI, Your Content, Account information, Service requests,
      communications, e-mails, messages or any other information pursuant to its agreements with
      Your group health plan and pursuant to HIPAA. oneRx may delete Your Data at any time or
      periodically to comply with applicable law or regulation, its internal document retention
      policies or with its agreements with Your group health plan
      <Title>10. SUPPORT AND FEEDBACK</Title>
      oneRx provides support to You via telephone, and through applicable Service platforms in its
      sole discretion but has no obligation to provide any support for the Service or to fix any
      issues with the Service, including without limitation, customer support, technical support,
      response or implementation of feedback, software or App solutions (e.g., bug fixes, software
      updates). oneRx will use reasonable efforts to troubleshoot and resolve an issue reported by
      You but does not make any representations or guarantees that such issues will be resolved in
      any given time frame or ever.
      <br />
      <br />
      From time to time, oneRx may send You requests to provide feedback on the Service. You submit
      such feedback voluntarily and any feedback received may be used by oneRx to improve the
      Service or for any other purpose in its sole discretion. You hereby grant oneRx, its
      licensors, Affiliates, and suppliers a perpetual, unlimited, worldwide fully-paid up, royalty
      free license to use all feedback, answers, ideas, comments, or other information You provide
      to oneRx through any medium.
      <Title>11. PROMOTIONAL OFFERS</Title>
      You hereby agree that oneRx (including its Affiliates, partners and contractors) may directly
      or indirectly send You marketing materials, Offers and/or promotional offerings via electronic
      transmission, e-mail, mail, or otherwise ("Promotional Offers"), provided, that You may
      unsubscribe to such materials at any time by clicking the "Unsubscribe" link at the bottom of
      any promotional email or Contacting Us. You understand and agree that oneRx may modify the
      scope or discontinue any or all Promotional Offers at any time without additional notice to
      You. You further understand and agree that oneRx may present Promotional Offers only to new
      users and that You may not be eligible for some or all of the Promotional Offers. Any terms
      involving promotion in Your HIPAA Patient Authorization, if any, shall supersede the terms of
      this section.
      <Title>12. ASSIGNMENT</Title>
      oneRx may assign this Agreement and any of its rights and obligations hereunder at any time.
      You may not transfer or assign this Agreement or any of Your rights or obligations under this
      Agreement, and any purported transfer or assignment in violation of this section is void.
      Subject to the foregoing, this Agreement shall be binding on and inure to the benefit of the
      parties, their successors, permitted assigns, and legal representatives.
      <Title>13. MODIFICATIONS</Title>
      We reserve the right to add, remove, fix, change, update, modify, amend or terminate either
      temporarily or permanently any feature, functionality or in entirety the Service or any term
      of this Agreement from time. If we make material changes to the Service or this Agreement, we
      may notify You of such changes by sending You an email notification or other message (e.g.,
      push notifications, in-app news notices) or by posting them on our Website, including when
      such changes shall take effect.
      <br />
      <br />
      You acknowledge and agree that oneRx has no obligation to make available to You any subsequent
      or updated versions of the Service. You also understand and agree that You may or may not be
      required to enter into a renewed version of this Agreement if You want to download, install,
      or use a new version of the Service. By continuing to use or access our Service after those
      revisions take effect, You agree to be bound by all applicable revised terms and conditions of
      this Agreement.
      <br />
      <br />
      The modified terms of this Agreement shall replace and supersede all previously agreed to
      electronic and written terms, as well as any prior versions of this Agreement. In order to
      ensure that You receive any notices from us, You agree to be solely responsible for (i) making
      sure that Your email account is current and functional, (ii) checking Your email regularly,
      and (iii) making sure that oneRx communications are not blocked or rendered undeliverable by
      You, Your computer, any software installed on Your computer, Your Internet service provider,
      Your mobile device or for any other reason. This section shall not apply to any HIPAA Patient
      Authorization; such terms cannot be changed by oneRx without your additional authorization,
      and any revisions shall be conducted pursuant to HIPAA.
      <Title>14. GOVERNING LAW AND VENUE</Title>
      Your use of the Service and the provisions of this Agreement are governed by and construed
      under the laws of the State of Delaware without regard to any conflict of law rules or
      principles. The United Nations Convention on Contracts for the International Sale of Goods
      does not apply to this Agreement or Your use of the Service.
      <br />
      <br />
      You agree that any disputes or claims regarding Your use of the Service or this Agreement
      shall be adjudicated in, and/or the venue for all disputes shall be in, the state and federal
      courts located in the State of Delaware, U.S.A. You agree to submit to the exclusive
      jurisdiction of such courts and agree not to bring any claims or disputes in any other court
      or adjudicative body. You hereby consent to venue and personal jurisdiction in such courts and
      irrevocably waive any right that You may have to assert that such forum is not convenient or
      that any such court lacks jurisdiction.
      <Title>15. THIRD PARTY BENEFICIARIES</Title>
      The following parties are named third party beneficiaries of this Agreement who will have the
      right to enforce this Agreement against You upon acceptance hereof: Google Inc., Apple, Inc.
      and their respective subsidiaries.
      <Title>16. MISCELLANEOUS</Title>
      This Agreement, including the documents referenced and/or incorporated herein, constitutes the
      full agreement between You and oneRx regarding the Service and supersedes all prior or
      contemporaneous understandings or agreements on the subject matter.
      <br />
      <br />
      In the event of any legal action between You and oneRx, the non-prevailing party shall
      reimburse the prevailing party for all reasonable and documented attorneys' fees, costs, and
      expenses relating to the claim or dispute.
      <br />
      <br />
      In the event of any dispute between this Agreement and any requirements of HIPAA, a HIPAA
      Business Associate Agreement, or Your HIPAA Patient Authorization (collectively “HIPAA
      Requirements”) that may apply to your PHI, the HIPAA requirements shall control and supersede
      this Agreement.
      <br />
      <br />
      Any and all claims related to this Agreement, including without limitation, product liability
      claims, any claim that the Service fails to confirm with any applicable legal or regulatory
      requirement or claims arising under consumer protection or similar legislation may be brought
      to our attention by contacting us.
      <br />
      <br />
      The section headings contained in this Agreement are for reference purposes only and shall not
      affect the meaning or interpretation of this Agreement.
      <br />
      <br />
      If a court holds any provision(s) of this Agreement invalid or unenforceable, such
      provision(s) shall be construed to reflect the parties' original intent, and the remaining
      provisions of this Agreement shall remain in full force and effect.
      <br />
      <br />
      oneRx's failure to enforce any provision or right of this Agreement will not be considered a
      waiver of those rights.
      <br />
      <br />
      Nothing in this Agreement shall be deemed or construed to constitute or create employment,
      partnership, association, joint venture, agency, or fiduciary relationship between You and
      oneRx.
      <br />
      <br />
      oneRx shall be excused from any delay or failure in performance hereunder caused by reason of
      occurrence or contingency beyond its reasonable control, including without limitation, acts of
      God, earthquake, fire, flooding, fiber cuts, actions or inactions of third party providers or
      suppliers, riots, sabotage, war, government requirements, or other events that are beyond
      oneRx's reasonable control.
      <br />
      <br />
      You agree and acknowledge that any breach of the provisions regarding intellectual property
      ownership or confidentiality contained in this Agreement shall cause oneRx irreparable harm
      and that oneRx may obtain injunctive relief and seek all other remedies available in law and
      in equity.
      <br />
      <br />
      The section titles in this Agreement are for convenience only and have no legal or contractual
      effect.
      <br />
      <br />
      This electronic document and any other electronic documents, policies, and guidelines
      incorporated erein will be: (i) deemed for all purposes to be a "writing" or "in writing," and
      to comply with all statutory, contractual, and other legal requirements for a writing; (ii)
      legally enforceable as a signed writing as against the parties subject to the electronic
      documents; and (iii) deemed an "original" when printed from electronic records established and
      maintained in the ordinary course of business. Electronic documents introduced as evidence in
      any judicial, arbitration, mediation, or administrative proceeding will, if established and
      maintained in the ordinary course of business, be admissible to the same extent as business
      records in written form that are similarly established and maintained.
      <br />
      <br />
      Notices to You shall be effective on the date sent to Your registered electronic mail address
      when sent by email or via the App, or at oneRx's option, three (3) days following the date
      deposited in regular United States Mail, postage prepaid, and addressed to Your current
      address, if known, associated with Your email address. You are responsible for notifying oneRx
      of any changes in Your contact information or address through Your Account settings or by
      contacting us.
      <br />
      <br />
      All notices from You to oneRx must be in writing, and such written notice shall be effective
      when directed to oneRx's Legal Department and received at our then-current address as posted
      in the Contact Us section below. Your notice must specify Your name, email address and clear
      and detailed background and description of the notice You are providing us.
      <Title>17. CONTACT US</Title>
      Please refer to our Frequently Asked Questions (FAQs) or contact us if You have any questions
      about our Service or this Agreement:
      <br />
      <strong>BY POSTAL MAIL</strong>
      <br />
      <div className='contact-us'>
        <div>oneRx, LLC</div>
        <div>Attn: Legal Department</div>
        <div>3 Beaver Valley Road, Suite 103</div>
        <div>Wilmington, DE, 19803</div>
      </div>
      <div className='contact-us'>
        <strong>BY EMAIL</strong>
        <div>legal@onerx.com</div>
      </div>
    </Content>
  );
};

export default TermsContent;

export enum Routes {
  HOME = '/',
  SIGN_IN = '/login',
  LOGIN_CALLBACK = '/login/callback',
  RESET_PASSWORD = '/recovery_token',
  FORGOT_PASSWORD = '/forgot-password',
  TERMS = '/terms-of-use',
  PRIVACY = '/privacy-policy',
  ABOUT = '/about',
  CONTACT = '/contact',
  DRUG_LOOKUP = '/drug-lookup',
  COMPARE = '/compare',
  RX_DETAIL = 'rx-detail',
  ME = '/me',
  MY_DEPENDENTS = '/me/dependents',
  MY_SETTINGS = '/me/settings',
  CREATE_USER = '/create-user',
  ACTIVATE_USER = '/activate',
  MY_INFO = '/me/info',
  MY_INSURANCE = '/me/insurance',
  MY_ACCOUNT = '/me/account',
  FAQ = '/faq',
  SUPPORT = '/support',

  FULL_PRIVACY_POLICY = '/full-privacy',
  HELP_INSURANCE = '/help-insurance',

  // Health Advocate Register links
  HEALTH_ADVOCATE = '/partnerships/healthadvocate',
  HEALTH_ADVOCATE_MERCER = '/partnerships/healthadvocate_mercer360',

  HA_SIGN_IN_ERROR = '/ha-signin-error',
  HA_TERMS_DECLINED = '/ha-terms-declined',
  HA_SIGNED_OUT = '/ha-signed-out',
  HA_SIGN_ON = '/ha-sign-on',

  // ADMIN ROUTES

  ADMIN_PREFIX = 'admin',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_CREATE = '/admin/users/create',
  ADMIN_USERS_EDIT = '/admin/users/:user/edit',

  ADMIN_ORGANIZATIONS = '/admin/organizations',
  ADMIN_ORGANIZATIONS_CREATE = '/admin/organizations/create',
  ADMIN_ORGANIZATIONS_EDIT = '/admin/organizations/:organization/edit',

  ADMIN_DASHBOARD = '/admin/dashboard',
}

export enum StatelessRoutes {
  //TODO: sync this two endpoints
  BULK_ADD_ERRORS = '/admin/bulk-add/errors',
  BULK_ACTION_ERRORS = '/admin/bulk_actions/errors',
  BULK_ACTION_SUCCESS = '/admin/bulk_actions/success',
}

import React, {useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {Controller, useForm} from 'react-hook-form';
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Input from '../../components/Input';
import Button from '../../components/Button';
import {Routes} from '../routes';
import {useNotification} from '../../context/notification-context';
import getErrorMessage from '../../services/getErrorMessage';
import oktaUserStatus from '../../services/authorization/oktaUserStatus';
import {NOTIFICATION_TYPES} from '../../components/Notification/types';
import {SIGN_IN_FIELDS, SignInFormData, UserStatusParams} from './types';
import getFieldError from '../../utils/getFieldError';
import {regexpPatterns, ValidationMessages} from '../../utils/validation';
import SignInLayout from '../../components/SignInLayout';
import Typography from '../../components/Typography';
import signInBg from '../../assets/sign-in-bg.jpg';
import useEffectOnce from '../../hooks/useEffectOnce';
import StorageKeys from '../../constants/storageKeys';
import {getErrorTemplate} from './functions';

const defaultValues: SignInFormData = {
  [SIGN_IN_FIELDS.USER_NAME]: '',
  [SIGN_IN_FIELDS.PASSWORD]: '',
};

const SignInForm = () => {
  const {oktaAuth} = useOktaAuth();
  const [sessionToken, setSessionToken] = useState<string | undefined>('');
  const {control, handleSubmit, formState} = useForm<SignInFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const notificationsContext = useNotification();

  const checkUserStatus = async (data: UserStatusParams) => {
    const {userData, error} = data;
    try {
      const response = await oktaUserStatus({email: userData.username});

      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.ERROR,
        description: getErrorTemplate(response?.status),
        closesByUser: true,
      });
    } catch (err) {
      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.ERROR,
        description: error,
      });
    }
  };

  useEffectOnce(() => {
    if (sessionStorage.getItem(StorageKeys.SIGN_IN_FAILED)) {
      sessionStorage.removeItem(StorageKeys.SIGN_IN_FAILED);

      notificationsContext?.setNotificationState?.({
        type: NOTIFICATION_TYPES.ERROR,
        description: (
          <>
            There was a problem logging into oneRx. Please try again later. If you still cannot
            login, please contact the <a href='mailto:support@onerx.com'>oneRx Help Team</a>
          </>
        ),
      });
    }
  });

  const signIn = async (data: SignInFormData) => {
    try {
      const response = await oktaAuth.signInWithCredentials({
        username: data.username,
        password: data.password,
      });

      const sessionToken = response?.sessionToken;

      setSessionToken(sessionToken);
      // sessionToken is a one-use token, so make sure this is only called once
      await oktaAuth.signInWithRedirect({
        sessionToken: response?.sessionToken,
        responseType: 'token',
      });
    } catch (error) {
      const signInError = getErrorMessage(error);
      checkUserStatus({userData: data, error: signInError});
    }
  };

  const submitForm = handleSubmit((data) => signIn(data));

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null;
  }

  return (
    <>
      <Helmet>
        <title>oneRx | Drug Price Comparisons</title>
        <meta
          name='description'
          content='oneRx helps users save money on prescription drugs through personalized medication price comparisons, powered by Truveris'
        />
        {/* Start of HubSpot Embed Code */}
        <script
          type='text/javascript'
          id='hs-script-loader'
          async
          defer
          src='//js-na1.hs-scripts.com/2298173.js'
        ></script>
        {/* End of HubSpot Embed Code  */}
      </Helmet>
      <SignInLayout
        title={
          <>
            <Typography level={1} data-e2e='login-form__title'>
              Welcome to <strong>oneRx</strong>
            </Typography>
            <Typography level={2} data-e2e='login-form__description'>
              Discover the best price for your medications by comparing your price with insurance
              and oneRx.
            </Typography>
          </>
        }
        backgroundImage={signInBg}
      >
        <form onSubmit={submitForm}>
          <Typography level={1}>Login</Typography>
          <input type='submit' style={{display: 'none'}} />

          <Controller
            name='username'
            control={control}
            rules={{
              required: ValidationMessages.REQUIRED,
              pattern: {
                value: regexpPatterns.EMAIL,
                message: ValidationMessages.EMAIL,
              },
            }}
            render={(props) => (
              <Input
                label='Email'
                type='text'
                placeholder='Enter your email'
                error={getFieldError(formState, props.name, formState.submitCount !== 1)}
                data_e2e='login-form__email-input'
                {...props}
              />
            )}
          />

          <Controller
            name='password'
            control={control}
            rules={{
              required: ValidationMessages.REQUIRED,
            }}
            render={(props) => (
              <Input
                label='Password'
                type='password'
                placeholder='Enter your password'
                error={getFieldError(formState, props.name, formState.submitCount !== 1)}
                data_e2e='login-form__password-input'
                {...props}
              />
            )}
          />

          <NavLink
            data-e2e='login-form__forgot-password-link'
            to={Routes.FORGOT_PASSWORD}
            style={{marginBottom: '16px', display: 'inline-block'}}
          >
            Forgot password?
          </NavLink>

          <Button
            type='primary'
            size='large'
            disabled={formState.isSubmitting}
            primaryGreen
            data_e2e='login-form__sign-in-button'
            onClick={submitForm}
            block
          >
            Sign in
          </Button>
        </form>
      </SignInLayout>
    </>
  );
};
export default SignInForm;

import {SetterOrUpdater} from 'recoil';

import generateRecoilState from './generateRecoilState';
import {RecoilKeys} from './enums';
import getMyUser from '../services/user/getMyUser';
import {UserState} from '../services/user/types';

const userState = generateRecoilState<UserState>(RecoilKeys.USER, getMyUser);

export const saveTermsAcceptance = (setter: SetterOrUpdater<UserState>) => {
  const formattedDate = new Date().toISOString();

  setter((data) => {
    return data && {...data, accepted_term_of_use_at: formattedDate};
  });
};

export default userState;

import React, {FC} from 'react';
import {Modal as AntModal} from 'antd';
import styled from 'styled-components/macro';

import {ReactComponent as CrossOutLogo} from '../../assets/cross-out.svg';
import styles from './styles';
import Button from '../Button';
import {defaultFooterTypes, ModalProps} from './types';

const defaultFooter = ({
  onOk,
  onCancel,
  okText = 'Ok',
  cancelText = 'Cancel',
  cancelButtonProps,
  okButtonProps,
  footerChildren,
}: defaultFooterTypes) => [
  <Button
    key='ok'
    type='primary'
    size='large'
    data-e2e='footer__ok-button'
    onClick={onOk}
    {...okButtonProps}
  >
    {okText}
  </Button>,
  <Button
    type='default'
    key='cancel'
    size='large'
    data-e2e='footer__cancel-button'
    onClick={onCancel}
    {...cancelButtonProps}
  >
    {cancelText}
  </Button>,
  ...footerChildren,
];

const ModalUnStyled = ({
  footerButtonAlignment = 'flex-end',
  onOk,
  onCancel,
  okText,
  cancelText,
  cancelButtonProps,
  okButtonProps,
  footerChildren = [],
  footerRenderer,
  ...props
}: ModalProps) => {
  const footerRenderCallback = footerRenderer ?? defaultFooter;

  return (
    <AntModal
      closeIcon={<CrossOutLogo />}
      footer={footerRenderCallback({
        onOk,
        onCancel,
        okText,
        cancelText,
        cancelButtonProps,
        okButtonProps,
        footerChildren,
      })}
      onCancel={onCancel}
      centered
      width={800}
      {...props}
    />
  );
};

const Modal: FC<ModalProps> = styled(ModalUnStyled)`
  ${styles}
`;

export default Modal;

import _ from 'lodash';

import {Optional} from '../../types';
import {regexpPatterns} from '../validation';

export function minLen(value: Optional<string>) {
  return !_.isNil(value) && value?.length >= 8;
}

export function oneUpperCase(value: Optional<string>) {
  return !_.isNil(value) && regexpPatterns.UPPERCASE_SYMBOL.test(value);
}

export function oneLowerCase(value: Optional<string>) {
  return !_.isNil(value) && regexpPatterns.LOWERCASE_SYMBOL.test(value);
}

export function oneNumericCharacter(value: Optional<string>) {
  return !_.isNil(value) && regexpPatterns.OKTA_PASSWORD_SPECIAL_CHARACTERS.test(value);
}

export function checkEmailSequence(value: Optional<string>, email: Optional<string>) {
  if (!value) {
    return false;
  } else if (!email) {
    return true; // TODO: change it during implementation in forgot password ticket
  } else if (value.length >= 3) {
    for (var i = 0, valLength = value.length; i < valLength; i += 1) {
      if (valLength - i < 3) {
        continue;
      }
      if (email.includes(value.toLocaleLowerCase().substring(i, i + 3))) {
        return false;
      }
    }
    return true;
  } else {
    return true;
  }
}

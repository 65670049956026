import React from 'react';

import {PopoverContentProps} from '../types';
import {PopoverContentStyles} from '../styles';
import {ReactComponent as OutlineCheckCircleIcon} from '../../../assets/outline-check-circle.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/error-icon.svg';

const PopoverContent = ({tooltipHintItems}: PopoverContentProps) => {
  return (
    <PopoverContentStyles {...tooltipHintItems}>
      {(tooltipHintItems as any[]).map(({item, id, valid, error}) => {
        return (
          <p key={id} className={`${valid ? 'valid' : ''} ${error ? 'error' : ''}`}>
            <span>{item}</span>

            {valid && <OutlineCheckCircleIcon />}
            {error && <ErrorIcon />}
          </p>
        );
      })}
    </PopoverContentStyles>
  );
};

export default PopoverContent;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';
import {AvatarStyles} from './components/DesktopNavigation/components/UserAvatar/AvatarStyles';

export const NavigationStyles = styled.nav<ThemeProps>`
  background-color: ${(props) => props.theme.colors.WHITE};
  border-radius: 0 0 30px 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  padding: 12px ${({theme}) => theme.grid.gutter.xlg} 12px;

  ${({theme}) =>
    theme.forWidth(
      'max',
      'sm',
      `padding-left: ${theme.grid.gutter.sm};
      padding-right: ${theme.grid.gutter.sm}`,
    )}

  max-height: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;

  > div {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;

    li {
      line-height: 14px;
      margin-right: ${({theme}) => theme.grid.gutter.xlg};
      padding-top: 28px;
      padding-bottom: 33px;

      a {
        color: ${(props) => props.theme.colors.BLUE3};
        font-size: 18px;
        text-decoration: none;
        font-weight: 500;
        &.active {
          color: ${(props) => props.theme.colors.BLUE3};
          pointer-events: none;
          ${({theme}) =>
            theme.forWidth('min', 'md', `color: ${theme.colors.GREY1}${theme.colors.THIRTY};`)}
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    ${AvatarStyles} {
      margin-left: ${({theme}) => theme.grid.gutter.xlg};
    }
  }
`;

export const LogoLink = styled.a<ThemeProps>`
  align-self: center;
`;

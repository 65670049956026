import userState from '../recoil/userState';
import isAdminRoleName from './isAdminRoleName';

const isAdmin = (): boolean => {
  const {data} = userState.useValue();

  return isAdminRoleName(data?.user_role?.name) && !!data?.user_role?.name;
};

export default isAdmin;

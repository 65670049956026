export enum DISPLAY_NAME {
  ABOUT_ONE_RX = 'About oneRx',
  FINDING_AND_PRICING_DRUGS = 'Finding & Pricing Drugs',
  HEALTHCARE_AND_INSURANCE_TERMS = 'Healthcare & Insurance Terms',
  INSURANCE = 'Insurance',
  PHARMACIES = 'Pharmacies',
  YOUR_ACCOUNT = 'Your Account',
}

export enum COMPONENT_NAME {
  ABOUT_ONE_RX = 'AboutOneRX',
  FINDING_AND_PRICING_DRUGS = 'FindingAndPricingDrugs',
  HEALTHCARE_AND_INSURANCE_TERMS = 'HealthcareAndInsuranceTerms',
  INSURANCE = 'Insurance',
  PHARMACIES = 'Pharmacies',
  YOUR_ACCOUNT = 'YourAccount',
}

export interface FAQComponent {
  componentName: COMPONENT_NAME;
  displayValue: DISPLAY_NAME;
}

export interface FAQComponentList {
  components: Array<FAQComponent>;
}

import {Optional} from '../types';

export const GENERAL_ERROR = 'Server returned an error.';

export const userHasBeenCreated = (email: string) => {
  return `You successfully created the account and an email prompting the user to activate the account has been sent to ${email}.`;
};

export const organizationHasBeenCreated = (org: string) => {
  return `${org} was successfully added.`;
};

export enum OKTA_ERROR_CODES {
  UPDATE_OF_CREDENTIALS_FAILED = 'E0000014',
  RECOVERY_ANSWER_OKTA_CODE = 'E0000087',
}

export const oktaErrors: Record<string, string> = {
  E0000001: 'A user with this email already exists!',
  E0000004: 'You have entered an incorrect email or password',
  E0000095: 'User with this email does not exist!',
  E0000034: 'Forgot password not allowed on specified user, please contact help and support',
  E0000011: 'The link is expired!',
  [OKTA_ERROR_CODES.RECOVERY_ANSWER_OKTA_CODE]: 'Please enter the correct security answer!',
  E0000080: 'Your password cannot be the same as your last 4 Okta passwords.',
  [OKTA_ERROR_CODES.UPDATE_OF_CREDENTIALS_FAILED]:
    'The temporary password you entered is incorrect. Please re-enter your temporary password.',
};

const serverErrors: Record<string, string> = {
  not_created: 'Object was not created',
  activation_expired: 'The activation link is expired. Please contact support for more information',
  user_already_exists: 'A user with this email already exists',
  email_not_valid:
    "We couldn't find a user with that email address. Check your spelling and try again.",
};

export interface ServerError {
  error_code: Optional<string>;
  error_msg: Optional<string>;
  errorCode: Optional<string>;
}

const getErrorMessage = (
  error: Optional<ServerError>,
  useServerMessage: boolean = false,
): string => {
  const error_code = error?.error_code || error?.errorCode;
  const error_msg = error?.error_msg;

  if (error_code) {
    if (serverErrors[error_code]) {
      return useServerMessage
        ? error_msg || serverErrors[error_code] || GENERAL_ERROR
        : serverErrors[error_code] || error_msg || GENERAL_ERROR;
    }

    if (oktaErrors[error_code]) {
      return oktaErrors[error_code];
    }
  }

  return useServerMessage && error_msg ? error_msg : GENERAL_ERROR;
};

export default getErrorMessage;

import {HttpClient} from '../../index';
import {ActivateUserRequestData} from './types';
import {getQueryString} from '../../../utils/urls';
import {
  ACTIVATE_USER_FORM_FIELDS,
  ActivateUserFormData,
} from '../../../pages/UserSelfActivation/components/UserSelfActivationForm';

const transformRequestData = (data: ActivateUserFormData): ActivateUserRequestData => ({
  activation_token: getQueryString('token') || '',
  old_password: data[ACTIVATE_USER_FORM_FIELDS.TEMP_PASSWORD]!,
  new_password: data[ACTIVATE_USER_FORM_FIELDS.NEW_PASSWORD]!,
  recovery_question_dict: {
    question: data[ACTIVATE_USER_FORM_FIELDS.SECURITY_QUESTION]!,
    answer: data[ACTIVATE_USER_FORM_FIELDS.SECURITY_ANSWER]!,
  },
  accepted_term_of_use: true,
});

const activateUser = (data: ActivateUserFormData): Promise<any> => {
  return HttpClient.post<any>({
    url: '/account/activate_account',
    data: transformRequestData(data),
  });
};

export default activateUser;

import {css, ThemeProps} from 'styled-components';

import {Theme} from '../../theme/theme';

type Props = ThemeProps<Theme>;

const notificationWrapper = `
  border-radius: 2px;
  box-shadow: 0 5px 30px 0 rgba(125, 138, 159, 0.2);
  z-index: 200;
`;

export const notificationStyles = css`
  &.ant-notification-bottomLeft {
    margin-left: ${(props: Props) => props.theme.grid.gutter.sm};
    ${({theme}) => theme.forWidth('min', 'md', `padding: ${theme.grid.padding}; margin-left: 0;`)}
  }

  .ant-notification-hook-holder {
    width: ${(props: Props) => `calc(100vw - ${props.theme.grid.gutter.lg})`};
    ${({theme}) => theme.forWidth('min', 'md', 'width: 606px;')};
    ${notificationWrapper}
  }

  .ant-notification-notice {
    width: auto;
    padding: 16px 16px 16px 25px;
  }

  .ant-notification-notice-icon {
    margin-left: 0;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 40px;
  }

  .ant-notification-close-icon {
    font-size: 11px;
    color: ${(props: Props) => props.theme.colors.GREY};
  }

  &.ant-alert {
    max-width: 606px;
    ${notificationWrapper}
  }

  &.ant-alert-success,
  .ant-notification-success {
    background-color: ${(props: Props) => props.theme.colors.AQUAMARINE3};
    border: 1px solid ${(props: Props) => props.theme.colors.AQUAMARINE1};
  }
  .anticon.ant-notification-notice-icon-success {
    color: ${(props: Props) => props.theme.colors.NEW_GREEN};
  }

  &.ant-alert-info,
  .ant-notification-info {
    background-color: ${(props: Props) => props.theme.colors.PURPLE3};
    border: 1px solid ${(props: Props) => props.theme.colors.BLUE3};
  }
  .anticon.ant-notification-notice-icon-info {
    color: ${(props: Props) => props.theme.colors.PRIMARY};
  }

  &.ant-alert-warning,
  .ant-notification-warning {
    background-color: ${(props: Props) => props.theme.colors.YELLOW4};
    border: 1px solid ${(props: Props) => props.theme.colors.YELLOW};
  }
  .anticon.ant-notification-notice-icon-warning {
    color: ${(props: Props) => props.theme.colors.YELLOW1};
  }

  &.ant-alert-error,
  .ant-notification-error {
    background-color: ${(props: Props) => props.theme.colors.RED3};
    border: 1px solid ${(props: Props) => props.theme.colors.RED};
  }
  .anticon.ant-notification-notice-icon-error {
    color: ${(props: Props) => props.theme.colors.RED1};
  }

  .ant-alert-message,
  .ant-alert-with-description .ant-alert-message,
  .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    color: ${(props: Props) => props.theme.colors.GREY};
  }

  .ant-alert-with-description .ant-alert-message {
    margin-bottom: ${(props: Props) => props.theme.grid.gutter.xs};
  }

  .ant-notification-notice-description {
    white-space: pre-wrap;
  }

  .ant-alert-description,
  .ant-notification-notice-description,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    ${(props: Props) => ({...props.theme.textStyle.body1})};
    color: ${(props: Props) => props.theme.colors.GREY};
  }

  &.ant-alert-with-description .ant-alert-close-icon,
  .ant-notification-notice-close {
    top: 15px;
    right: 17px;
    font-size: 11px;
  }

  &.ant-alert-with-description .ant-alert-icon,
  .ant-notification-notice-icon {
    font-size: 21px;
  }
`;

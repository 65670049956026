import {notification} from 'antd';
import {NotificationPlacement} from 'antd/lib/notification';
import {createGlobalStyle, ThemeContext, ThemeProps} from 'styled-components/macro';
import React, {FC, useCallback, useContext} from 'react';

import {notificationDuration, notificationMessageByType} from '../components/Notification';
import {Theme} from '../theme/theme';
import useWidth from '../hooks/useWidth';

export type NotificationState = {
  type: 'success' | 'info' | 'error' | 'warning';
  title?: string;
  description?: string | React.ReactNode;
  placement?: NotificationPlacement;
  closesByUser?: boolean;
};

type ContextProps = {
  setNotificationState: (arg: NotificationState) => void;
};

type GlobalStylesProps = {placement: NotificationPlacement} & ThemeProps<Theme>;

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`

  &.ant-notification-bottomRight  {
    ${({theme, placement}) => {
      return placement === 'topLeft'
        ? theme.forWidth(
            'max',
            'lg',
            'left: 0 !important; top: 24px !important; bottom: auto !important',
          )
        : '';
    }}
  }
  &.ant-notification-topLeft  {
    ${({theme, placement}) => {
      return placement === 'bottomRight'
        ? theme.forWidth(
            'min',
            'lg',
            'right: 0 !important; left: auto !important; top: auto!important; bottom: 24px !important',
          )
        : '';
    }}
  }
`;

const NotificationContext = React.createContext<Partial<ContextProps>>({});

export const NotificationProvider: FC = (props) => {
  const [api, contextHolder] = notification.useNotification();

  const theme = useContext<Theme>(ThemeContext);
  const width = useWidth();

  const placement = width && width <= theme.grid.breakpoints.lg ? 'topLeft' : 'bottomRight';

  const setNotificationState = useCallback(
    ({type, description, title, closesByUser}: NotificationState) => {
      api[type]({
        message: title || notificationMessageByType[type],
        description,
        placement,
        className: `ant-notification-${type}`,
        duration: closesByUser ? 0 : notificationDuration[type],
      });
    },
    [api, placement],
  );

  return (
    <NotificationContext.Provider
      value={{
        setNotificationState,
      }}
      {...props}
    >
      <GlobalStyles placement={placement} />
      {props.children}
      {contextHolder}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (typeof context === 'undefined') {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
};

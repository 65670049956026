import {Dropdown, Menu} from 'antd';
import React, {useMemo} from 'react';
import {generatePath, Link} from 'react-router-dom';
import {useLocation} from 'react-router';
import {Routes} from 'pages/routes';
import userState from 'recoil/userState';

import {DefaultLink} from '../NavLinks/styles';
import Role from 'constants/enums/Role';
import texts from 'constants/texts';

const {useValue: useUserStateValue} = userState;

const NavLinks = () => {
  const {data} = useUserStateValue();
  const {pathname} = useLocation();

  const isAdminRoute = useMemo(() => pathname.includes(Routes.ADMIN_PREFIX), [pathname]);

  const organizationId = data?.employer?.id || '';
  const menu = (
    <Menu
      className='admin-link--overlay'
      data-e2e='navigation__admin'
      children={[
        <Link
          className='admin-link--overlay__item'
          data-e2e='navigation__admin-users-link'
          to={Routes.ADMIN_USERS}
          key={'admin_users'}
        >
          {texts.users}
        </Link>,
        [Role.TRUVERIS_ADMIN_ALL_CLIENTS, Role.TRUVERIS_SUPER_ADMIN].includes(
          data?.user_role?.name as Role,
        ) ? (
          <Link
            className='admin-link--overlay__item'
            data-e2e='navigation__admin-organization-link'
            to={Routes.ADMIN_ORGANIZATIONS}
            key={'admin_organizations'}
          >
            {texts.organization_s}
          </Link>
        ) : (
          <Link
            className='admin-link--overlay__item'
            data-e2e='navigation__admin-organization-link'
            to={generatePath(Routes.ADMIN_ORGANIZATIONS_EDIT, {organization: organizationId})}
          >
            {texts.organization}
          </Link>
        ),
        [Role.TRUVERIS_ADMIN_ALL_CLIENTS, Role.TRUVERIS_SUPER_ADMIN].includes(
          data?.user_role?.name as Role,
        ) ? (
          <Link
            className='admin-link--overlay__item'
            data-e2e='navigation__admin-organization-link'
            to={Routes.ADMIN_DASHBOARD}
            key={'admin_dashboard'}
          >
            {texts.oneRxDashboard}
          </Link>
        ) : null,
      ]}
    />
  );

  return (
    <li className={`admin-link ${isAdminRoute ? 'active' : ''}`}>
      <Dropdown
        overlay={menu}
        placement='bottomLeft'
        overlayClassName='admin-link--overlay'
        data-e2e='navigation__admin-dropdown'
      >
        <DefaultLink data-e2e='navigation__admin'>{texts.admin}</DefaultLink>
      </Dropdown>
    </li>
  );
};

export default NavLinks;

import memoize from 'lodash/memoize';

import storageKeys from '../constants/storageKeys';

export default memoize(function shouldHideSignIn() {
  return Boolean(sessionStorage.getItem(storageKeys.SHOULD_HIDE_SIGN_IN));
});

export function hideSignIn() {
  sessionStorage.setItem(storageKeys.SHOULD_HIDE_SIGN_IN, 'yes');
}

import React from 'react';

import {Content, Title} from '../Privacy/styles';

const FullPrivacyPolicyContent = () => {
  return (
    <Content>
      Effective: February 01, 2020
      <Title>I. INTRODUCTION</Title>
      This Privacy Policy describes the types of information we may collect; how we collect; how we
      use, with whom we share; how we protect your information; your choices and rights regarding
      the information collected; other information related to the privacy of your information; and
      how to contact us. We encourage you to freely communicate with us about questions or concerns
      about this Privacy Policy. References to "OneRx", “our”, “us” or ‘we” refer to Truveris, Inc.,
      including, if applicable, any company that Truveris controls or is controlled by, including
      without limitation a parent or subsidiary, as applicable ("Related Entity").
      <br />
      <br />
      This Privacy Policy applies to all existing and future website(s), application(s), domain
      name(s), linked pages, features, content, products, services, mobile properties and mobile
      device applications (each, a "Service Offering") owned or operated by OneRx. By visiting or
      using any of our Service Offerings, you hereby acknowledge and accept the terms and conditions
      set forth in this Privacy Policy, including without limitation, the use and disclosure of your
      information as outlined in this Privacy Policy. For other terms and conditions that govern
      your use of our Service Offerings, please visit{' '}
      <a style={{fontSize: '12px'}} href='/'>
        www.onerx.com
      </a>
      .
      <Title>
        II. PERSONAL INFORMATION
        <br />
        a. Information We Collect
      </Title>
      We collect personal data you choose to provide while utilizing our Service Offerings, e.g.,
      through registrations, applications, transactions and surveys, and in connection with your
      inquiries and purchases. In some circumstances, the personal data provided including your
      name, date of birth, physical address, e-mail address, phone number, billing and shipping
      information, and information related to your prescription, may include, or may be used and
      disclosed to generate Protected Health Information ("PHI"). In those instances, our use of the
      PHI will also be subject to the requirements of the Health Information Portability and
      Accountability Act (frequently referred to as 'HIPAA'). For the purposes of this Privacy
      Policy, PHI shall have the same meaning as defined in HIPAA and may be included in the
      definition of "personally identifiable information" or "PII" (as defined below). To the extent
      any term(s) in this Privacy Policy conflict with any term(s) in HIPAA, HIPAA will control and
      supersede the terms in this Privacy Policy.
      <br />
      <br />
      We collect a variety of personally identifiable information ("PII") about users in order to
      conduct our Service Offerings. "PII" means information that identifies, relates to, describes,
      references, is capable of being associated with, or could reasonably be linked, directly or
      indirectly, with a particular user.
      <br />
      <br />
      You are responsible for ensuring the accuracy of all PII that you submit to OneRx. Inaccurate
      information may affect your experience when using our Service Offerings and/or our ability to
      contact you as described in this Privacy Policy.
      <br />
      <br />
      PII does not include "De-Identified Information" which is information or data we collect from
      which individual user identities have been removed. De-identified Information may be
      aggregated to helps us understand trends, user needs and other information to provide improved
      Service Offerings. We also collect "Non-Personal Information" about your use and interaction
      with our Service Offerings. "Non-Personal Information" means information that we cannot use to
      identify you. Non-Personal Information is not treated as PII unless we combine it with or link
      it to PII that you give to us. We may collect your Non-Personal Information through cookies,
      web beacons, log files, and similar technologies. “Cookies” are small files that we place on
      your computer. Your browser can be configured to accept or reject most types of cookies.
      Please consult your browser’s documentation for more information.
      <br />
      <Title>b. Information About Others</Title>
      You may have the opportunity to provide information about other individuals through our
      Service Offerings. If you submit any PII relating to other people to us, you represent that
      you have the authority and have obtained consent to do so prior to providing to us and to
      permit us to use the information in accordance with this Privacy Policy.
      <br />
      <br />
      <Title>
        III. HOW WE COLLECT INFORMATION, AND CATAGORIES OF INFORMATION
        <br />
        a. Information You Give Us
      </Title>
      We collect PII that you give to us when you register or use our Service Offerings. When you
      register for a user account ("Account") on the website or mobile application, you will be
      assigned a unique account identifier ("Account ID") which will be saved and be associated with
      any PII you give to us under your Account ID. In particular, we may collect the following
      categories of information from users:
      <br />
      <ul>
        <li>
          Identifiers, which may include, for example, a real name, Internet Protocol address, email
          address, telephone number, or other similar identifiers;
        </li>
        <li>
          Categories of information including driver's license, insurance policy number, medical
          information, or health insurance information;
        </li>
        <li>
          Classification characteristics, including age, marital status, medical conditions, and
          sex.
        </li>
      </ul>
      You may give us information to allow us to contact you or use certain features available
      through our Service Offerings without signing up for an Account, such as when you fill out a
      request form, report a coupon, co-pay card or voucher, provide feedback, email us, or engage
      in communications with our team. Some of this information may be collected when you use some
      functionality of the Service Offerings without registering for an Account.
      <br />
      <Title>b. Location Information</Title>
      When you access or use our Service Offerings using a desktop or mobile device, you can enter
      your location or give us your consent to access your location ("Location Information"). If you
      use our mobile application, we may, in accordance with your consent, passively collect
      Location Information when our application is installed on your mobile device. You can change
      the privacy settings of your mobile device at any time to stop sharing your Location
      Information with us. Please note that certain features and functionality may be affected or be
      unavailable if you do not share your Location Information with us.
      <br />
      <Title>c. Call Recording and Monitoring</Title>
      Your calls to and from us may be recorded for various purposes including: monitoring customer
      service quality or compliance, checking accuracy of information you provide us, preventing
      fraud or providing training for our staff or customer service representatives. Any PII
      obtained from you during the call will be treated in accordance with the provisions of this
      Privacy Policy.
      <br />
      <Title>d. Information We Collect Automatically</Title>
      Even if you do not provide any PII to us, we automatically collect certain information about
      your use and interaction with our Service Offerings. For example, when you visit our website,
      our systems automatically maintain web logs to record data about all visitors who use our
      website and stores this information in our database. These web logs may contain information
      about you including the following: IP address, type(s) of operating system you use, type of
      device you use, date and time you visited the website, your activity and/or referring
      websites. We use your log information to troubleshoot problems, gather demographic
      information, customize your experience when accessing our Service Offerings and other business
      purposes.
      <br />
      <Title>e. Information Collected From Other Sources</Title>
      We may combine the information we collect from you through the Service Offering with
      information we receive from and about you from other online and offline sources, and may use
      the combined information in accordance with this Privacy Policy.
      <br />
      <Title>f. Cookies and Web Beacons</Title>
      A cookie is a small data file that certain websites write to your computer hard drive when you
      visit the website. These files identify your computer and record your preferences and other
      data about your visit to our website and/or websites you accessed before visiting our Service
      Offerings.
      <br />
      <br />
      A web beacon is a tiny, often invisible graphic image displayed in a webpage, web-based
      document, or e-mail message. When the user's web browser reads the code embedded in a web
      beacon, it can pass along the following information to us: IP address of the user's computer
      or mobile device, date and time viewed, length of time viewed and type of browser used. Web
      beacons can also be linked to a user's cookies and any personally identifiable information
      that may be stored in them.
      <br />
      <br />
      <Title>IV. HOW WE USE INFORMATION COLLECTED</Title>
      We may use, disclose, or store the PII we collect about you to operate our business and
      deliver our Service Offerings. We utilize PII for the following purposes:
      <br />
      <ul>
        <li>
          Process, complete and fulfill your requested transactions, e.g., to administer Service
          Offerings;
        </li>
        <li>
          Provide you with support or access to Service Offerings and other tool or offerings;
        </li>
        <li>
          Respond to your inquiries, including to investigate complaints and address various
          requests;
        </li>
        <li>Send you administrative communications;</li>
        <li>Obtain your feedback on our site and our Service Offerings;</li>
        <li>
          Combine your information with other information, including without limitation,
          demographics, prescription drug pricing, or other aggregated information, for analysis,
          testing and other purposes;
        </li>
        <li>
          Statistically analyze or measure user behavior, activity and industry trends for testing
          or other purposes that may help us improve our Service Offerings;
        </li>
        <li>Provide you with related content, marketing and/or advertisements;</li>
        <li>Conduct research on and/or analyze Accounts or website activities; or</li>
        <li>
          Send you personalized emails, offers, information and secure electronic messages
          pertaining to your Account, activities or related interests, including news,
          announcements, marketing, reminders and opportunities.
        </li>
        <li>
          Respond to law enforcement requests as required by applicable law, regulation, or court
          order. Investigate security breaches or otherwise cooperate with authorities.
        </li>
      </ul>
      <br />
      We also use cookies to maintain continuity of navigation when viewing our website during one
      (1) online session ("Session Cookie"). After closing the browser, a Session Cookie simply
      terminates and is not stored on your computer hard drive thereafter. Other cookies are saved
      and stored on your computer hard drive, so we can customize your next visit to our website by
      saving your settings, preferences, drug information and other information collected in a prior
      session. As a consequence, cookies are often tied to your PII when used by us. We also use
      information collected through cookies and/or web beacons as De-identified Information to
      understand how our users use our Service Offerings, gauge traffic, troubleshoot and make
      adjustments to continually improve our Service Offerings. We may use remarketing to advertise
      online. Under these circumstances, we may place cookies and/or web beacons on your mobile
      devices or computer to allow third parties and their advertisers to feed advertisements to you
      based on the history of your past visits to our website or other websites. These cookies
      and/or web beacons do not enable third parties to access any of your personally identifiable
      information, and we will not provide third parties with information that discloses a user's
      personal health condition or personal protected health information ("PHI"). We are not
      responsible for the actions of cookies and/or web beacons from third party websites, which may
      require you to accept cookies and/or web beacons that collect information.
      <Title>
        V. WITH WHOM DO WE SHARE INFORMATION
        <br />
        a. Disclosure To Third Parties
      </Title>
      We may disclose your PII or Non-Personal Information to third parties for any of the permitted
      uses described above. For example, we may disclose your Personally Identifiable Information or
      Non-Personal Information to:
      <br />
      <ul>
        <li>
          Our service providers, clients or agents, who facilitate or provide services related to
          Services Offerings such as website hosting, data analysis, payment, payment processing,
          coupon/co-pay card/voucher fulfillment, information technology and related infrastructure
          provision, customer service, e-mail delivery, auditing, and other services;
        </li>
        <li>Law enforcement officials, regulatory authorities, or as otherwise required by law;</li>
        <li>Our Related Entities or business partners;</li>
        <li>
          Third parties in connection with the sale or transfer of some or all of our business,
          assets, or stock (including in connection with any bankruptcy or similar proceedings or by
          way of merger or other change of control) or as security, or to the extent we engage in
          business negotiations with third parties, to third parties as part of any such transaction
          or negotiation.
        </li>
      </ul>
      <Title>b. Disclosure Without Your Consent</Title>
      In general, we may disclose your information without your consent to disclosure when we
      reasonably believe disclosure is appropriate in order to:
      <br />
      <ul>
        <li>Comply with the law (e.g., lawful subpoena or court order);</li>
        <li>
          Cooperate with or report to law enforcement agencies in investigations that involve users
          who use our Service Offerings for activities that are or seem illegal or illegitimate
          activities;
        </li>
        <li>Enforce or apply agreements for our Service Offerings; or</li>
        <li>
          Protect our rights or property or that of our affiliates, including respective officers,
          directors, employees, agents, third-party content providers, suppliers, sponsors, or
          licensors (e.g., to address allegations about fraudulent or unlawful activity related to a
          OneRx account).
        </li>
      </ul>
      We may share your information amongst Related Entities where it is necessary to support,
      improve, develop or otherwise provide the Service Offerings to you.
      <Title>c. Sale of Personal Information</Title>
      In the preceding twelve (12) months, we have not sold personal information.
      <br />
      <Title>d. Disclosure Related to Business Events</Title>
      Information about our users, including PII, may be disclosed as part of any merger,
      acquisition, public offering or sale of company assets. It may also be disclosed in the
      unlikely event of insolvency, bankruptcy, or receivership in which personally identifiable
      information would be transferred as one of our business assets.
      <br />
      <br />
      <Title>VI. YOUR RIGHTS AND CHOICES</Title>
      You can request the removal or modification of the PII you have provided to us by sending an
      e-mail to{' '}
      <a style={{fontSize: '12px'}} href='mailto:legal@truveris.com'>
        legal@truveris.com
      </a>
      . For your protection, we may only implement requests with respect to the PII associated with
      the particular e-mail address that you use to send us your request, and we may need to verify
      your identity and obtain information on the context in which you provided your PII before
      implementing your request. We will try to accommodate your request as soon as reasonably
      practicable.
      <br />
      <br />
      Please note that we may need to retain certain information for recordkeeping purposes and/or
      to complete any transactions that you began prior to requesting such change or deletion. There
      may also be residual information that will remain within our databases and other records,
      which will not be removed.
      <br />
      <br />
      You may click the unsubscribe link at the bottom of emails you receive from us to stop
      receiving emails and reply STOP to our text messages to stop receiving SMS messages. You may
      control the receipt of push notifications from our mobile applications through your mobile
      device settings. Do-Not-Track Signals your browser may allow you to send us a "do-not-track
      signal" to communicate your privacy preferences to us. The Service Offerings do not respond to
      browser do-not-track signals.
      <Title>a. Your California Privacy Rights</Title>
      California Civil Code Section &sect; 1798.83 permits users of the Services that are California
      residents to request certain information regarding our disclosure of PII to third parties for
      their direct marketing purposes. To make such a request, please contact us as described in the
      "Contact Us" section below.
      <br />
      <ul>
        <li>
          <Title>Access to Specific Information and Data Portability Rights</Title>
        </li>
        You have the right to request that we disclose certain information to you about our
        collection and use of your personal information over the past 12 months. Once we receive and
        confirm your verifiable consumer request, we will disclose to you:
        <br />
        <ul>
          <li>The categories of personal information we collected about you.</li>
          <li>The categories of sources for the personal information we collected about you.</li>
          <li>
            Our business or commercial purpose for collecting or selling that personal information.
          </li>
          <li>The categories of third parties with whom we share that personal information.</li>
          <li>
            The specific pieces of personal information we collected about you (also called a data
            portability request).
          </li>
          <li>
            If we disclosed your personal information for a business purpose, identifying the
            personal information categories that each category of recipient obtained.
          </li>
        </ul>
        <li>
          <Title>Deletion Request Rights</Title>
        </li>
        You have the right to request that we delete any of your PII that we collected from you and
        retained, subject to certain exceptions. Once we receive and confirm your verifiable
        consumer request, we will delete (and direct our service providers to delete) your personal
        information from our records, unless an exception applies. We may deny your deletion request
        if retaining the information is necessary for us or our service provider(s) to:
        <br />
        <ul>
          <li>
            Complete the transaction for which we collected the personal information, provide a good
            or service that you requested, take actions reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform our contract with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
            activity, or prosecute those responsible for such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing intended
            functionality.
          </li>
          <li>
            Comply with other existing law, regulation, court order, or other legal or contractual
            requirement.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with consumer expectations based
            on your relationship with us.
          </li>
          <li>
            Make other internal and lawful uses of that information that are compatible with the
            context in which you provided it.
          </li>
        </ul>
        <li>
          <Title>Exercising Access, Data Portability, and Deletion Rights</Title>
        </li>
        To exercise the access, data portability, and deletion rights described above, please submit
        a verifiable consumer request to us by sending us a message as described in the "Contact Us"
        section below. Only you, or a person that you authorize to act on your behalf (consistent
        with applicable law), may make a verifiable consumer request related to your personal
        information. You may also make a verifiable consumer request on behalf of your minor child.
        <br />
        <br />
        You may only make a verifiable consumer request for access or data portability twice within
        a 12-month period. The verifiable consumer request must:
        <br />
        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify you are the person
            about whom we collected personal information or an authorized representative.
          </li>
          <li>
            Describe your request with sufficient detail that allows us to properly understand,
            evaluate, and respond to it.
          </li>
        </ul>
        We cannot respond to your request or provide you with personal information if we cannot
        verify your identity or authority to make the request and confirm the personal information
        relates to you. We will only use personal information provided in a verifiable consumer
        request to verify the requestor's identity or authority to make the request.
      </ul>
      <Title>b. Response Timing and Format</Title>
      We aim to respond to a verifiable consumer request within forty-five (45) days of its receipt.
      If we require more time, we will inform you of the reason and extension period in writing. We
      will deliver our written response by mail or electronically, at your option. Any disclosures
      we provide will cover the 12-month period preceding the verifiable consumer request's receipt.
      The response we provide will also explain the reasons we cannot comply with a request, if
      applicable. For data portability requests, we will select a format to provide your personal
      information that is readily useable and should allow you to transmit the information from one
      entity to another entity without hindrance, specifically by electronic mail communication.
      <br />
      <br />
      We do not charge a fee to process or respond to your verifiable consumer request unless it is
      excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a
      fee, we will tell you why we made that decision and provide you with a cost estimate before
      completing your request.
      <Title>c. Updating/Removing Your Personal Information through User Account</Title>
      You can also customize how we collect and uses your information as set forth below. However,
      you should note that your decisions on how to customize the collection of your information may
      impact the functionality or availability of the Service Offerings. Here are some examples of
      how to customize the collection of information for the Service Offerings:
      <br />
      <ul>
        <li>
          Search History: You can choose to clear recent searches at any time by selecting Settings
          and clicking Clear Recent Searches in the app or browser. Doing so removes all saved
          content such as the name and dosage of a recent prescription drug so that any future user
          of the Service Offerings will see no trace of your history.
        </li>
        <li>
          Health Insurance Information: When you enter your insurance information on certain Service
          Offerings, we save this data to process and identify your pharmacy related benefits,
          including without limitation, to determine if a prescription drug is covered, your
          out-of-pocket obligations and other benefit related restrictions and requirements. You may
          clear this information at any time by going to Settings, Account Info, Insurance Settings,
          and then clearing data and tapping Save in the app or browser.
        </li>
        <li>
          Coupon Eligibility Information: By providing your date of birth, gender and zip, you
          activate the ability to verify your eligibility for particular coupons. In some cases, you
          may elect to share your information with third parties including pharmaceutical
          manufacturers for benchmarking purposes and/or to receive third party communications
          relating to specific drugs and conditions.
        </li>
        <li>
          Preventing Cookies: Your browser settings allow you to prevent accepting cookies from
          websites you visit. The default setting on most web browsers are set to accept cookies,
          but if you prefer not to receive cookies you can set your browser to warn you or refuse
          cookies all together by turning them off in your browser. If you choose not to accept the
          use of cookies on our website, your use of our website may be impaired or limited. At this
          time, we do not respond to browser "Do Not Track" signals. If at any point, you would like
          to clear browsing data including cookies from our website, you may select "Clear Recent
          Searches" on your browser settings, but this data may continue to be saved in our
          database.
        </li>
      </ul>
      <Title>d. Non-Discrimination</Title>
      We will not discriminate against you for exercising any of the rights above. Unless permitted
      by the law, we will not:
      <br />
      <ul>
        <li>Deny you goods or services.</li>
        <li>Provide you a different level or quality of goods or services.</li>
      </ul>
      <Title>VII. HOW WE SECURE AND PROTECT INFORMATION</Title>
      We use a variety of physical, administrative, electronic, technical and procedural safeguards
      to maintain a high-level of security to guard your PII, including without limitation, password
      protection, data backup, audit controls, access controls, and some data encryption.
      Specifically, we use commercially accepted procedures and systems to protect against
      unauthorized access to our systems. We restrict access to your PII to those employees,
      advisors and contractors who are covered by this Privacy Policy and need to know such
      information to provide services or otherwise assist you in connection with our Service
      Offerings. We protect the security of your PII during transmission via our website by using
      industry standard Secure Sockets Layer ("SSL") software, which encrypts information you input,
      so that no one else can read it as it is transmitted over the Internet.
      <br />
      <br />
      We also urge you to take additional steps on your own to safeguard and maintain the integrity
      of your PII. For example, you should never share your account or login information with other
      people and be sure to sign off when finished using a shared or public computer. We urge you to
      be aware that if you use or access our Service Offerings through a third-party computer
      network (e.g., employer, internet caf&eacute;, library) or other potentially non-secure
      internet connection, such use is not recommended and solely at your own risk. It is your
      responsibility to check beforehand on the privacy and/or security policy of your network prior
      to accessing the Service Offerings.
      <br />
      <br />
      We are not responsible for your handling, sharing, re-sharing and/or distribution of your
      information except as set forth in the Privacy Policy. Moreover, if you forward or submit any
      information electronically to another person or entity on or off the Service Offerings, even
      if you are linked via our Service Offerings, we are not responsible for any harm or other
      consequences from third party use or re-sharing of your information.
      <Title>
        VIII. MISCELLANEOUS
        <br />
        a. Children
      </Title>
      We are committed to protecting the privacy of children and are committed to preventing the
      unintentional collection of Personal Information and Protected Health Information from
      children under the age of 13 in accordance with the Children's Online Privacy Protection Act
      ("COPPA"). Our Service Offerings are not designed or intended to attract children under the
      age of 18, and we do not knowingly allow individuals under the age of 18 to create Accounts. A
      parent or legal guardian, however, may use our Service Offerings on behalf of a minor in their
      custody. The parent or legal guardian is solely responsible for any information provided on
      behalf of the minor and for ensuring that registration and information submitted is accurate
      and remains safe. The parent or legal guardian also assumes full responsibility for the
      interpretation and use of any information provided through our Service Offerings for the
      minor.
      <br />
      <br />
      If you are the parent or legal guardian of a child under the age of 13, and you have reason to
      believe that your child has provided his or her own PII to us, you have the right to request
      the removal of that child's PII from our database. In order to request such removal, please
      Contact Us. You will be required to verify your identity and status as the child's parent or
      legal guardian in order to have their PII removed.
      <Title>b. External links</Title>
      We do not control and are not responsible or liable for the security, authenticity, updates,
      accuracy of information, privacy policies, practices or otherwise of other websites or
      applications you might visit, interact with, or from which you might obtain services or
      products, even if you visit them using links from our Service Offerings. The purpose of the
      external sites is to provide users with further outside resources, which may or may not be
      related to OneRx, such as Prescription Assistance Programs, organizations, and further
      information to potentially help users.
      <Title>c. Using our Service Offerings from outside the United States</Title>
      OneRx services are intended for use only by residents of the United States. As of May 25,
      2018, OneRx redirects website visitors from the European Union to a message notifying such
      visitors that they cannot use OneRx and does not permit them to register or to use our
      services. We do not collect any personally identifying information from such visitors to our
      Site, although we do archive their IP addresses.
      <Title>IX. CHANGES TO THIS PRIVACY POLICY</Title>
      We may amend this Privacy Policy at any time as we deem appropriate with or without notice to
      you. In the event changes are made to this Privacy Policy, we will post the current version of
      our Privacy Policy on our website and at other places we deem appropriate. You understand and
      agree that your continued use of the Service Offerings shall be deemed as acceptance to any
      and all changes to this Privacy Policy, and you further agree that you will periodically check
      our website,{' '}
      <a style={{fontSize: '12px'}} href='/'>
        www.onerx.com
      </a>
      , for any such changes. You may also Contact Us to request a copy of our current Privacy
      Policy.
      <Title>CONTACT US</Title>
      If you have questions or concerns about this Privacy Policy, please contact us. Please provide
      your name and contact information in your correspondence.
      <br />
      <br />
      <div className='contact-us'>
        <strong>BY POSTAL MAIL</strong>
        <div>OneRx,</div>
        <div> Attn: Privacy Officer</div>
        <div> 3 Beaver Valley Road, Suite 103</div>
        <div>Wilmington, DE, 19803</div>
      </div>
      <div className='contact-us'>
        <strong>BY EMAIL</strong>
        <div>
          <a style={{fontSize: '12px'}} href='mailto:legal@truveris.com'>
            legal@truveris.com
          </a>
        </div>
      </div>
    </Content>
  );
};

export default FullPrivacyPolicyContent;

import React from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {useRouteMatch} from 'react-router-dom';

import {Routes} from '../../../../pages/routes';
import UserAvatar from './components/UserAvatar';
import NavLinks from '../NavLinks';
import SignInButton from '../SignInButton';

const DesktopNavigation = () => {
  const {authState} = useOktaAuth();

  const hideNav = Boolean(
    useRouteMatch([
      Routes.ABOUT,
      Routes.TERMS,
      Routes.PRIVACY,
      Routes.FAQ,
      Routes.SUPPORT,
      Routes.FULL_PRIVACY_POLICY,
    ]),
  );

  return (
    <ul>
      {hideNav && !authState.isAuthenticated ? null : <NavLinks />}
      {authState.isAuthenticated ? <UserAvatar /> : <SignInButton />}
    </ul>
  );
};

export default DesktopNavigation;

import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import {GoBackButton} from '../Button';
import {Optional} from '../../types';

export interface State {
  from?: string;
  canGoBack?: boolean;
}

const GoBack: FC = () => {
  const history = useHistory<Optional<State>>();

  if (!history.location.state?.canGoBack) {
    return null;
  }

  return <GoBackButton onClick={() => history.goBack()} />;
};

export default GoBack;

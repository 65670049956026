import {AxiosError} from 'axios';

import HttpClient from '../httpClient';
import {
  E1ResponseStatusCode,
  UserExtendedSchema,
  UserResponse,
  UserState,
  InsuranceInfoSchema,
} from './types';
import {getOktaAuth} from '../../hocs/AppWithRouterAccess';
import {CreateUserSuccessBody} from './createUser/types';
import {setRedirectionPath} from '../../utils/redirectionPath';
import {Routes} from '../../pages/routes';
import isHAEmployer from '../../utils/isHaEmployer';
import sendDemographicInfoHA from './sendDemographicInfoHA';
import {history} from '../../pages';
import signOut from '../../utils/signOut';
import StorageKeys from '../../constants/storageKeys';
import updateLoginTimestamp from '../authorization/updateLoginTimestamp';
import sendInsuranceInfoHA from './HealthAdvocate/sendInsuranceInfoHA';
import HttpCode from '../../constants/httpCode';
import {ERROR_CODES} from '../../errors/Error/messages';
import {Optional} from '../../types';

const createUser = () => {
  return HttpClient.post<CreateUserSuccessBody>({
    url: '/sso/users',
  });
};

const handleInsurance = async (
  userData: UserExtendedSchema,
  isUpdate: boolean = false,
): Promise<UserExtendedSchema> => {
  try {
    const insuranceInfo = await sendInsuranceInfoHA(isUpdate, {
      is_insured: !!userData.is_insured,
      insurance: userData.e1_transaction?.insurance,
    });

    return {
      ...userData,
      insurance: insuranceInfo,
    };
  } catch (e) {
    return {
      ...userData,
      insurance: null,
      insurance_failed: true,
    };
  }
};

const handleDemographicInfo = async (
  userResponse: Partial<UserResponse>,
  isUpdate: boolean = false,
): Promise<UserExtendedSchema> => {
  try {
    const demographicInfo = await sendDemographicInfoHA(isUpdate, userResponse);

    const e1Failed = demographicInfo?.e1_transaction?.status?.code !== E1ResponseStatusCode.OK;

    return {
      ...userResponse,
      ...demographicInfo,
      is_demographic_changed: false,
      insurance: e1Failed ? null : demographicInfo.e1_transaction.insurance,
      insurance_failed: userResponse.is_insured ? e1Failed : false,
    };
  } catch (e) {
    return {...userResponse, demographic_failed: true};
  }
};

export const handleErrorRedirectHA = (e: Optional<AxiosError> = null): undefined => {
  const oktaAuth = getOktaAuth();

  if (!oktaAuth) {
    return;
  }

  const redirectUrl = window?._env_?.HA_SING_IN_FAIL_URL;

  if (!redirectUrl && !e) {
    return;
  }

  if (!redirectUrl) {
    throw e;
  }

  signOut(oktaAuth).then(() => {
    window.location.href = redirectUrl;
  });
};

export const resolveUserAccountRedirectRoute = (userResponse: UserExtendedSchema): Routes => {
  if (userResponse?.demographic_info === null) {
    return Routes.MY_INFO;
  }

  if ((!userResponse?.insurance && userResponse?.is_insured) || userResponse.insurance_failed) {
    return Routes.MY_INSURANCE;
  }

  return userResponse?.hipaa_signed_at ? Routes.DRUG_LOOKUP : Routes.MY_ACCOUNT;
};

const syncLogin = (id: string) => {
  return updateLoginTimestamp(id).then(() =>
    sessionStorage.removeItem(StorageKeys.SHOULD_UPDATE_LOGIN_TIMESTAMP),
  );
};

const getMyUser = (): Promise<Partial<UserState>> => {
  return HttpClient.get<UserResponse>({
    url: '/employees/me',
  })
    .then(async (userResponse) => {
      let userData: UserExtendedSchema = userResponse;

      if (
        sessionStorage.getItem(StorageKeys.SHOULD_UPDATE_LOGIN_TIMESTAMP) === 'true' &&
        userData.user?.id
      ) {
        await syncLogin(userData.user?.id);
      }

      const hasDemographicInfo = userData.demographic_info !== null;
      const demographicIsChanged = userData.is_demographic_changed;
      const hasInsurance = userData.insurance;

      if (
        !isHAEmployer() ||
        (isHAEmployer() && hasDemographicInfo && !demographicIsChanged && hasInsurance)
      ) {
        return userResponse;
      }

      const isNewlyCreated = !hasDemographicInfo && userResponse.accepted_term_of_use_at;

      if (isNewlyCreated || (hasDemographicInfo && demographicIsChanged)) {
        userData = await handleDemographicInfo(userResponse, !isNewlyCreated);
      }

      if (userData.demographic_failed) {
        handleErrorRedirectHA();
      }

      if (userData?.e1_transaction?.status?.code === E1ResponseStatusCode.OK) {
        userData = await handleInsurance(userData);
      }

      const isStatelessRedirect = sessionStorage.getItem(StorageKeys.IS_STATELESS_ROUTE);

      if (isStatelessRedirect !== 'true') {
        history.push(resolveUserAccountRedirectRoute(userData));
      }

      sessionStorage.removeItem(StorageKeys.IS_STATELESS_ROUTE);

      return userData;
    })
    .catch((e: AxiosError) => {
      const isStatelessRoute = sessionStorage.getItem(StorageKeys.IS_STATELESS_ROUTE);

      sessionStorage.removeItem(StorageKeys.IS_STATELESS_ROUTE);

      if (!isHAEmployer()) {
        return new Promise<UserResponse>(() => {
          if (!isStatelessRoute) {
            sessionStorage.setItem(StorageKeys.SIGN_IN_FAILED, 'true');
          }

          const oktaAuth = getOktaAuth();
          if (oktaAuth) {
            signOut(oktaAuth).catch(() => {
              history.replace(Routes.SIGN_IN);
            });
          }
        });
      }

      const response = e?.response;

      if (response?.status === HttpCode.NOT_FOUND) {
        return createUser()
          .then(async (user) => {
            await syncLogin(user.id);

            if (!user.employee?.accepted_term_of_use_at) {
              return {
                user,
                employer: user?.employee?.employer,
                is_insured: user?.employee?.is_insured,
              };
            }

            let insurance: Optional<InsuranceInfoSchema>;

            try {
              const {demographic_info, e1_transaction: e1Transaction} =
                await sendDemographicInfoHA();

              insurance = e1Transaction.insurance;

              if (e1Transaction?.status?.code === E1ResponseStatusCode.FAILED) {
                user.employee.insurance = null;
                user.insurance_failed = true;
              }

              if (demographic_info) {
                user.employee.demographic_info = demographic_info;
              }
            } catch {
              handleErrorRedirectHA();
            }

            try {
              if (insurance && !user.insurance_failed) {
                user.employee.insurance = await sendInsuranceInfoHA(false, {
                  is_insured: !!user.employee.is_insured,
                  insurance,
                });
              }
            } catch {
              user.employee.insurance = null;
              user.insurance_failed = true;
            }

            const userData = {
              insurance_failed: !!user?.insurance_failed,
              demographic_info: user?.employee?.demographic_info,
              is_insured: user?.employee?.is_insured,
              insurance: user?.employee?.insurance,
              hipaa_signed_at: user?.employee?.hipaa_signed_at,
              accepted_term_of_use_at: user?.employee?.accepted_term_of_use_at,
            };

            history.push(resolveUserAccountRedirectRoute(userData));

            return {
              user,
              employer: user?.employee?.employer,
              is_insured: user?.employee?.is_insured,
              ...userData,
            };
          })
          .catch(() => {
            return new Promise<UserResponse>(() => {
              setRedirectionPath(Routes.HA_SIGN_IN_ERROR);
              sessionStorage.setItem(StorageKeys.SIGN_IN_FAILED, 'true');

              const oktaAuth = getOktaAuth();
              if (oktaAuth) {
                signOut(oktaAuth).catch(() => {
                  history.replace(Routes.HA_SIGN_IN_ERROR);
                });
              }
            });
          });
      }

      if (
        response?.status === HttpCode.BAD_REQUEST &&
        response?.data?.error_code === ERROR_CODES.invalidDemographicInfo
      ) {
        handleErrorRedirectHA(e);
      }

      throw e;
    });
};

export default getMyUser;

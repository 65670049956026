import {createGlobalStyle, css, ThemeProps} from 'styled-components/macro';

import {Theme} from '../../theme/theme';
import {MenuProps} from './types';

type Props = MenuProps & ThemeProps<Theme>;

const styles = css<Props>`
  .ant-menu-item-1 {
    padding: 20px 50px 10px;
    border-bottom: none;
  }
`;

export const GlobalStyles = createGlobalStyle<Props>`
.ant-menu-root.ant-menu-vertical {
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
  min-width: 100px;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical > .ant-menu-item a {
    margin: 0;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    color: ${(props: Props) => props.theme.colors.GREY};
  }

  .ant-menu-vertical > .ant-menu-item:hover {
    background-color: ${(props: Props) => props.theme.colors._SECONDARY};
    color: ${(props: Props) => props.theme.colors.GREY};
  }
  .ant-menu-vertical > .ant-menu-item:active, .ant-menu-vertical > .ant-menu-item:active a {
    background-color: ${(props: Props) => props.theme.colors.BLUE3};
    color: ${(props: Props) => props.theme.colors.WHITE};
  }
  .ant-menu-vertical > .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
    border-bottom: 1px solid ${(props: Props) => props.theme.colors._SECONDARY};
  }
`;

export default styles;

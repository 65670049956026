import React, {useRef, useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';

import {ModalProps} from '../../../components/Modal/types';
import {TitleStyles} from '../../../components/AuthWrapper/styles';
import {useScrollListener} from '../../../hooks/useScrollListener';
import TermsContent from '../../Terms/TermsContent';
import Typography from '../../../components/Typography';
import {GlobalModalMaskStyles} from '../../MyProfile/components/ModalHipaa';
import {FooterText, ModalStyled, TermsBody} from './styled';
import {getQueryString} from '../../../utils/urls';
import {EmployerName} from '../../../services/user/types';
import TermsContentHA from '../../Terms/TermsContentHA';
import {isFullyScrolled} from '../../../utils/isFullyScrolled';

const TermsOfUseModal = ({visible, onOk, onCancel}: ModalProps) => {
  const {authState} = useOktaAuth();
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState<boolean>(true);
  let termsBodyRef = useRef<HTMLDivElement>(null);

  const handleAcceptButtonScroll = () => {
    if (isFullyScrolled<HTMLDivElement>(termsBodyRef)) {
      setAcceptButtonDisabled(false);
    }
  };

  const isHAUser = authState.isAuthenticated
    ? authState.accessToken?.claims?.employer === EmployerName.HEALTH_ADVOCATE
    : getQueryString('employer') === EmployerName.HEALTH_ADVOCATE;

  useScrollListener({
    element: termsBodyRef.current,
    callBack: handleAcceptButtonScroll,
  });

  return (
    <ModalStyled
      width={780}
      visible={visible}
      onOk={onOk}
      okText='Accept'
      onCancel={onCancel}
      cancelText='Decline'
      footerDirection='row-reverse'
      data-e2e='terms-of-use__wrapper'
      okButtonProps={{
        disabled: acceptButtonDisabled,
        // @ts-ignore
        primaryGreen: true,
      }}
      forceRender={true}
      footerChildren={[
        <FooterText data-e2e='terms-of-use__footer'>
          Scroll to the bottom of the text to enable buttons
        </FooterText>,
      ]}
      title={
        <TitleStyles>
          <Typography level={1} data-e2e='terms-of-use__title'>
            oneRx Terms of Use
          </Typography>
        </TitleStyles>
      }
    >
      <GlobalModalMaskStyles />
      <TermsBody ref={termsBodyRef}>{isHAUser ? <TermsContentHA /> : <TermsContent />}</TermsBody>
    </ModalStyled>
  );
};

export default TermsOfUseModal;

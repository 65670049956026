export const regexpPatterns = {
  //based on this validation https://emailregex.com/
  EMAIL:
    // eslint-disable-next-line no-control-regex
    /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  OKTA_PASSWORD: /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!$%@#£€*?&\\.]{8,}$/,
  OKTA_PASSWORD_SPECIAL_CHARACTERS: /[\d!$%@#£€*?&\\.]/,

  LOWERCASE_SYMBOL: /[a-z]/,
  UPPERCASE_SYMBOL: /[A-Z]/,
  DIGIT: /[0-9]/,

  RX_BIN: /^[0-9]{6}$/,
  NUMBERS_OR_LETTERS: /^[0-9a-z]*$/i,
  PERSONAL_CODE: /^[0-9]{2,3}$/i,

  ANY_NO_DIGIT_SYMBOL: /[^0-9]/g,
  ONLY_DIGITS: /^[0-9]+$/,

  NAME: /^[a-z]+[a-z'\- ]*$/i,

  NO_NAME_SYMBOLS: /[^a-z'\- ]+/gi,
  NO_NAME_SYMBOLS_AT_START: /^[^a-z]+/i,
};

export enum ValidationMessages {
  REQUIRED = 'This field is required',
  REQUIRED_FIELD_PREFIX = 'Please provide ',

  AT_LEAST_ONE_SELECTED = 'At least one option must be selected',

  // Length
  MIN_LENGTH = 'Min field length is :min',
  MAX_LENGTH = 'Max field length is :max',

  MIN_VALUE = 'Value must be :min or more',

  MIN_2 = 'Should be 2 symbols or more',
  MIN_3 = 'Should be 3 symbols or more',
  MIN_4 = 'Should be 4 symbols or more',
  MIN_8 = 'Should be 8 symbols or more',
  MAX_15 = 'Should be 15 symbols or less',
  MAX_20 = 'Should be 20 symbols or less',
  MAX_25 = 'Should be 25 symbols or less',
  TOOLTIP_MIN_8 = 'At least 8 characters long',

  NUMBERS_OR_LETTERS = 'Should be only letters and digits',
  NUMBERS_ONLY = 'Should be only digits',
  ZERO_VALUES_NOT_ALLOWED = 'Zero values are not allowed',
  EMAIL = 'Incorrect email format',
  RX_BIN = 'Should be 6 digits',
  PERSONAL_CODE = 'Should be 2 or 3 digits',
  SSN_4 = 'Should be 4 digits',
  ZIP = 'Should be 5 digits',

  // Date
  MIN_DATE_PREFIX = 'Date should be after ',
  DATE_SHOULD_BE_IN_THE_PAST = 'Date should be in the past',
  DATE_SHOULD_NOT_BE_IN_THE_PAST = 'Date cannot occur in the past',
  INVALID_DATE = 'Invalid date format',

  // Password
  ONE_LOWERCASE = '1 lower case character',
  ONE_UPPERCASE = '1 upper case character',
  ONE_DIGIT = 'Should include at least one digit',
  ONE_NUMERIC_CHARACTERS = '1 numeric character',
  EMAIL_SEQUENCE_VALUE = 'Password cannot contain fragments from users` email',
  PASSWORD_DIFFERS = 'Your password and confirm password don’t match',
  MATCHES_TEMP_PASSWORD = 'New password can’t be the same as the temporary password',

  //File
  FILE_MUST_BE_CSV = 'File format must be CSV, please convert and try again',
  FILE_INCORRECT_HEADERS = 'File headers are incorrect, please fix and try again',
}

import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';

const FooterStyles = styled.div<ThemeProps>`
  width: 100%;
  height: 90px;
  color: ${({theme}) => theme.colors.GREY};
  background-color: ${({theme}) => theme.colors.GREY3};
  z-index: 100;
  font-size: 12px;

  ${({theme}: ThemeProps) =>
    theme.forWidth(
      'max',
      'sm',
      `
        height: 68px;
        .ant-row {
          display: none;
        }
      `,
    )}

  > div {
    height: 100%;
    padding: 35px 40px;
    display: flex;
    justify-content: space-between;

    ${({theme}: ThemeProps) =>
      theme.forWidth(
        'max',
        'sm',
        `
          justify-content: center;
          align-items: center;
          padding: 0 ${theme.grid.gutter.sm};

          p {
            margin: 0;
            font-size: ${theme.textStyle.body.fontSize};
            line-height: ${theme.textStyle.body.lineHeight};
          }
        `,
      )}
  }

  a {
    color: inherit;
    font-size: inherit;
  }
`;

export default FooterStyles;

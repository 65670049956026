import React from 'react';

import Navigation from '../../components/Navigation';
import {
  ButtonWrapper,
  Content,
  ContentWrapper,
  Header,
  Layout,
  Logo,
  Wrapper,
} from '../Privacy/styles';
import GoBack from '../../components/GoBack';
import OptionalWrapper from '../../hocs/OptionalWrapper';

const Privacy = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <OptionalWrapper noEmpty Wrapper={ButtonWrapper}>
          <GoBack />
        </OptionalWrapper>
        <Layout>
          <Header>
            <Logo>oneRx </Logo>
            <span>Privacy Policy</span>
          </Header>
          <ContentWrapper>
            <Content>
              You acknowledge and agree that any and all right, title and interest in any foreign
              and domestic patents, copyrights, trademarks, service marks, trade secrets, and all
              other intellectual property rights (collectively, "IP Rights") in the Service are and
              shall remain the sole and exclusive property of oneRx and its licensors, as
              applicable. Nothing in this Agreement intends to or shall grant, transfer, assign or
              vest any IP Rights to or in You, and You are only entitled to the limited use of the
              rights expressly granted to You in this Agreement. You will not take any action to
              jeopardize, limit, or interfere with oneRx's IP Rights. You acknowledge and agree that
              any unauthorized use of the IP Rights is a violation of this Agreement, as well as a
              violation of applicable intellectual property laws. You acknowledge and understand
              that all right, title and interest in and to any Third Party Content (as defined
              below) that may be accessed through the Service is the property of the respective
              content owners and may be protected by applicable copyright or other intellectual
              property laws and treaties.You agree not to sell, assign, rent, lease, distribute,
              export, import, act as an intermediary or provider, or otherwise grant rights to third
              parties with regard to the Service or any part thereof without oneRx's prior written
              consent.You agree not to undertake, cause, permit, or authorize the copying,
              modification, creation of derivative works, translation, reverse engineering,
              decompiling, disassembling, or hacking of the Service, or any parts thereof. You agree
              not to intercept, capture, emulate, decrypt, or redirect the communications protocols
              used by oneRx for any purpose or manner not expressly authorized by oneRx.4.2
              Trademarks and CopyrightsThe Service, this Agreement and all content provided through
              the Service by oneRx are Copyrighted 2015 by oneRx, LLC. All rights reserved.All
              trademarks indicated herein are the sole and exclusive property of oneRx. Unless
              otherwise labeled, all custom graphics, logos, icons and service names used on the
              Service are trademarks of oneRx, its Affiliates or licensors. All other service marks
              or trademarks are property of their respective owners. No part of this Agreement
              grants You the right to use any logo, trademark, service mark, and/or the name of
              oneRx, its Affiliates or its Licensors for any purpose.You may not alter, remove or
              obscure any service mark, copyright, trademark or other proprietary rights notices
              incorporated in or accompanying the Service, including in any content provided
              therein.4.3 Third Party Content And SitesMaterials or content may be made available
              via the Service that are owned, provided or references documents, information,
              software, products, services, offers and/or materials provided by third parties not
              within our control, including without limitation, information regarding prescription
              drugs, coupons, discounts, patient assistance programs ("PAPs") offered by
              pharmaceutical manufacturers or other entities, links to third party sites or other
              condition management initiatives (collectively, "Third Party Content").Third Party
              Content is not under the control of oneRx, and oneRx is not liable or responsible for
              Third Party Content available, linked or accessible through the Service, and any
              right, title, interest and liability for any intellectual property rights in such
              Third Party Content shall remain with the respective third party owner or licensor
              unless otherwise stated herein or agreed to by oneRx and such third party. You assume
              all risk arising from Your use of Third Party Content, including without limitation,
              any Third Party Content You may find or view on sites linked from the Service. oneRx's
              inclusion of such a link to Third Party Content is provided as a convenience and is
              not intended to indicate association with or endorsement of such third party, or any
              warranty of any kind, either implied or express of any of the Third Party Content.
              oneRx does not and is under no obligation to review Third Party Content transmitted,
              sent, received, used, linked or offered through the Service for any purposes,
              including without limitation, for determining intellectual property infringement,
              copyright compliance, accuracy, decency, legality, omissions, errors, references or
              any other aspect of the Third Party Content. However, oneRx reserves the right to and
              will, in appropriate circumstances, terminate access to the Service if a user
              infringes on any intellectual property right of oneRx or a third party.You are only
              permitted to use Third Party Content as expressly authorized by oneRx or Third Party
              Content provider. Any unauthorized use of the materials appearing on the Service may
              violate trademark, copyright and other applicable laws resulting in civil or criminal
              penalties.5. TERMINATIONYou may stop using the Service and cancel Your Account at any
              time by deactivating Your Account online or Contacting Us. oneRx reserves the right to
              terminate Your use of the Service at any time for any reason or no reason in our sole
              discretion. If You breach any terms of this Agreement, oneRx may immediately suspend,
              block and/or disable Your existing Account and prevent any future use or access to the
              Service. To the extent oneRx is in possession of PHI, the disposition of such PHI upon
              termination shall be controlled by the agreement(s) between oneRx and Your group
              health plan. 6. REPRESENTATIONS AND WARRANTIES6.1 Your Representations and
              WarrantiesYou represent and warrant that (i) You possess the legal right, capacity,
              and ability to enter into this Agreement; (ii) You will provide and maintain true and
              accurate information on Your Account at all times; (iii) You own or have a license to
              use any of Your Data or Your Content that You submit to us; (iv) Your Data and Your
              Content does not violate the copyrights, contract rights, publicity rights, privacy
              rights, or any other rights of any person; (iv) You are not located in a country that
              is subject to a U.S. Government embargo, or that has been designated by the U.S.
              Government as a "terrorist supporting" country; (v) You not listed on any U.S.
              Government list of prohibited or restricted parties; and (iv) You will not use the
              Service in violation of this Agreement.6.2 oneRx's Warranty DisclaimerONERX'S SERVICE
              IS PROVIDED "AS IS" AND "AS AVAILABLE," AND THE ENTIRE RISK ASSOCIATED WITH THE USE OF
              THE SERVICE SHALL BE BORNE SOLELY BY YOU. TO THE FULLEST EXTENT PERMITTED BY LAW,
              ONERX, ITS LICENSORS, AFFILIATES, SUPPLIERS AND AGENTS MAKE NO WARRANTIES, EXPRESS,
              STATUTORY, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE OR ANY SIMILAR
              WARRANTY, WHETHER SAID WARRANTY ARISES UNDER PROVISIONS OF ANY LAW OF THE UNITED
              STATES OR ANY STATE OR TERRITORY THEREOF, OR OF ANY COUNTRY. ONERX MAKES NO
              REPRESENTATIONS OR WARRANTIES THAT THE SERVICES ARE FREE OF RIGHTFUL CLAIMS OF ANY
              THIRD PARTY FOR INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY OR OTHER
              PROPRIETARY RIGHTS (INCLUDING PATENT AND TRADE SECRET RIGHTS) OR THAT YOUR USE OF
              SERVICE OR ANY CONTENT OBTAINED OR DISPLAYED ON THE SERVICE WILL NOT INFRINGE ON THIRD
              PARTY INTELLECTUAL PROPERTY RIGHTS.WITHOUT LIMITING THE FOREGOING, ONERX MAKES NO
              WARRANTY AS TO THE ACCURACY, AVAILABILITY, RELIABILITY, COMPLETENESS, CURRENTNESS,
              SECURITY OR TIMELINESS OF THE CONTENT, MATERIALS, OFFERS, SOFTWARE, TEXT, GRAPHICS,
              LINKS, THIRD PARTY SITES, THIRD PARTY CONTENT, THIRD PARTY MATERIALS, RESULTS OR
              COMMUNICATIONS OBTAINED THROUGH THE USE OF THE SERVICE OR ANY DATA OR INFORMATION
              DOWNLOADED OR OTHERWISE OBTAINED OR ACQUIRED THROUGH THE USE OF THE SERVICE. YOU
              ACKNOWLEDGE AND AGREE THAT ANY CONTENT, DATA, COUPONS OR INFORMATION DOWNLOADED OR
              OTHERWISE OBTAINED, ACQUIRED OR VIEWED THROUGH THE USE OF THE SERVICE ARE AT YOUR SOLE
              RISK AND DISCRETION, AND ONERX WILL NOT BE LIABLE OR RESPONSIBLE FOR ANY DAMAGE TO
              YOU, PERSONS YOU HAVE LEGAL CUSTODY OF OR AUTHORIZATION FROM OR THE RESPECTIVE
              PROPERTY OF EACH OF SUCH PERSONS. ONERX IS NOT RESPONSIBLE FOR THE CONTENT OR
              FUNCTIONALITY OF ANY THIRD PARTY NETWORK, PLUGINS OR SERVICES USED IN CONNECTION WITH
              OR USED TO CONNECT TO THE SERVICE.ONERX MAKES NO WARRANTY THAT THE SERVICES WILL MEET
              YOUR REQUIREMENTS, OR THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR
              FREE OR THAT ANY DEFECTS IN THE SERVICE WILL BE CORRECTED. ONERX FURTHER MAKES NO
              WARRANTY OR GUARANTEE OF UP-TIME, RESPONSE TIMES, LATENCY, SECURITY, TIME BETWEEN
              FAILURES OR QUALITY OF SERVICE, AND EXPRESSLY DISCLAIMS ANY WARRANTY THAT THE SERVICE
              IS APPROPRIATE FOR HIGH-RISK, FAIL-SAFE, EMERGENCY OR OTHER ACTIVITIES WHERE FAILURE
              OF THE SERVICE COULD RESULT IN SERIOUS HARM TO PERSONS OR PROPERTY. ONERX IS NOT
              RESPONSIBLE FOR INFORMATION LOST OR MISDIRECTED DUE TO INTERRUPTIONS OR FLUCTUATIONS
              IN THE SERVICE, NETWORK CONNECTIONS OR THE INTERNET IN GENERAL.NO ADVICE OR
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ONERX, ITS EMPLOYEES, ITS
              LICENSORS, PARTNERS, CONRACTORS, AFFILIATES, SUPPLIERS OR AGENTS DIRECTLY OR
              INDIRECTLY THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
              IN THESE TERMS AND CONDITIONS AND SHALL UNDER NO CIRCUMSTANCES BE CONSTRUED AS MEDICAL
              ADVICE.SOME JURISDICTIONS DO NOT PERMIT THE DISCLAIMER OF CERTAIN IMPLIED WARRANTIES,
              SO CERTAIN OF THE FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU. TO THE EXTENT THAT ONERX
              CANNOT DISCLAIM ANY SUCH WARRANTY AS A MATTER OF APPLICABLE LAW, THE SCOPE AND
              DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW.7.
              INDEMNIFICATIONTo the maximum extent permitted by applicable law, You shall indemnify
              and hold harmless, individually and collectively, oneRx, its Affiliates, agents,
              partners, and other providers who furnish goods and services to You in connection with
              the Service, and their officers, directors, managers, employees, and shareholders (the
              "Indemnified Parties") from and against any and all liability, claims, losses
              (including loss of profits, revenue and goodwill), damages, fines, penalties, injuries
              to persons or property, costs, and expenses (including reasonable attorneys' fees and
              dispute resolution expenses) arising from or related to (i) the use of or reliance
              upon the Service by You or any third party acting upon Your permission, knowledge,
              authority or direction, (ii) a breach of this Agreement by You or third parties whom
              You represent or are acting for or upon, (iii) any negligent acts, omissions to act or
              willful misconduct by You or any third party acting with Your permission, knowledge,
              authority or direction, (iv) the use of the Service in connection with a violation of
              any applicable law, code, regulation, or ordinance, and/or (v) the misappropriation,
              breach, violation, or infringement of any right, title or interest of any third party,
              including but not limited to, contractual rights, intellectual property rights
              (including patent, trademark, copyright, and trade secret rights), rights of privacy,
              and rights of publicity and personality.8. LIMITATION OF LIABILITY IN NO EVENT SHALL
              ONERX, ITS AFFILIATES, LICENSORS, CONTRACTORS OR SUPPLIERS BE LIABLE TO YOU OR ANY
              THIRD PARTY FOR SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
              DAMAGES OF ANY KIND INCLUDING WITHOUT LIMITATION, PERSONAL INJURY/WRONGFUL DEATH, LOSS
              OF PROFITS, USE, DATA, GOODWILL, BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES,
              RESULTING FROM (i) YOUR USE OF, ACCESS TO OR INABILITY TO USE OR ACCESS THE SERVICE OR
              CONTENT PROVIDED IN THE SERVICE; (ii) ANY CONTENT OBTAINED FROM THE SERVICE; AND/OR
              (iii) UNAUTHORIZED USE, ACCESS, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT FROM
              USE OF THE SERVICE, WHETHER BASED ON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE OR
              STRICT LIABILITY) OR ANY OTHER LEGAL OR EQUITABLE THEORY OF LIABILITY, REGARDLESS OF
              WHETHER ONERX HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A
              REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.ONERX'S TOTAL
              LIABILITY TO YOU FOR ANY AND ALL DAMAGES UNDER ALL THEORIES OF LIABILITY AND FROM ALL
              CAUSES OF ACTION WILL BE LIMITED TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU,
              WHICH SHALL NOT EXCEED (IF YOU HAVE PAID ANY FEES TO ONERX) THE FEES PAID BY YOU FOR
              USE THE SERVICE DURING THE ONE (1) MONTH IMMEDIATELY PRIOR TO THE DATE THAT THE EVENTS
              GIVING RISE TO THE, ACTION OR CLAIM FIRST OCCURRED TO ONERX OR (IF YOU HAVE NOT PAID
              ANY FEES TO ONERX) $50, AS APPLICABLE. THE LIMITATION OF LIABILITY REFLECTS THE
              ALLOCATION OF RISK BETWEEN THE PARTIES. THE LIMITATIONS SPECIFIED IN THIS SECTION WILL
              SURVIVE AND APPLY IN ANY AND ALL CIRCUMSTANCES.SOME JURISDICTIONS DO NOT ALLOW CERTAIN
              LIMITATIONS OF LIABILITY, SO CERTAIN OF THE FOREGOING LIMITATIONS MAY NOT APPLY TO
              YOU.ANY CLAIMS ARISING IN CONNECTION WITH YOUR USE OF THE SERVICE, CONTENT, PUBLIC
              AREAS OR THIS AGREEMENT MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT
              GIVING RISE TO SUCH ACTION OCCURRED. THE REMEDIES FOR SUCH CLAIMS ARE LIMITED TO THOSE
              EXPRESSLY PROVIDED FOR IN THIS AGREEMENT.ONERX HEREBY DISCLAIMS ANY LIABILITY IN
              RESPECT TO ANY SUCH THIRD PARTY CONTENT. NOTWITHSTANDING THE FOREGOING, YOU UNDERSTAND
              AND AGREE THAT SUCH THIRD PARTY CONTENT MAY BE "TRANSACTIONAL," OR NEEDED TO COMPLETE
              THE PROCESS OF ACQUIRING DISCOUNTS OR COUPONS ACCESSED THROUGH ONERX, AND MAY RELATE
              TO OR PROCEED FROM PERSONALLY IDENTIFIABLE INFORMATION (PII) OR PERSONAL HEALTH
              INFORMATION THAT YOU HAVE ENTERED. 9. STORAGE OF INFORMATIONoneRx is obligated to
              store Your PHI, Your Content, Account information, Service requests, communications,
              e-mails, messages or any other information pursuant to its agreements with Your group
              health plan and pursuant to HIPAA. To the extent Your Data does not include PHI and is
              not covered by HIPAA, oneRx is not obligated to store Your Data, but does so in its
              sole discretion. In either case, oneRx may delete Your Data at any time or
              periodically to comply with applicable law or regulation, its internal document
              retention policies or with its agreements with Your group health plan10. SUPPORT AND
              FEEDBACKoneRx provides support to You via telephone, and through applicable Service
              platforms in its sole discretion but has no obligation to provide any support for the
              Service or to fix any issues with the Service, including without limitation, customer
              support, technical support, response or implementation of feedback, software or App
              solutions (e.g., bug fixes, software updates). oneRx will use reasonable efforts to
              troubleshoot and resolve an issue reported by You but does not make any
              representations or guarantees that such issues will be resolved in any given time
              frame or ever.From time to time, oneRx may send You requests to provide feedback on
              the Service. You submit such feedback voluntarily and any feedback received may be
              used by oneRx to improve the Service or for any other purpose in its sole discretion.
              You hereby grant oneRx, its licensors, Affiliates, and suppliers a perpetual,
              unlimited, worldwide fully-paid up, royalty free license to use all feedback, answers,
              ideas, comments, or other information You provide to oneRx through any medium.11.
              PROMOTIONAL OFFERSYou hereby agree that oneRx (including its Affiliates, partners and
              contractors) may directly or indirectly send You marketing materials, Offers and/or
              promotional offerings via electronic transmission, e-mail, mail, or otherwise
              ("Promotional Offers"), provided, that You may unsubscribe to such materials at any
              time by clicking the "Unsubscribe" link at the bottom of any promotional email or
              Contacting Us. You understand and agree that oneRx may modify the scope or discontinue
              any or all Promotional Offers at any time without additional notice to You. You
              further understand and agree that oneRx may present Promotional Offers only to new
              users and that You may not be eligible for some or all of the Promotional Offers. Any
              terms involving promotion in Your HIPAA Authorization, if any, shall supersede the
              terms of this section. 12. ASSIGNMENToneRx may assign this Agreement and any of its
              rights and obligations hereunder at any time. You may not transfer or assign this
              Agreement or any of Your rights or obligations under this Agreement, and any purported
              transfer or assignment in violation of this section is void. Subject to the foregoing,
              this Agreement shall be binding on and inure to the benefit of the parties, their
              successors, permitted assigns, and legal representatives. 13. MODIFICATIONSWe reserve
              the right to add, remove, fix, change, update, modify, amend or terminate either
              temporarily or permanently any feature, functionality or in entirety the Service or
              any term of this Agreement from time to time with or without prior notice to You. If
              we make material changes to the Service or this Agreement, we may notify You of such
              changes by sending You an email, notification or other message (e.g., push
              notifications, in-app news notices) or by posting them on our Website, including when
              such changes shall take effect.You acknowledge and agree that oneRx has no obligation
              to make available to You any subsequent or updated versions of the Service. You also
              understand and agree that You may or may not be required to enter into a renewed
              version of this Agreement if You want to download, install, or use a new version of
              the Service. By continuing to use or access our Service after those revisions take
              effect, You agree to be bound by all applicable revised terms and conditions of this
              Agreement.The modified terms of this Agreement shall replace and supersede all
              previously agreed to electronic and written terms, as well as any prior versions of
              this Agreement. In order to ensure that You receive any notices from us, You agree to
              be solely responsible for (i) making sure that Your email account is current and
              functional, (ii) checking Your email regularly, and (iii) making sure that oneRx
              communications are not blocked or rendered undeliverable by You, Your computer, any
              software installed on Your computer, Your Internet service provider, Your mobile
              device or for any other reason. This section shall not apply to any HIPAA
              Authorization; such terms cannot be changed by oneRx without your additional
              authorization, and any revisions shall be conducted pursuant to HIPAA. 14. GOVERNING
              LAW AND VENUEYour use of the Service and the provisions of this Agreement are governed
              by and construed under the laws of the State of New York without regard to any
              conflict of law rules or principles. The United Nations Convention on Contracts for
              the International Sale of Goods does not apply to this Agreement or Your use of the
              Service.You agree that any disputes or claims regarding Your use of the Service or
              this Agreement shall be adjudicated in, and/or the venue for all disputes shall be in,
              the state and federal courts located in Manhattan in the State of New York, U.S.A. You
              agree to submit to the exclusive jurisdiction of such courts and agree not to bring
              any claims or disputes in any other court or adjudicative body. You hereby consent to
              venue and personal jurisdiction in such courts and irrevocably waive any right that
              You may have to assert that such forum is not convenient or that any such court lacks
              jurisdiction.15. THIRD PARTY BENEFICIARIES The following parties are named third party
              beneficiaries of this Agreement who will have the right to enforce this Agreement
              against You upon acceptance hereof: Google Inc., Apple, Inc. and their respective
              subsidiaries.16. MISCELLANEOUSThis Agreement, including the documents referenced
              and/or incorporated herein, constitutes the full agreement between You and oneRx
              regarding the Service and supersedes all prior or contemporaneous understandings or
              agreements on the subject matter.In the event of any legal action between You and
              oneRx, the non-prevailing party shall reimburse the prevailing party for all
              reasonable and documented attorneys' fees, costs, and expenses relating to the claim
              or dispute.In the event of any dispute between this Agreement and any requirements of
              HIPAA, a HIPAA Business Associate Agreement, or Your HIPAA Authorization (collectively
              “HIPAA Requirements”) that may apply to your PHI, the HIPAA requirements shall control
              and supersede this Agreement.Any and all claims related to this Agreement, including
              without limitation, product liability claims, any claim that the Service fails to
              confirm with any applicable legal or regulatory requirement or claims arising under
              consumer protection or similar legislation may be brought to our attention by
              contacting us.The section headings contained in this Agreement are for reference
              purposes only and shall not affect the meaning or interpretation of this Agreement.If
              a court holds any provision(s) of this Agreement invalid or unenforceable, such
              provision(s) shall be construed to reflect the parties' original intent, and the
              remaining provisions of this Agreement shall remain in full force and effect.oneRx's
              failure to enforce any provision or right of this Agreement will not be considered a
              waiver of those rights.Nothing in this Agreement shall be deemed or construed to
              constitute or create employment, partnership, association, joint venture, agency, or
              fiduciary relationship between You and oneRx.oneRx shall be excused from any delay or
              failure in performance hereunder caused by reason of occurrence or contingency beyond
              its reasonable control, including without limitation, acts of God, earthquake, fire,
              flooding, fiber cuts, actions or inactions of third party providers or suppliers,
              riots, sabotage, war, government requirements, or other events that are beyond oneRx's
              reasonable control. You agree and acknowledge that any breach of the provisions
              regarding intellectual property ownership or confidentiality contained in this
              Agreement shall cause oneRx irreparable harm and that oneRx may obtain injunctive
              relief and seek all other remedies available in law and in equity.The section titles
              in this Agreement are for convenience only and have no legal or contractual
              effect.This electronic document and any other electronic documents, policies, and
              guidelines incorporated herein will be: (i) deemed for all purposes to be a "writing"
              or "in writing," and to comply with all statutory, contractual, and other legal
              requirements for a writing; (ii) legally enforceable as a signed writing as against
              the parties subject to the electronic documents; and (iii) deemed an "original" when
              printed from electronic records established and maintained in the ordinary course of
              business. Electronic documents introduced as evidence in any judicial, arbitration,
              mediation, or administrative proceeding will, if established and maintained in the
              ordinary course of business, be admissible to the same extent as business records in
              written form that are similarly established and maintained.Notices to You shall be
              effective on the date sent to Your registered electronic mail address when sent by
              email or via the App, or at oneRx's option, three (3) days following the date
              deposited in regular United States Mail, postage prepaid, and addressed to Your
              current address, if known, associated with Your email address. You are responsible for
              notifying oneRx of any changes in Your contact information or address through Your
              Account settings or by contacting us.All notices from You to oneRx must be in writing,
              and such written notice shall be effective when directed to oneRx's Legal Department
              and received at our then-current address as posted in the Contact Us section below.
              Your notice must specify Your name, email address and clear and detailed background
              and description of the notice You are providing us.17. CONTACT USPlease refer to our
              Frequently Asked Questions (FAQs) or contact us if You have any questions about our
              Service or this Agreement:BY POSTAL MAILoneRx, LLCAttn: Legal Department3 Beaver
              Valley Road, Suite 103Wilmington, DE, 19803BY EMAILsupport@onerx.com
            </Content>
          </ContentWrapper>
        </Layout>
      </Wrapper>
    </>
  );
};

export default Privacy;

import React, {ReactNode, useContext} from 'react';
import {ThemeContext} from 'styled-components/macro';

import {Breakpoints, Theme} from '../../theme/theme';
import useWidth from '../../hooks/useWidth';

interface ShowResponsiveProps {
  max?: Breakpoints;
  excludeMax?: boolean;
  min?: Breakpoints;
  excludeMin?: boolean;
  children: ReactNode;
}

/**
 * Renders children only when screen width
 * is in range between min and max.
 */
const ShowResponsive = ({max, excludeMax, min, excludeMin, children}: ShowResponsiveProps) => {
  const theme = useContext<Theme>(ThemeContext);
  const width = useWidth();

  if (width) {
    if (max) {
      const maxWidth = theme.grid.breakpoints[max];
      if (width > maxWidth) return null;
      if (excludeMax && width === maxWidth) return null;
    }

    if (min) {
      const minWidth = theme.grid.breakpoints[min];
      if (width < minWidth) return null;
      if (excludeMin && width === minWidth) return null;
    }
  }

  return <>{children}</>;
};

export default ShowResponsive;

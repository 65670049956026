import HttpClient from '../httpClient';
import {SessionHTTPMetaData, SessionResponseSchema} from './types';

const createSession = () => {
  return HttpClient.post<SessionResponseSchema>({
    url: SessionHTTPMetaData.SESSION_API_URL,
  }).then(({session_id}) => session_id);
};

export default createSession;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../../../../theme/theme';

const CircleButton = styled.button<ThemeProps>`
  border: 1px solid ${({theme}) => theme.colors.GREY2};
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  padding: 0;
  text-align: center;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  background: ${({theme}) => theme.colors.WHITE};
  border-radius: 50%;
  color: ${({theme}) => theme.colors.GREY2};
  font-size: 20px;
  outline: none;
  transition: all 0.1s ease;
  position: relative;
  cursor: pointer;

  :active {
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
    top: 1px;
  }
`;

export default CircleButton;

import styled from 'styled-components/macro';

import {ThemeProps} from '../../theme/theme';

const Link = styled.a<ThemeProps>`
  color: ${(props) => props.theme.colors.BLUE3};
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors.BLUE3};
    text-decoration: underline;
  }
`;

export default Link;

import React from 'react';

import {Content} from '../../styles';
import {Title} from '../../../Privacy/styles';
import SupportEmailLink from '../SupportEmailLink';

const FindingAndPricingDrugs = () => {
  return (
    <Content>
      <Title>Why can't I find my drug?</Title>
      If you can’t find your drug, check if you spelled it correctly. If it still does not appear,
      please email <SupportEmailLink />.
      <br />
      <br />
      <Title>
        What is the drug type, formulation, dosage, or quantity for my drug and how do I find this
        info?
      </Title>
      The drug <strong>form</strong> is how the drug is taken (ex. tablet, capsule, gel, syringe,
      etc.). The drug <strong>quantity </strong>
      is how many of a certain form of drug is being prescribed (ex. 30 tablets, 1 vial, 100 mL,
      etc.). The drug <strong>dosage</strong> is the strength of your prescription (ex. 20 mg, 80
      mg, etc.).
      <br />
      These details should be listed on the prescription issued by your prescriber. You may find
      this information on your pill bottle, the paperwork you received the last time you had it
      filled, or you may look it up on your medication list from your insurance website. If you have
      any questions about your prescription, please contact your prescriber or pharmacist.
      <br />
      <br />
      <Title>How do I find and get the best price for my drug?</Title>
      When you do a drug search, oneRx will show you a side by side price comparison with your
      insurance and the lowest oneRx price. This pice comparison will help you understand which
      option that is right for you.
      <br />
      <br />
    </Content>
  );
};

export default FindingAndPricingDrugs;

import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import isAdmin from '../utils/isAdmin';
import {Routes, StatelessRoutes} from '../pages/routes';
import {isEnumValue} from '../utils/enums';
import StorageKeys from '../constants/storageKeys';

const AdminRoute: FC = ({children}) => {
  const location = useHistory();
  const isStatelessRoute = isEnumValue<typeof StatelessRoutes>(
    StatelessRoutes,
    location.location.pathname,
  );

  if (isStatelessRoute) {
    sessionStorage.setItem(StorageKeys.IS_STATELESS_ROUTE, String(isStatelessRoute));
  }

  if (!isAdmin() && !isStatelessRoute) {
    location.push(Routes.DRUG_LOOKUP);
  }

  return <>{children}</>;
};

export default AdminRoute;
